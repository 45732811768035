var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var SnackBar_1;
import { AsyncContainer } from 'badphraim/core/AsyncContainer';
import { css, html, LitElement } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { CerumIcon } from './CerumIcon';
let SnackBar = SnackBar_1 = class SnackBar extends LitElement {
    static get is() {
        return 'snack-bar';
    }
    static get styles() {
        return css `
            * {
                --primary-background-color: hsl(213, 33%, 17%);
                --primary-foreground-color: white;
                --success-background-color: hsl(159, 60%, 70%);
                --success-foreground-color: hsl(159, 75%, 16%);
                --info-background-color: hsl(210, 60%, 70%);
                --info-foreground-color: hsl(210, 75%, 16%);
                --warning-background-color: hsl(28, 80%, 73%);
                --warning-foreground-color: hsl(28, 74%, 24%);
                --danger-background-color: hsl(3, 100%, 76%);
                --danger-foreground-color: hsl(360, 76%, 20%);
            }

            :host {
                position: fixed;
                z-index: 100;
                bottom: 20px;
                right: 20px;
                display: none;
                max-width: 50%;
            }

            :host(.active) {
                display: block;
            }

            .container {
                border-radius: 8px;
                box-shadow: 1px 1px 2px 2px #ededed;
                overflow: hidden;
            }

            .contents {
                padding: 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .primary {
                background-color: var(--primary-background-color);
                color: var(--primary-foreground-color);
            }

            .success {
                background-color: var(--success-background-color);
                color: var(--success-foreground-color);
            }

            .info {
                background-color: var(--info-background-color);
                color: var(--info-foreground-color);
            }

            .warning {
                background-color: var(--warning-background-color);
                color: var(--warning-foreground-color);
            }

            .danger {
                background-color: var(--danger-background-color);
                color: var(--danger-foreground-color);
            }

            .badge {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 10px;
                height: 32px;
                width: 32px;
                border-radius: 50%;
                cursor: pointer;
            }

            .badge cerum-icon {
                height: var(--cerum-icon-height, 24px);
                width: var(--cerum-icon-width, 24px);
            }

            .primary .badge cerum-icon {
                stroke: var(--primary-foreground-color);
            }

            .success .badge cerum-icon {
                stroke: var(--success-foreground-color);
            }

            .info .badge cerum-icon {
                stroke: var(--info-foreground-color);
            }

            .warning .badge cerum-icon {
                stroke: var(--warning-foreground-color);
            }

            .danger .badge cerum-icon {
                stroke: var(--danger-foreground-color);
            }

            .title-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-right: 50px;
                cursor: pointer;
            }

            .title {
                font-weight: 500;
            }

            .times {
                font-style: italic;
                font-size: 80%;
            }

            .close {
                text-align: right;
                cursor: pointer;
                user-select: none;
            }

            #remainder {
                height: 4px;
            }

            .primary #remainder {
                background-color: var(--primary-foreground-color);
            }

            .success #remainder {
                background-color: var(--success-foreground-color);
            }

            .info #remainder {
                background-color: var(--info-foreground-color);
            }

            .warning #remainder {
                background-color: var(--warning-foreground-color);
            }

            .danger #remainder {
                background-color: var(--danger-foreground-color);
            }
        `;
    }
    static push(snack) {
        const handleId = ++this.handleId;
        (() => __awaiter(this, void 0, void 0, function* () {
            (yield this.instance.getValue())._addToStack(snack, handleId);
        }))();
        return handleId;
    }
    static pushError(error) {
        const handleId = ++this.handleId;
        (() => __awaiter(this, void 0, void 0, function* () {
            const title = typeof error === 'string' ? 'Error' : error.http_status + ' ' + error.type;
            const subtitle = typeof error === 'string' ? error : error.message;
            (yield this.instance.getValue())._addToStack({
                title,
                subtitle,
                type: 'danger',
            }, handleId);
        }))();
        return handleId;
    }
    static revoke(handleId) {
        (() => __awaiter(this, void 0, void 0, function* () {
            (yield this.instance.getValue())._revokeFromStack(handleId);
        }))();
    }
    constructor() {
        super();
        this.snacks = [];
        if (!SnackBar_1.instance.hasValue()) {
            SnackBar_1.instance.setValue(this);
        }
    }
    /** @override */ render() {
        return html `
            ${this.currentSnack
            ? html `
                      <div class="container">
                          <div class="${this.currentSnack.type ? this.currentSnack.type : 'primary'}">
                              <div class="contents">
                                  <div class="badge" @click="${this._stopTimers}">
                                      <cerum-icon
                                          icon="cerum:${this.currentSnack.type
                ? this.currentSnack.type
                : 'info'}"
                                      ></cerum-icon>
                                  </div>
                                  <div class="title-container" @click="${this._doOnClick}">
                                      <div class="title">${this.currentSnack.title}</div>
                                      ${this.currentSnack.subtitle
                ? html ` <div class="subtitle">${this.currentSnack.subtitle}</div> `
                : ''}
                                  </div>
                                  <div style="display: flex; flex-direction: column;">
                                      <div
                                          class="close"
                                          @click="${(event) => this._removeFromStack(event)}"
                                      >
                                          <cerum-icon icon="cerum:close"></cerum-icon>
                                      </div>
                                      ${this.currentSnack.occurrences && this.currentSnack.occurrences > 0
                ? html `<div class="times">
                                                ${this.currentSnack.occurrences + ' times'}
                                            </div>`
                : ''}
                                  </div>
                              </div>
                              ${this.currentSnack.duration !== 0 ? html ` <div id="remainder"></div> ` : ''}
                          </div>
                      </div>
                  `
            : ''}
        `;
    }
    /** @override */ updated(changedProperties) {
        changedProperties.forEach((_oldValue, propertyName) => {
            switch (propertyName) {
                case 'currentSnack':
                    this._currentSnackObserver();
                    break;
            }
        });
        super.updated(changedProperties);
    }
    _addToStack(snack, handleId) {
        if (this.snacks.length) {
            const latestSnack = this.snacks[this.snacks.length - 1];
            if (latestSnack !== this.currentSnack) {
                if (latestSnack.title === snack.title && latestSnack.subtitle === snack.subtitle) {
                    latestSnack.occurrences = latestSnack.occurrences ? latestSnack.occurrences + 1 : 2;
                    if (handleId) {
                        if (!(latestSnack.handles instanceof Array)) {
                            latestSnack.handles = [];
                        }
                        latestSnack.handles.push(handleId);
                    }
                    return;
                }
            }
        }
        if (handleId) {
            if (snack.handles instanceof Array) {
                snack.handles.push(handleId);
            }
            else {
                snack.handles = [handleId];
            }
        }
        const pushedIndex = this.snacks.push(snack);
        if (pushedIndex === 1) {
            this.currentSnack = this.snacks[pushedIndex - 1];
        }
    }
    _currentSnackObserver() {
        if (this.currentSnack) {
            if (!this.classList.contains('active')) {
                this.classList.add('active');
            }
            if (this.currentSnack.duration !== 0) {
                const snackDuration = this.currentSnack.duration
                    ? this.currentSnack.duration * 1000
                    : SnackBar_1.defaultDuration;
                this.currentSnackStart = window.performance.now();
                this.currentSnackTimeout = window.setTimeout(() => {
                    this._removeCurrentItemFromStack();
                }, snackDuration);
                this.currentSnackInterval = window.setInterval(() => {
                    if (!this.currentSnackStart) {
                        return;
                    }
                    const currentSnackElapsed = window.performance.now() - this.currentSnackStart;
                    const remainderElement = this.shadowRoot && this.shadowRoot.getElementById('remainder');
                    remainderElement.style.width = 100 - (currentSnackElapsed / snackDuration) * 100 + '%';
                }, 10);
            }
        }
        else {
            if (this.classList.contains('active')) {
                this.classList.remove('active');
            }
        }
    }
    _doOnClick() {
        if (!this.currentSnack || !this.currentSnack.onClick) {
            return;
        }
        this.currentSnack.onClick();
    }
    _removeCurrentItemFromStack() {
        const splicedSnacks = this.snacks.splice(0, 1);
        if (splicedSnacks.length) {
            const removedSnack = splicedSnacks[0];
            if (removedSnack.hasOwnProperty('onClose') && removedSnack.onClose !== undefined) {
                removedSnack.onClose();
            }
        }
        if (this.snacks.length) {
            this.currentSnack = this.snacks[0];
        }
        else {
            this.currentSnack = undefined;
        }
        this._stopTimers();
    }
    _removeFromStack(event) {
        if (event.shiftKey) {
            this.snacks = [];
            this.currentSnack = undefined;
            this._stopTimers();
        }
        else {
            this._removeCurrentItemFromStack();
        }
    }
    _revokeFromStack(handleId) {
        if (this.currentSnack &&
            this.currentSnack.handles &&
            this.currentSnack.handles.length === 1 &&
            this.currentSnack.handles[0] === handleId) {
            this._removeCurrentItemFromStack();
        }
        this.snacks.forEach((snack, snackIndex) => {
            if (!snack.handles) {
                return;
            }
            const handleIndex = snack.handles.indexOf(handleId);
            if (handleIndex >= 0) {
                if (snack.occurrences && snack.occurrences > 1) {
                    snack.occurrences = snack.occurrences - 1;
                    snack.handles.splice(handleIndex, 1);
                }
                else {
                    this.snacks.splice(snackIndex, 1);
                }
            }
        });
    }
    _stopInterval() {
        if (this.currentSnackInterval) {
            clearInterval(this.currentSnackInterval);
            this.currentSnackInterval = undefined;
        }
    }
    _stopTimeout() {
        if (this.currentSnackTimeout) {
            clearTimeout(this.currentSnackTimeout);
            this.currentSnackTimeout = undefined;
        }
    }
    _stopTimers() {
        this._stopTimeout();
        this._stopInterval();
    }
};
SnackBar._deps = [CerumIcon];
SnackBar.defaultDuration = 5000;
SnackBar.handleId = 0;
SnackBar.instance = new AsyncContainer();
__decorate([
    state(),
    __metadata("design:type", Object)
], SnackBar.prototype, "currentSnack", void 0);
SnackBar = SnackBar_1 = __decorate([
    customElement(SnackBar.is),
    __metadata("design:paramtypes", [])
], SnackBar);
export { SnackBar };
