var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { Icon } from '@vaadin/icon';
import '@vaadin/icons';
let CerumIcon = class CerumIcon extends LitElement {
    constructor() {
        super(...arguments);
        this.icon = '';
        this.title = '';
        this.size = 24;
    }
    static get is() {
        return 'cerum-icon';
    }
    static get styles() {
        return css `
            vaadin-icon {
                height: var(--cerum-icon-height, 18px);
                width: var(--cerum-icon-width, 18px);
            }
        `;
    }
    /** @override */ render() {
        return html `<vaadin-icon icon="${this.icon}"></vaadin-icon>`;
    }
};
CerumIcon._deps = [Icon];
__decorate([
    property({ type: String }),
    __metadata("design:type", String)
], CerumIcon.prototype, "icon", void 0);
__decorate([
    property({ type: String }),
    __metadata("design:type", String)
], CerumIcon.prototype, "title", void 0);
__decorate([
    property({ type: Number }),
    __metadata("design:type", Number)
], CerumIcon.prototype, "size", void 0);
CerumIcon = __decorate([
    customElement(CerumIcon.is)
], CerumIcon);
export { CerumIcon };
