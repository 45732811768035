var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export class ServicesBase {
    getAuthorizationServer() {
        return __awaiter(this, void 0, void 0, function* () {
            const mf_data = yield this.getManifestData();
            if (mf_data.authorization_server) {
                return mf_data.authorization_server;
            }
            const url = yield this.getBaseUrl();
            return url + '/rest/as';
        });
    }
    getApplicationRoot() {
        if (this._application_root) {
            return this._application_root;
        }
        // Use <base href="" /> from index.html if set
        if (document.baseURI) {
            return (this._application_root = this._stripDownToFolder(document.baseURI));
        }
        const loc = document.location;
        let base_url = loc.protocol;
        base_url += '//';
        base_url += loc.hostname;
        if (loc.port) {
            base_url += ':' + loc.port;
        }
        if (loc.hostname === '127.0.0.1' || loc.hostname === 'localhost') {
            base_url += '/';
        }
        else {
            base_url += loc.pathname.replace(/\/[^/]*$/, '');
        }
        return (this._application_root = base_url);
    }
    _stripDownToFolder(url) {
        const qpos = url.indexOf('?');
        if (qpos >= 0) {
            url = url.substr(0, qpos);
        }
        return url.replace(/\/\w*?[.]html$/, '');
    }
    getGraphQLEndpoint(id) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = yield this.getBaseUrl();
            return url + '/graphql/' + id;
        });
    }
    getRestBaseUrl() {
        return __awaiter(this, void 0, void 0, function* () {
            return (yield this.getBaseUrl()) + '/rest';
        });
    }
    getServerDispatcherUrl() {
        return __awaiter(this, void 0, void 0, function* () {
            const url = yield this.getBaseUrl();
            return url + '/rest/serverdispatcher/url';
        });
    }
    getStaticUrl() {
        return __awaiter(this, void 0, void 0, function* () {
            const m = yield this.getManifestData();
            if (m.static_url) {
                return m.static_url;
            }
            return '';
        });
    }
    getVersion() {
        return __awaiter(this, void 0, void 0, function* () {
            const m = yield this.getManifestData();
            return m.version;
        });
    }
    getWebSocketUrl() {
        return __awaiter(this, void 0, void 0, function* () {
            const manifest = yield this.getManifestData();
            return manifest.websocket_url;
        });
    }
    _getBaseUrl() {
        return __awaiter(this, void 0, void 0, function* () {
            const m = yield this.getManifestData();
            if (m.service_base_url !== undefined) {
                return m.service_base_url;
            }
            throw new Error('Mangler service_base_url');
        });
    }
    getManifestData() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this._manifest) {
                return this._manifest;
            }
            /*
            const loc = document.location;
            let base_url = loc.protocol;
            base_url += "//";
            base_url += loc.host;
            if (loc.port) {
                base_url += ":" + loc.port;
            }
            base_url += loc.pathname.replace(/\/[^/]*$/, "/");
            */
            const manifest_url = this.getManifestUrl();
            //                CerumServices.getAjaxHandler().doAjax(manifest_url, null, delegate);
            const raw_manifest = yield this.getAjaxHandler().doAjax(manifest_url);
            try {
                const obj = JSON.parse(raw_manifest);
                if (obj) {
                    return (this._manifest = obj);
                }
            }
            catch (exception) {
                console.error('Manifest lastet ned fra [' + manifest_url + '] er ikke gyldig', {
                    exception,
                    raw_manifest,
                });
            }
            throw new Error('Får ikke tak i (gyldig) manifest fra [' + manifest_url + ']');
        });
    }
    _setManifest(x) {
        this._manifest = x;
    }
    getManifestUrl() {
        const base_url = this.getApplicationRoot();
        console.log('Application root: ', base_url);
        const manifest_url = base_url + '/manifest.json';
        console.log('Manifest url: ', manifest_url);
        return manifest_url;
    }
}
