var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
let BoostImageContact = class BoostImageContact extends LitElement {
    constructor() {
        super(...arguments);
        this.smallScreen = false;
    }
    static get is() {
        return 'boost-image-contact';
    }
    static get styles() {
        return css `
            .img {
                padding-top: 50px;
                padding-right: 100px;
            }
            @media screen and (max-width: 1300px) {
                .img {
                    padding-right: 0px;
                }
            }
        `;
    }
    /** @override */ render() {
        return html `
            <svg
                width="400"
                height="400"
                xmlns="http://www.w3.org/2000/svg"
                data-name="Layer 1"
                viewBox="0 0 1000 1000"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                class="img"
            >
                <path
                    id="adc31fd7-f1bd-4275-a5ba-966b160b5074-427"
                    data-name="bcf9023f-ac5b-481c-aaa4-352627f2cc6c"
                    d="M971.76282,257.7844h-4V148.2394a63.4,63.4,0,0,0-63.4-63.4h-232.087a63.4,63.4,0,0,0-63.4,63.4v600.974a63.4,63.4,0,0,0,63.4,63.4H904.36182a63.4,63.4,0,0,0,63.4-63.4v-413.453h4Z"
                    transform="translate(-104.5058 -84.8394)"
                    fill="#3f3d56"
                />
                <path
                    id="ba1c2519-d6d5-475e-b818-d50dee449681-428"
                    data-name="f7950315-d447-41b3-b140-1076b53a001f"
                    d="M906.92078,101.3324h-30.295a22.495,22.495,0,0,1-20.828,30.994h-132.959a22.49506,22.49506,0,0,1-20.827-30.991h-28.3a47.348,47.348,0,0,0-47.348,47.348v0h0v600.089a47.348,47.348,0,0,0,47.348,47.348H906.91181a47.348,47.348,0,0,0,47.348-47.348v-.00013h0V148.68041a47.348,47.348,0,0,0-47.348-47.348h0Z"
                    transform="translate(-104.5058 -84.8394)"
                    fill="#fff"
                />
                <circle
                    id="b1347523-5d0d-4426-8baa-7e4ff69466d7"
                    data-name="f890d8c3-e731-49cb-a2e5-5c59e9f60352"
                    cx="790.71679"
                    cy="716.58541"
                    r="26"
                    transform="matrix(0.16018, -0.98709, 0.98709, 0.16018, -147.78029, 1297.46834)"
                    fill="#e6e6e6"
                />
                <g id="f07fc029-d661-4a9c-a387-d7f638457fb7" data-name="a1a6c59b-3717-4dad-b370-97c9e621082a">
                    <rect
                        id="b6842365-6bf6-421b-a76f-142cad50747e"
                        data-name="aadecade-068c-4f25-a9ef-29b7b9ecc005"
                        x="542.06"
                        y="330.46009"
                        width="287.49203"
                        height="56.76102"
                        fill="#e6e6e6"
                    />
                    <path
                        id="e7fbf384-106f-4cb3-8269-5d203db1b915-429"
                        data-name="be47b05f-620a-4a83-9c0d-c2bf8bc76fef"
                        d="M652.69483,465.93241h275.235v-44.5h-275.235Z"
                        transform="translate(-104.5058 -84.8394)"
                        fill="#fff"
                    />
                    <path
                        id="f5c17ec5-dcf3-493c-940c-3f483179e7ee-430"
                        data-name="a28d2100-c4d0-4925-85de-1e7d52571659"
                        d="M676.78882,426.35746a16.955,16.955,0,1,1-16.955,16.955,16.95493,16.95493,0,0,1,16.955-16.955Z"
                        transform="translate(-104.5058 -84.8394)"
                        fill="#34f758"
                    />
                    <rect
                        id="adafbf9e-f9c7-4cfa-9aad-5eb67346e79b"
                        data-name="bf3352be-1ca2-4e23-bbaf-f2f5ef64af93"
                        x="612.82703"
                        y="351.83808"
                        width="167.33499"
                        height="3.686"
                        fill="#e6e6e6"
                    />
                    <rect
                        id="be8534b7-df20-46df-9f05-64e7092d168b"
                        data-name="bb396d43-890f-415a-a911-0c113deb0c88"
                        x="612.82703"
                        y="361.421"
                        width="167.33499"
                        height="3.686"
                        fill="#e6e6e6"
                    />
                </g>
                <g id="e9d029e2-4dd4-4647-b108-53b557302a97" data-name="a76a8676-f787-4aac-bc26-4d28e625d812">
                    <rect
                        id="b4cf72e5-4ba6-4e2b-b14c-1171a63de167"
                        data-name="a3fed62c-a480-4fda-a6ab-9c7719a11d03"
                        x="542.06"
                        y="411.46009"
                        width="287.49203"
                        height="56.76102"
                        fill="#e6e6e6"
                    />
                    <path
                        id="abc8e350-e50b-4525-a5c6-ed7f7a98e41a-431"
                        data-name="b2013604-1388-480a-bf39-bc8d6d6f2c30"
                        d="M652.69483,546.93238h275.235v-44.5h-275.235Z"
                        transform="translate(-104.5058 -84.8394)"
                        fill="#fff"
                    />
                    <circle
                        id="b1ceed28-7a6c-48a0-a241-2405df300e97"
                        data-name="a10785fe-0480-48e0-a209-79d56eb9fb83"
                        cx="572.28302"
                        cy="439.47306"
                        r="16.95499"
                        fill="#34f758"
                    />
                    <rect
                        id="afcf7a73-8185-49b7-bb67-77f1fdddc979"
                        data-name="e4ba10f0-0456-4e2c-9d9e-10f625ff54d2"
                        x="612.82703"
                        y="432.83808"
                        width="167.33499"
                        height="3.686"
                        fill="#e6e6e6"
                    />
                    <rect
                        id="be6a92c9-7616-468a-a67a-29b28eb9632c"
                        data-name="a086a789-ddcc-4a0c-b196-f7973999ed5b"
                        x="612.82703"
                        y="442.421"
                        width="167.33499"
                        height="3.686"
                        fill="#e6e6e6"
                    />
                </g>
                <g id="f4943932-aa58-49fe-bbcc-e4a4546cf491" data-name="a5a5e7c6-2328-4bbd-a0bc-78368cb001e7">
                    <rect
                        id="b57d1f36-8955-4db1-8860-63423d74537a"
                        data-name="be3c08ee-83a5-46f6-b550-c65a1a75b35b"
                        x="542.06"
                        y="492.46009"
                        width="287.49203"
                        height="56.76102"
                        fill="#e6e6e6"
                    />
                    <path
                        id="a63cfb64-3b51-4102-91ab-4269507e4ee8-432"
                        data-name="beee7471-5a7b-446e-b565-9b3273ba023a"
                        d="M652.69483,627.93238h275.235v-44.5h-275.235Z"
                        transform="translate(-104.5058 -84.8394)"
                        fill="#fff"
                    />
                    <circle
                        id="a17c1f24-f75d-4e1e-acc5-863bf9fe01d1"
                        data-name="f8684faf-2b28-4db0-a7a9-e69102a88591"
                        cx="572.28302"
                        cy="520.47307"
                        r="16.955"
                        fill="#34f758"
                    />
                    <rect
                        id="f940ca31-2ab4-48ba-9a2d-1c1060c535fd"
                        data-name="e7cf9a62-74c8-4968-991d-f63bde6d8d74"
                        x="612.82703"
                        y="513.83805"
                        width="167.33499"
                        height="3.68597"
                        fill="#e6e6e6"
                    />
                    <rect
                        id="bd14fb11-4b03-46c5-a221-969a54d151d4"
                        data-name="b57b1a7e-a030-4cfd-a852-c296d4f42a3d"
                        x="612.82703"
                        y="523.421"
                        width="167.33499"
                        height="3.68597"
                        fill="#e6e6e6"
                    />
                </g>
                <circle cx="685.806" cy="193.91798" r="80" fill="#51f96f" />
                <path
                    d="M829.3118,253.362v50.79069a3.62692,3.62692,0,0,1-3.62791,3.62791H754.93971a3.62692,3.62692,0,0,1-3.62791-3.62791V253.362a3.3941,3.3941,0,0,1,.21789-1.21538,3.58941,3.58941,0,0,1,2.875-2.36719,3.45288,3.45288,0,0,1,.535-.04534h70.74418a3.45267,3.45267,0,0,1,.535.04534,3.58876,3.58876,0,0,1,2.875,2.36719A3.3941,3.3941,0,0,1,829.3118,253.362Z"
                    transform="translate(-104.5058 -84.8394)"
                    fill="#fff"
                />
                <path
                    d="M829.09391,252.14665,791.391,280.20852a1.78663,1.78663,0,0,1-2.1585,0l-37.70286-28.06187a3.58941,3.58941,0,0,1,2.875-2.36719L790.3118,276.499l35.90707-26.71955A3.58876,3.58876,0,0,1,829.09391,252.14665Z"
                    transform="translate(-104.5058 -84.8394)"
                    fill="#34f758"
                />
                <path
                    d="M323.05822,343.9431c0,24.80818,22.93911,21.05586,51.23594,21.05586s51.236,3.75232,51.236-21.05586-11.20518-68.7825-51.23595-68.7825C332.88429,275.1606,323.05822,319.13492,323.05822,343.9431Z"
                    transform="translate(-104.5058 -84.8394)"
                    fill="#2f2e41"
                />
                <polygon
                    points="378.996 712.276 395.376 706.996 382.806 641.306 358.631 649.099 378.996 712.276"
                    fill="#a0616a"
                />
                <path
                    d="M477.6006,793.1145l32.25683-10.39775.00131-.00042a21.59777,21.59777,0,0,1,27.18244,13.93124l.2153.668-52.814,17.024Z"
                    transform="translate(-104.5058 -84.8394)"
                    fill="#2f2e41"
                />
                <polygon
                    points="177.937 712.818 195.147 712.817 203.334 646.438 177.934 646.439 177.937 712.818"
                    fill="#a0616a"
                />
                <path
                    d="M278.05355,792.039l33.89124-.00137h.00137a21.59775,21.59775,0,0,1,21.59837,21.598v.70186l-55.49.00206Z"
                    transform="translate(-104.5058 -84.8394)"
                    fill="#2f2e41"
                />
                <path
                    d="M260.22545,541.72568h0a15.87213,15.87213,0,0,1,2.65048-22.74609,15.30114,15.30114,0,0,1,1.97536-1.26116l56.1363-132.02687a12.70683,12.70683,0,1,1,23.94385,8.51419L287.12308,525.5664a15.86458,15.86458,0,0,1-26.89763,16.15928Z"
                    transform="translate(-104.5058 -84.8394)"
                    fill="#a0616a"
                />
                <path
                    d="M467.807,554.972a15.76759,15.76759,0,0,1-6.34177-19.57949L403.63941,403.988a12.70672,12.70672,0,1,1,23.94385-8.51556L483.737,527.5445a15.29976,15.29976,0,0,1,1.97536,1.26116,15.87212,15.87212,0,0,1,2.65049,22.74609h0a15.8867,15.8867,0,0,1-12.27505,5.76843A15.69224,15.69224,0,0,1,467.807,554.972Z"
                    transform="translate(-104.5058 -84.8394)"
                    fill="#a0616a"
                />
                <circle
                    cx="374.46548"
                    cy="330.48061"
                    r="34.47692"
                    transform="translate(-217.13504 135.27564) rotate(-28.6632)"
                    fill="#a0616a"
                />
                <path
                    d="M325.90919,477.12968,319.07084,397.7a22.615,22.615,0,0,1,25.37122-24.376l63.53669,8.04263a22.5641,22.5641,0,0,1,19.6823,24.48293c-2.523,28.38155-9.56321,78.20829-28.36681,93.05432l-.28685.22618Z"
                    transform="translate(-104.5058 -84.8394)"
                    fill="#1f477d"
                />
                <path
                    d="M307.3568,757.49074l-26.35925-3.12411a6.294,6.294,0,0,1-5.63786-6.30168c.05792-12.68424.02536-25.67555-.00582-38.23779-.26937-107.63578-.52366-209.30162,50.17184-237.35554l.27382-.15079,73.73357,25.522,2.82287,49.399,59.85775,84.79921a75.92209,75.92209,0,0,1,11.0228,23.05316l22.23991,78.12467a6.31739,6.31739,0,0,1-4.65532,7.885l-25.92435,5.98228a6.323,6.323,0,0,1-7.339-3.948l-34.736-86.48395-70.58445-76.0561a2.10544,2.10544,0,0,0-3.57922,1.04457L314.23011,752.45981a6.30039,6.30039,0,0,1-6.87331,5.03093Z"
                    transform="translate(-104.5058 -84.8394)"
                    fill="#132c4f"
                />
                <path
                    d="M414.30031,327.09837v0H400.664l-6.01586-16.84469-1.2029,16.84469h-6.51759l-3.50931-9.82607-.70186,9.82607H334.288v0a36.49679,36.49679,0,0,1,36.4968-36.4968h7.01869A36.49679,36.49679,0,0,1,414.30031,327.09837Z"
                    transform="translate(-104.5058 -84.8394)"
                    fill="#2f2e41"
                />
            </svg>
        `;
    }
};
BoostImageContact._deps = [];
__decorate([
    property({ type: Boolean }),
    __metadata("design:type", Boolean)
], BoostImageContact.prototype, "smallScreen", void 0);
BoostImageContact = __decorate([
    customElement(BoostImageContact.is)
], BoostImageContact);
export { BoostImageContact };
