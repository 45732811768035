var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { css, html, LitElement } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { BoostWebsiteFooter } from '../components/BoostWebsiteFooter';
import { BoostWebsiteHeader } from '../components/BoostWebsiteHeader';
let BoostWebsiteApp = class BoostWebsiteApp extends LitElement {
    constructor() {
        super(...arguments);
        this.smallScreen = false;
        this.toggleMembershipInformation = false;
        this.toggleAdminInformation = false;
    }
    static get is() {
        return 'boost-website-app';
    }
    static get styles() {
        return css `
            a {
                color: white;
            }

            li {
                padding-bottom: 1rem;
            }

            .background-img-container {
                height: 32em;
                background: linear-gradient(
                        90deg,
                        rgba(32, 67, 115, 0.329551889115021) 0%,
                        rgba(32, 67, 115, 0.4556023092830882) 4%,
                        rgba(32, 67, 115, 0) 67%
                    ),
                    url('images/new-website/app-background-1.jpg');
                background-size: cover;
                margin-top: 8rem;
                background-position: center;
            }
            .header-h1 {
                color: #ffffff;
                transform: translate(0px, 10rem);
                width: 50%;
                padding-left: 6rem;
                line-height: 1.4;
                font-size: 50px;
                font-weight: normal;
            }

            .header-text {
                font-size: 36px;
                color: #ffffff;
                width: 88%;
                font-weight: lighter;
            }

            .text-container {
                max-width: 86rem;
                display: flex;
                flex-direction: column;
                gap: 2rem;
                margin: 6rem auto;
                width: 50%;
                line-height: 1.6;
            }

            .screenshot-container {
                max-width: 86rem;
                margin: 4rem auto;
                display: flex;
                justify-content: center;
            }

            .screenshots {
                height: 20%;
                width: 17%;
            }

            .read-more-box {
                display: flex;
                flex-direction: column;
                padding: 1rem;
                background-color: #204373;
                border-radius: 1rem;
                padding: 6px;
            }

            .read-more-container {
                display: flex;
                flex-direction: column;
                max-width: 86rem;
                margin: 0 auto;
                width: 50%;
                gap: 2rem;
                cursor: pointer;
            }

            .h1-p-container {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding: 0rem 2rem 0rem 2rem;
                align-items: center;
            }

            .list-container {
                width: 86%;
                padding-left: 5rem;
            }

            @media screen and (max-width: 1400px) {
                .list-container {
                    width: 85%;
                    padding-left: 3rem;
                }
            }

            @media screen and (max-width: 1000px) {
                .background-img-container {
                    background-repeat: no-repeat;
                    margin-top: 7rem;
                    background-size: cover;
                    height: 20rem;
                    width: 100%;
                    background-image: linear-gradient(
                            90deg,
                            rgba(32, 67, 115, 0.5942576859845501) 0%,
                            rgba(32, 67, 115, 0.5662464815027574) 100%,
                            rgba(32, 67, 115, 0) 100%
                        ),
                        url('images/new-website/app-background-1.jpg');
                    background-position: revert;
                }
                .header-h1 {
                    width: 98%;
                    padding-left: 10px;
                    transform: translate(0px, 5rem);
                    font-size: 36px;
                }

                .header-text {
                    font-size: 20px;
                }

                .text-container {
                    width: 92%;
                    margin: 1rem;
                }

                .read-more-container {
                    width: 96%;
                }

                .list-container {
                    padding-left: 2rem;
                }

                .screenshots {
                    height: 36%;
                    width: 67%;
                }

                .screenshot-container {
                    flex-direction: column;
                    align-items: center;
                }
            }
        `;
    }
    /** @override */ render() {
        return html `
            <boost-website-header
                @screen-size-changed="${(event) => (this.smallScreen = event.detail.value)}"
            ></boost-website-header>
            <div class="background-img-container">
                <h1 class="header-h1">
                    Medlemsappen
                    <p class="header-text">
                        Vi er stolte av vår egenutviklede app! Den har mange nyttige funksjoner for medlemmer
                        og administratorer.
                    </p>
                </h1>
            </div>
            <div class="text-container">
                <p>
                    Som <i style="font-weight:bold">første medlemssystem</i> i Norge kunne vi lansere vår
                    medlemsapp med booking, medlemsinformasjon og integrert adgangskontroll tilbake i 2018. Nå
                    var det slutt på glemte og mistede adganskort og portaler for booking av kurs og
                    gruppetimer. Appen vår har ulike funksjoner for medlemmer og instruktører. Hvis ønskelig
                    kan vi tilpasse appen med ditt senters farger.
                </p>
                <p>
                    Appen kan du laste ned her:
                    <br />
                    <span style="font-weight: bold">IOS:</span>
                    <a href="https://apps.apple.com/no/app/boost-system/id1319312136">Appstore</a>
                    <br />
                    <span style="font-weight: bold">Android:</span>
                    <a
                        href=${'https://play.google.com/store/apps/details?id=no.cerum.boost.playstore&hl=no&pli=1'}
                        >Google Play
                    </a>
                </p>
            </div>
            <div class="read-more-container">
                <div
                    class="read-more-box"
                    id="toggle-container-2"
                    @click="${() => this._toggleMembershipInformation()}"
                >
                    <div class="h1-p-container">
                        <p>Les mer om appen for medlemmer</p>
                        ${!this.toggleMembershipInformation
            ? html `<p style="font-size:30px;">&#62;</p>`
            : html ` <p style="font-size:30px;">&#8744;</p>`}
                    </div>
                    ${this.toggleMembershipInformation
            ? html `<ul class="list-container" id="toggle-list-1">
                              <li>
                                  <span style="font-weight:bold">Min profil:</span> Her ser de sitt medlemskap
                                  og har en logg over trening og fakturahistorikk.
                              </li>
                              <li>
                                  <span style="font-weight:bold">Adgang:</span> Hit går medlemmer for å åpne
                                  døren på senteret og/eller melde sin ankomst på gruppetimer de er påmeldt.
                              </li>
                              <li>
                                  <span style="font-weight:bold">Booking:</span> Her kan medlemmene gå for å
                                  booke seg på timer. Det kan være timer de har tilgang på via sitt medlemskap
                                  eller så kan de bruke sitt elektroniske klippekort for å booke. For
                                  medlemmer uten tilgang kan det settes opp kjøp av timer eller kurs direkte i
                                  appen. Dette betales da med Vipps.
                              </li>
                              <li>
                                  <span style="font-weight:bold">Senter:</span> Her ser medlemmer oversikt
                                  over når det er flest og færrest og trener. I tillegg finner de
                                  kontaktinformasjon til senteret.
                              </li>
                              <li>
                                  <span style="font-weight:bold">Nyheter:</span> Vårt nyeste tilskudd til
                                  appen. Her kan medlemmer lese nyheter og annet dere velger å publisere i
                                  appen. Hvis dere ønsker så kan dere også velge å sende et push-varsel til
                                  medlemmene når dere publiserer noe nytt.
                              </li>
                          </ul>`
            : html ``}
                </div>
                <div
                    class="read-more-box"
                    id="toggle-container-2"
                    @click="${() => this._toggleAdminInformation()}"
                >
                    <div class="h1-p-container">
                        <p>Appen for administrator og instruktør</p>
                        ${!this.toggleAdminInformation
            ? html `<p style="font-size:30px;">&#62;</p>`
            : html ` <p style="font-size:30px;">&#8744;</p>`}
                    </div>
                    ${this.toggleAdminInformation
            ? html `<ul class="list-container" id="toggle-list-2">
                              <li>
                                  <span style="font-weight:bold">Kanseller time:</span> Noen ganger må en time
                                  avlyses. Dette gjøres direkte i appen og selvsagt kan man da også sende en
                                  beskjed til de påmeldte om avlysingen.
                              </li>
                              <li>
                                  <span style="font-weight:bold"> “Dør-admin”:</span> Dine medlemmer er
                                  avhengig av å være i nærheten av dør for å få tilgang til å åpne den. Dette
                                  kan være upraktisk for en administratorbruker. Disse kan åpne døren fra
                                  uansett hvor i verden man er. Lurt hvis man har et medlem som av en eller
                                  annen grunn ikke får lukket seg inn.
                              </li>
                              <li>
                                  <span style="font-weight:bold">Instruktør-rapport:</span> Her kan dine
                                  instruktører få tilgang til fulle rapporter om sine timer. De kan se antall
                                  deltakere og belegg på sine timer
                              </li>
                              <li>
                                  <span style="font-weight:bold">Merk medlemmer:</span> Merk medlemmer som
                                  ankommet eller fraværende på gruppetime. Instruktøren kan sjekke ankomne mot
                                  lista i appen og oppdatere direkte i appen hvis det er avvik.
                              </li>
                          </ul>`
            : html ``}
                </div>
            </div>
            <div class="screenshot-container">
                <img src="images/new-website/screenshotProfile.png" class="screenshots" />
                <img src="images/new-website/screenshotBooking.png" class="screenshots" />
                <img src="images/new-website/screenshotAccess.png" class="screenshots" />
                <img src="images/new-website/screenshotCenter.png" class="screenshots" />
                <img src="images/new-website/screenshotNews.png" class="screenshots" />
            </div>
            <boost-website-footer></boost-website-footer>
        `;
    }
    _toggleMembershipInformation() {
        if (this.toggleMembershipInformation) {
            this.toggleMembershipInformation = false;
        }
        else {
            this.toggleMembershipInformation = true;
        }
        console.log(this.toggleMembershipInformation, '1');
    }
    _toggleAdminInformation() {
        if (this.toggleAdminInformation) {
            this.toggleAdminInformation = false;
        }
        else {
            this.toggleAdminInformation = true;
        }
        console.log(this.toggleAdminInformation, '2');
    }
};
BoostWebsiteApp._deps = [BoostWebsiteHeader, BoostWebsiteFooter];
__decorate([
    property({ type: Boolean }),
    __metadata("design:type", Boolean)
], BoostWebsiteApp.prototype, "smallScreen", void 0);
__decorate([
    state(),
    __metadata("design:type", Boolean)
], BoostWebsiteApp.prototype, "toggleMembershipInformation", void 0);
__decorate([
    state(),
    __metadata("design:type", Boolean)
], BoostWebsiteApp.prototype, "toggleAdminInformation", void 0);
BoostWebsiteApp = __decorate([
    customElement(BoostWebsiteApp.is)
], BoostWebsiteApp);
export { BoostWebsiteApp };
