import { LoadingBoxHandle } from './LoadingBoxHandle';
/**
 * Helper utility for loading-status
 */
export class LoadingBoxBase {
    /** @override */ getHandle() {
        const h = new LoadingBoxHandle(this);
        return h;
    }
}
