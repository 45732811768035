export class LoadingBoxHandle {
    constructor(box) {
        this.box = box;
        this._started = 0;
        this._ended = 0;
    }
    /** @override */ end() {
        if (!this._started) {
            return;
        }
        if (this._ended) {
            return;
        }
        this._ended = new Date().getTime();
        this.box.end();
    }
    /** @override */ start() {
        if (!this._started) {
            this._started = new Date().getTime();
            this.box.start();
        }
    }
}
