var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
let BoostWebsiteProfilePictureIcon = class BoostWebsiteProfilePictureIcon extends LitElement {
    constructor() {
        super(...arguments);
        this.smallScreen = false;
    }
    static get is() {
        return 'boost-website-profile-picture-icon';
    }
    static get styles() {
        return css ``;
    }
    /** @override */ render() {
        return html `
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512 "
                height="25"
                width="25"
                max-height="25"
            >
                <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                <path
                    d="M224 256c70.7 0 128-57.31 128-128s-57.3-128-128-128C153.3 0 96 57.31 96 128S153.3 256 224 256zM274.7 304H173.3C77.61 304 0 381.6 0 477.3c0 19.14 15.52 34.67 34.66 34.67h378.7C432.5 512 448 496.5 448 477.3C448 381.6 370.4 304 274.7 304z"
                    fill="white"
                />
            </svg>
        `;
    }
};
BoostWebsiteProfilePictureIcon._deps = [];
__decorate([
    property({ type: Boolean }),
    __metadata("design:type", Boolean)
], BoostWebsiteProfilePictureIcon.prototype, "smallScreen", void 0);
BoostWebsiteProfilePictureIcon = __decorate([
    customElement(BoostWebsiteProfilePictureIcon.is)
], BoostWebsiteProfilePictureIcon);
export { BoostWebsiteProfilePictureIcon };
