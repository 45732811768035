/* tslint:disable: class-name */
/*
 * AUTOGENERATED FILE - DO NOT EDIT
 */
import { APIRestClient } from '../../../APIRestClient';
import { ewRestRequestWebContact } from '../../../schemas/ewRestRequestWebContact';
import { ewRestRequestWebLiveMesse } from '../../../schemas/ewRestRequestWebLiveMesse';
export class WebAPI extends APIRestClient {
    /**
     * Send inn bruker sin henvendelse
     * Implementert hovedsakelig i *ewRestResourceWebContact*::*create*
     */
    static postV1WebContact(__QUERY, __DATA) {
        return this._post('/v1/web/contact', {}, __QUERY, JSON.stringify(__DATA), ewRestRequestWebContact.classOf(ewRestRequestWebContact, new ewRestRequestWebContact()), [], 'json');
    }
    /**
     * Send inn bruker sin innmelding
     * Implementert hovedsakelig i *ewRestResourceWebLiveMesse*::*create*
     */
    static postV1WebLivemesse(__DATA) {
        return this._post('/v1/web/livemesse', {}, null, JSON.stringify(__DATA), ewRestRequestWebLiveMesse.classOf(ewRestRequestWebLiveMesse, new ewRestRequestWebLiveMesse()), [], 'json');
    }
}
