var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
let BoostWebsiteLogInIcon = class BoostWebsiteLogInIcon extends LitElement {
    constructor() {
        super(...arguments);
        this.smallScreen = false;
    }
    static get is() {
        return 'boost-website-log-in-icon';
    }
    static get styles() {
        return css `
            @media screen and (max-width: 600px) {
                #smallscreen-image {
                    position: absolute;
                    opacity: 0.2;
                }
            }
        `;
    }
    /** @override */ render() {
        return html `
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="25" height="25">
                <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                <path
                    d="M344.7 238.5l-144.1-136C193.7 95.97 183.4 94.17 174.6 97.95C165.8 101.8 160.1 110.4 160.1 120V192H32.02C14.33 192 0 206.3 0 224v64c0 17.68 14.33 32 32.02 32h128.1v72c0 9.578 5.707 18.25 14.51 22.05c8.803 3.781 19.03 1.984 26-4.594l144.1-136C354.3 264.4 354.3 247.6 344.7 238.5zM416 32h-64c-17.67 0-32 14.33-32 32s14.33 32 32 32h64c17.67 0 32 14.33 32 32v256c0 17.67-14.33 32-32 32h-64c-17.67 0-32 14.33-32 32s14.33 32 32 32h64c53.02 0 96-42.98 96-96V128C512 74.98 469 32 416 32z"
                    fill="#24c34c"
                />
            </svg>
        `;
    }
};
BoostWebsiteLogInIcon._deps = [];
__decorate([
    property({ type: Boolean }),
    __metadata("design:type", Boolean)
], BoostWebsiteLogInIcon.prototype, "smallScreen", void 0);
BoostWebsiteLogInIcon = __decorate([
    customElement(BoostWebsiteLogInIcon.is)
], BoostWebsiteLogInIcon);
export { BoostWebsiteLogInIcon };
