var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Router } from '@vaadin/router';
import { css, html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
let BoostWebsiteFooterWithMap = class BoostWebsiteFooterWithMap extends LitElement {
    static get is() {
        return 'boost-website-footer-with-map';
    }
    static get styles() {
        return css `
            h1 {
                color: #ffffff;
            }

            p {
                color: #ffffff;
            }

            .flex-container {
                display: flex;
                flex-direction: row;
                width: 100%;
                gap: 20px;
                justify-content: space-evenly;
                margin-top: 4rem;
            }

            .org-container {
                justify-content: space-evenly;
                display: flex;
                margin-top: 3rem;
            }

            .map-container {
                display: flex;
                flex-direction: column;
                margin-top: 4rem;
                margin-bottom: 1rem;
            }

            .map {
                width: 65%;
                border-radius: 10px;
            }

            .map-span {
                color: #ffff;
                font-size: 26px;
            }

            .map-text {
                line-height: 1.3;
                font-size: 16px;
            }

            .map-text-container {
                width: 50%;
                display: flex;
                flex-direction: row;
                gap: 40px;
                align-items: center;
            }

            img {
                height: 40px;
                width: 40px;
            }

            .contact-container {
                display: flex;
                flex-direction: column;
                gap: 20px;
                width: 24%;
                align-self: center;
            }

            .icon-text-container {
                display: flex;
                flex-direction: row-reverse;
                align-items: center;
                gap: 20px;
                justify-content: start;
            }

            .text-container {
                display: flex;
                flex-direction: column;
            }

            .contact-btn {
                height: 3rem;
                width: 14rem;
                background-color: #2dfe53;
                border-radius: 50px;
                border: none;
                color: #132c4f;
                font-size: 16px;
                cursor: pointer;
            }

            .contact-h1 {
                font-weight: normal;
                font-size: 46px;
                margin: 0;
            }

            @media screen and (max-width: 1200px) {
                .map-container {
                    flex-direction: column;
                    margin-top: 4rem;
                    margin-bottom: 0rem;
                }

                .contact-container {
                    width: 90%;
                }

                .contact-h1 {
                    font-size: 36px;
                }

                .map-text-container {
                    flex-direction: column-reverse;
                    width: 90%;
                    gap: 10px;
                    align-items: start;
                    padding-left: 23px;
                    margin-top: 2rem;
                }

                .map {
                    width: 100%;
                }

                .map-text {
                    font-size: 16px;
                }

                .flex-container {
                    flex-direction: column;
                }

                .org-container {
                    margin-top: 0;
                    flex-direction: column;
                    align-items: center;
                }
            }
        `;
    }
    /** @override */ render() {
        return html `
            <div class="map-container">
                <div class="flex-container">
                    <div class="contact-container">
                        <div class="icon-text-container">
                            <h1 class="contact-h1">Ta kontakt</h1>
                        </div>
                        <p style="margin-top:0;">
                            Ta gjerne kontakt med oss for en hyggelig, uforpliktende prat.
                        </p>
                        <button class="contact-btn" @click="${() => Router.go('/contact')}">
                            Kontakt oss
                        </button>
                    </div>
                    <div class="map-text-container">
                        <div class="map">
                            <google-map coordinates="66.3131328,14.127023"></google-map>
                        </div>
                        <div class="text-container">
                            <span class="map-span">Besøksadresse</span>
                            <p class="map-text">
                                Skysoft AS <br />
                                Midtre gate 5 <br />
                                8624 Mo i Rana
                            </p>

                            <span class="map-span">Fakturaadresse</span>
                            <p class="map-text">
                                Skysoft AS <br />
                                Postboks 413 <br />
                                8624 Mo i Rana
                            </p>
                        </div>
                    </div>
                </div>
                <div class="org-container">
                    <p style="color: #d4d4d4; font-size: medium;">
                        &copy; Skysoft AS &mdash; Org. nummer 999 290 396
                    </p>
                    <a
                        style="color: #d4d4d4; text-decoration:none; font-size: medium;"
                        href="/privacypolicy"
                        target="_blank"
                    >
                        Personvernerklæring & bruksvilkår
                    </a>
                </div>
            </div>
        `;
    }
};
BoostWebsiteFooterWithMap._deps = [];
BoostWebsiteFooterWithMap = __decorate([
    customElement(BoostWebsiteFooterWithMap.is)
], BoostWebsiteFooterWithMap);
export { BoostWebsiteFooterWithMap };
