class FakeAbortController {
    static create() {
        return {
            abort() {
                throw new Error('AbortController/AbortSignal not supported in browser');
            },
            signal: this.createSignal(),
        };
    }
    static createSignal() {
        return {
            aborted: false,
            reason: undefined,
            throwIfAborted() {
                throw new Error('AbortController/AbortSignal not supported in browser');
            },
            onabort() {
                throw new Error('AbortController/AbortSignal not supported in browser');
            },
            addEventListener() {
                throw new Error('AbortController/AbortSignal not supported in browser');
            },
            removeEventListener() {
                throw new Error('AbortController/AbortSignal not supported in browser');
            },
            dispatchEvent(_event) {
                throw new Error('AbortController/AbortSignal not supported in browser');
            },
        };
    }
}
export class HTTPPromiseAborter {
    constructor() {
        if ('AbortController' in window) {
            this.controller = new AbortController();
        }
        else {
            // IE11 har ikke støtte for AbortController
            // Den trengs bl.a. i Outlook-pluginet
            this.controller = FakeAbortController.create();
        }
    }
    abort() {
        const signal = this.controller.signal;
        if (!signal.aborted) {
            this.controller.abort();
            if (!signal.aborted && signal.onabort) {
                signal.onabort(new CustomEvent('abort'));
            }
        }
        if (this.rejector) {
            this.rejector('Request aborted: ' + this.url);
        }
    }
}
export class HTTPPromise extends Promise {
    setAborter(aborter) {
        this.aborter = aborter;
        return this;
    }
    static newWithFallback(executer, aborter) {
        /*
         * The typedefs for a native promise does not allow for the resolve-callback to be declared`async` (return a Promise<void>).
         * This will to the best of our knowledge have no negative consequences. So we just allow it and coerce it down here with a cast
         */
        const executerAdjusted = executer;
        try {
            return new HTTPPromise(executerAdjusted).setAborter(aborter);
        }
        catch (error) {
            if (!(error instanceof TypeError && error.message == 'undefined is not a promise')) {
                console.error(error);
            }
            const prom = new Promise(executerAdjusted);
            prom.abort = () => {
                aborter.abort();
            };
            return prom;
        }
    }
    static newWithSignal(wrapper) {
        const aborter = new HTTPPromiseAborter();
        return this.newWithFallback((resolve, reject) => {
            wrapper(aborter.controller.signal).then(resolve).catch(reject);
        }, aborter);
    }
    abort() {
        if (this.aborter) {
            this.aborter.abort();
        }
        else {
            throw new Error("Can't abort, missing aborter");
        }
    }
}
