var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
import { GoogleMap } from 'cerum-maps/GoogleMap';
import { BoostWebsiteHeader } from '../components/BoostWebsiteHeader';
import { BoostWebsiteImageLocation } from '../../website-images/BoostWebsiteImageLocation';
import { BoostWebsiteImageWeb } from '../../website-images/BoostWebsiteImageWeb';
import { BoostWebsiteInformation } from '../components/BoostWebsiteInformation';
import { BoostWebsiteFooterWithMap } from '../components/BoostWebsiteFooterWithMap';
import { BoostImageQuotationMarks } from '../../website-images/BoostWebsiteImageQuotationMarks';
import { Router } from '@vaadin/router';
let BoostWebsiteFrontPage = class BoostWebsiteFrontPage extends LitElement {
    static get is() {
        return 'boost-website-front-page';
    }
    static get styles() {
        return css `
            body {
                overflow: hidden;
            }

            h1 {
                font-weight: normal;
            }

            .background-img-container {
                height: 39rem;

                width: 100%;
                background: linear-gradient(
                        90deg,
                        rgba(32, 67, 115, 0.5942576859845501) 0%,
                        rgba(32, 67, 115, 0.5662464815027574) 39%,
                        rgba(32, 67, 115, 0) 100%
                    ),
                    url('images/new-website/background-front-2.jpg');
                background-size: cover;
                margin-top: 8rem;
                background-repeat: no-repeat;
            }

            .header-h1 {
                color: #ffffff;
                transform: translate(0, 10rem);
                width: 40%;
                padding-left: 6rem;
                line-height: 1.4;
                font-size: 55px;
                font-weight: normal;
            }

            .header-span {
                font-weight: bold;
            }

            .box-container {
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(23rem, 1fr));
                max-width: 86rem;
                justify-content: center;
                margin: 0 auto;
                gap: 2rem 2rem;
                justify-items: center;
            }

            .box {
                background-color: #204373;
                border-radius: 50px;
                text-align: center;
                display: flex;
                width: 100%;
                max-width: 26rem;
                align-items: center;
                flex-direction: column-reverse;
                justify-content: start;
                box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
            }

            .box-header-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                gap: 10px;
                margin: 20px;
            }

            .boost-logo-thumb {
                height: 46px;
            }

            .box-header {
                color: #ffff;
                font-size: 36px;
                font-weight: normal;
            }

            .box-text-p {
                color: #ffffff;
                width: 90%;
                font-size: 16px;
                font-weight: normal;
            }

            .box-text-h1 {
                color: #fff;
                padding-left: 20px;
                font-size: 20px;
            }

            .box-text-img {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            .icons {
                width: 100%;
                height: 12rem;
                border-radius: 3rem 3rem 0rem 0rem;
                object-fit: cover;
            }

            .read-more-btn {
                height: 4rem;
                width: 20rem;
                background-color: #2dfe53;
                border-radius: 50px;
                border: none;
                font-size: 16px;
                cursor: pointer;
                color: #132c4f;
            }

            .btn-container {
                display: flex;
                justify-content: center;
                width: 100%;
                margin-top: 2rem;
                padding-bottom: 2rem;
            }

            .customer-slide-container {
                background-color: #204373;
                width: 100%;
                padding: 2rem 0rem 4rem 0rem;
            }

            .container-width {
                display: flex;
                justify-content: center;
                gap: 2rem;
                max-width: 85rem;
                margin: 0 auto;
            }

            .customer-slide {
                display: flex;
                flex-direction: column;
                padding-top: 40px;
            }

            .customer-img-text {
                background-color: #ffff;
                display: flex;
                flex: 1;
                flex-direction: column;
                border-radius: 20px;
                padding: 0.5rem;
            }

            .center-name-text {
                color: #132c4f;
                background-color: #2dfe53;
                padding: 14px;
                border-radius: 50px;
                width: 16rem;
            }

            .customer-img {
                height: 4rem;
                width: 4rem;
                border-radius: 50%;
                object-fit: cover;
            }

            .customer-text {
                width: 94%;
                color: #132c4f;
                font-size: 16px;
                flex: 1;
                padding: 1rem;
            }

            .ceo-center {
                font-weight: bold;
                color: #132c4f;
                font-size: 16px;
            }

            .stas-logo {
                object-fit: contain;
            }

            .screenshot {
                height: 6rem;
                width: 3rem;
                border-radius: 0%;
            }

            .vipps-logo {
                width: 100%;
                height: 12rem;
                background-color: white;
                border-radius: 3rem 3rem 0rem 0rem;
                object-fit: contain;
            }

            .center-logo-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 10px;
                font-size: 26px;
                font-weight: lighter;
            }

            .box-text-container {
                width: 100%;
                height: 11rem;
                text-align: center;
            }

            .img-text {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 2rem;
                padding: 1rem;
            }

            .app-container {
                display: flex;
                margin: 0 auto;
                max-width: 86rem;
            }

            @keyframes fadeVisibility {
                0% {
                    opacity: 0;
                }
                100% {
                    opacity: 1;
                }
            }

            @media screen and (max-width: 1400px) {
                .header-h1 {
                    width: 50%;
                }

                .vipps-logo {
                    margin-right: 0rem;
                }

                .box-container {
                    max-width: 73rem;
                }

                .container-width {
                    max-width: 73rem;
                }
            }

            @media screen and (max-width: 1200px) {
                .background-img-container {
                    background-repeat: no-repeat;
                    background-position: top;
                    height: 20rem;
                }

                .header-h1 {
                    width: 98%;
                    padding-left: 10px;
                    transform: translate(0px, 4rem);
                    font-size: 34px;
                }
                .box-header-container {
                    justify-content: center;
                }

                .box-header {
                    font-size: 26px;
                }

                .box-container {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    margin: 0px;
                    gap: 2rem;
                    align-items: center;
                }
                .box {
                    width: 98%;
                }

                .box-text-p {
                    font-size: 16px;
                    width: 78%;
                }

                .box-text-h1 {
                    font-size: 20px;
                }

                .box-text-img {
                    justify-content: space-between;
                }

                .screenshot {
                    height: 70px;
                    width: 36px;
                    margin-right: 3rem;
                }

                .customer-slide-container {
                    flex-direction: column;
                    width: 100%;
                    gap: 1rem;
                }

                .customer-slide {
                    width: 100%;
                }

                .customer-img-text {
                    flex-direction: column;
                    height: auto;
                    width: 96%;
                    align-self: center;
                }

                .customer-img {
                    margin-top: 1rem;
                    align-self: start;
                }

                .center-logo-container {
                    padding-left: 1rem;
                }

                .container-width {
                    flex-direction: column;
                }
            }
        `;
    }
    /** @override */ render() {
        return html `
            <boost-website-header></boost-website-header>
            <div class="background-img-container">
                <h1 class="header-h1">
                    Komplett løsning for ditt treningssenter, med
                    <span class="header-span">topp kundeservice</span> og
                    <span class="header-span">fornøyde</span> medlemmer!
                </h1>
            </div>
            <div class="background-img">
                <div class="box-header-container">
                    <img class="boost-logo-thumb" src="images/new-website/boostlogothumb.png" />
                    <h1 class="box-header">Litt om våre løsninger</h1>
                </div>

                <div class="box-container">
                    <div class="box">
                        <p class="box-text-p">
                            En litt frekk påstand, men vi liker å tro det.
                            <br />
                            Og kundene våre er enig!
                        </p>
                        <h1 class="box-text-h1">Den beste kundeservicen i bransjen!</h1>
                        <img class="icons" src="images/new-website/happypeople.jpg" />
                    </div>

                    <div class="box" @click="${() => Router.go('/app')}">
                        <p class="box-text-p">
                            Ingen adgangskort å huske på. Ingen adresser og pålogg på web. Alt er integrert i
                            samme app.
                        </p>
                        <h1 class="box-text-h1">App for booking og adgang</h1>
                        <img class="icons" src="images/new-website/girlonphone.jpg" />
                    </div>

                    <div class="box">
                        <p class="box-text-p">
                            Vårt system kan integreres mot de fleste elektroniske<br />
                            dørlåser og inngangsporter.
                        </p>
                        <h1 class="box-text-h1">Adgangskontroll</h1>
                        <img class="icons" src="images/new-website/lock.jpg" />
                    </div>
                    <div class="box">
                        <p class="box-text-p">
                            Vipps gjør betaling enkelt. Alle kjøp utenom det månedlige trekket kan
                            gjennomføres med Vipps.
                        </p>
                        <h1 class="box-text-h1">Integrasjon mot Vipps</h1>
                        <img class="vipps-logo" src="images/new-website/vipps_logo_rgb.png" />
                    </div>

                    <div class="box">
                        <p class="box-text-p">
                            Dette kan vi!
                            <br />

                            Med vår løsning “Full drift” vil du slippe å tenke på månedstrekk,
                            betalingsoppfølging og innbetalinger.
                        </p>
                        <h1 class="box-text-h1">Kjedelig med rutiner?</h1>
                        <img class="icons" src="images/new-website/folder.jpg" />
                    </div>

                    <div class="box">
                        <p class="box-text-p">
                            Integrer systemet med ditt regnskapssystem eller velg blant våre gode rapporter
                            til regnskap.
                        </p>
                        <h1 class="box-text-h1">Regnskap</h1>
                        <img class="icons" src="images/new-website/paperstack.jpg" />
                    </div>
                </div>

                <div class="btn-container">
                    <button class="read-more-btn" @click="${() => Router.go('/functions')}">
                        Nysgjerrig? Les mer!
                    </button>
                </div>
            </div>
            <div class="customer-slide-container">
                <div class="container-width">
                    <div class="customer-slide">
                        <boost-website-image-quotation-marks></boost-website-image-quotation-marks>
                        <div class="customer-img-text">
                            <i class="customer-text">
                                "Som daglig leder for tre senter så har jeg en hektisk hverdag. Jeg har satt
                                bort alt jeg kan av rutiner til Boost slik at jeg kan konsentrere meg om å
                                gjøre mine senter bedre og få inn nye medlemmer. Hvis jeg trenger råd eller
                                tips får jeg alltid rask og effektiv hjelp hos kundeservice!""
                            </i>
                            <div class="img-text">
                                <img src="images/new-website/tomharryson.png" class="customer-img" />
                                <span class="ceo-center"
                                    >Tom Harrysson - Daglig leder Lilleby, Levanger og Stjørdal</span
                                >
                            </div>
                        </div>
                    </div>
                    <div class="customer-slide">
                        <boost-website-image-quotation-marks></boost-website-image-quotation-marks>
                        <div class="customer-img-text">
                            <i class="customer-text">
                                "Så langt er vi veldig tilfreds med systemet, vi får god oversikt og de gode
                                rapportene hjelper oss å holde oversikt over kunder, hvilke type avtale folk
                                har og ubetalte fakturaer. Vi får fine besøksrapporter og økonomirapporter og
                                vi ser ikke at systemet har noen mangler.<br /><br />
                                Kjøp av dropin via Vipps er vi veldig godt fornøyd med. Fungerer spesielt godt
                                for studenter som er hjemme på ferie og alle som er på helgebesøk."
                            </i>
                            <div class="img-text">
                                <img src="images/new-website/jorundbindalseide.jpeg" class="customer-img" />
                                <span class="ceo-center"
                                    >John Henning Vikestad - Bindalseidet Treningssenter</span
                                >
                            </div>
                        </div>
                    </div>
                    <div class="customer-slide">
                        <boost-website-image-quotation-marks></boost-website-image-quotation-marks>
                        <div class="customer-img-text">
                            <i class="customer-text">
                                "Boost System har gjort hverdagen vår som drivere av treningssenter enklere.
                                Vi bruker mindre tid på administrasjon enn vi så for oss, og support er alltid
                                positiv til våre henvendelser. Ting blir løst på rekordtid og med et smil og
                                vi føler oss godt ivaretatt!
                                <br /><br />
                                Med Boost system er vi fleksible og kan hjelpe kunder som står fast selv om vi
                                er på reise."</i
                            >
                            <div class="img-text">
                                <img
                                    src="images/new-website/stasknallformlogo.png"
                                    style="object-fit:contain;"
                                    class="customer-img"
                                />
                                <span class="ceo-center"
                                    >Sissel Johnsen og Per Emil Søreng - Stas Knallform</span
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <boost-website-footer-with-map></boost-website-footer-with-map>
        `;
    }
};
BoostWebsiteFrontPage._deps = [
    BoostWebsiteHeader,
    BoostWebsiteFooterWithMap,
    BoostWebsiteInformation,
    BoostImageQuotationMarks,
    BoostWebsiteImageLocation,
    BoostWebsiteImageWeb,
    GoogleMap,
];
BoostWebsiteFrontPage = __decorate([
    customElement(BoostWebsiteFrontPage.is)
], BoostWebsiteFrontPage);
export { BoostWebsiteFrontPage };
