var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
let BoostWebsitePrivacyPolicy = class BoostWebsitePrivacyPolicy extends LitElement {
    static get is() {
        return 'boost-website-privacy-policy';
    }
    static get styles() {
        return css ``;
    }
    /** @override */ render() {
        return html `<object
            data=${'https://cerum.no/api/rest/document/public/1588a3ed1555313?action=read&name=Personvernerkl%25E6ring+Skysoft_signert+%25282%2529.pdf#toolbar=0'}
            width="100%"
            height="1080px"
        ></object>`;
    }
};
BoostWebsitePrivacyPolicy._deps = [];
BoostWebsitePrivacyPolicy = __decorate([
    customElement(BoostWebsitePrivacyPolicy.is)
], BoostWebsitePrivacyPolicy);
export { BoostWebsitePrivacyPolicy };
