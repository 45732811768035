var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Ajax } from 'badphraim/ajax/Ajax';
import { Dom } from 'badphraim/dom/Dom';
import { SingletonInstanceLike } from 'badphraim/core/Singleton';
export class StandaloneAjax extends Ajax {
    static scopeIntersect(a, b) {
        return a.filter((scope) => b.indexOf(scope) >= 0);
    }
    static getInterceptorForAnyScope(scopes) {
        const interceptors = this.scopeInterceptors
            .filter((si) => this.scopeIntersect(scopes, si.scopes).length > 0)
            .map((i) => i.interceptor);
        return interceptors.shift() || null;
    }
    static instanceForAnyScope(scopes) {
        if (!scopes || !scopes.length) {
            return Ajax.stdInstance();
        }
        if (this.getInterceptorForAnyScope(scopes)) {
            return SingletonInstanceLike(StandaloneAjax, () => {
                return new StandaloneAjax(new Dom(document));
            });
        }
        throw new Error('No available handler for any requested scope: ' + scopes.join(', '));
    }
    static registerScopeInterceptor(scopes, interceptor) {
        this.scopeInterceptors.push({
            scopes,
            interceptor,
        });
    }
    /** @override */ _sendRequest(x, url, data, l_handle, scopes) {
        const _super = Object.create(null, {
            _sendRequest: { get: () => super._sendRequest }
        });
        return __awaiter(this, void 0, void 0, function* () {
            if (scopes && scopes.length) {
                const interceptor = StandaloneAjax.getInterceptorForAnyScope(scopes);
                if (interceptor) {
                    const bearer_token = yield interceptor(url, x, scopes);
                    if (bearer_token && bearer_token.length) {
                        x.setRequestHeader('Authorization', 'Bearer ' + bearer_token);
                    }
                }
            }
            return _super._sendRequest.call(this, x, url, data, l_handle, scopes);
        });
    }
}
StandaloneAjax.scopeInterceptors = [];
