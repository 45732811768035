var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export class Utils {
    static in_array(needle, haystack) {
        for (let i = 0; i < haystack.length; i++) {
            if (typeof haystack[i] !== 'undefined' && haystack[i] === needle) {
                return true;
            }
        }
        return false;
    }
    static max(a, b) {
        return a > b ? a : b;
    }
    static lazyMap(arr, cb) {
        return __awaiter(this, void 0, void 0, function* () {
            if (arr.length == 0) {
                return [];
            }
            const res = [];
            const first = arr.shift();
            const s = performance.now();
            const fres = cb(first);
            const elapsed = performance.now() - s;
            res.push(fres);
            if (arr.length == 0) {
                return res;
            }
            const chunk_size = Math.round(100 / elapsed);
            const process_chunk = () => {
                let i = chunk_size;
                let progress = false;
                while (i-- > 0 && arr.length) {
                    res.push(cb(arr.shift()));
                    progress = true;
                }
                return progress;
            };
            return new Promise((resolve, reject) => {
                const schedule_process_chunk = () => {
                    window.requestAnimationFrame(() => {
                        try {
                            if (process_chunk()) {
                                schedule_process_chunk();
                            }
                            else {
                                resolve(res);
                            }
                        }
                        catch (e) {
                            reject(e);
                        }
                    });
                };
                try {
                    schedule_process_chunk();
                }
                catch (e) {
                    reject(e);
                }
            });
        });
    }
}
