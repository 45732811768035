export class GeneratorHelpers {
    static instance() {
        throw new Error('Not implemented');
    }
}
export class ArrayOfClass {
    /** @override */ arrayOf() {
        return GeneratorHelpers.instance().wrapAsArray(this);
    }
    /** @override */ hashMapOf() {
        return GeneratorHelpers.instance().wrapAsHashMap(this);
    }
}
export class HashMapOfClass {
    /** @override */ arrayOf() {
        return GeneratorHelpers.instance().wrapAsArray(this);
    }
    /** @override */ hashMapOf() {
        return GeneratorHelpers.instance().wrapAsHashMap(this);
    }
}
class GeneratorHelperImpl extends GeneratorHelpers {
    /** @override */ wrapAsArray(self) {
        return new (class extends ArrayOfClass {
            /** @override */ generate(data) {
                const arr = [];
                for (let i = 0; i < data.length; i++) {
                    if (data.hasOwnProperty(i)) {
                        arr[i] = self.generate(data[i]);
                    }
                }
                return arr;
            }
        })();
    }
    /** @override */ wrapAsHashMap(self) {
        return new (class extends HashMapOfClass {
            /** @override */ generate(data) {
                const map = {};
                for (const i in data) {
                    if (data.hasOwnProperty(i)) {
                        map[i] = self.generate(data[i]);
                    }
                }
                return map;
            }
        })();
    }
}
const impl = new GeneratorHelperImpl();
GeneratorHelpers.instance = () => {
    return impl;
};
