var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/**
 * @description
 * Simple variable container, which allows asynchronious appearance of any
 * variable content. You can wrap anything in this class, and then use async/await
 * or Promise<T>-based access of the variables contents.
 * A single AsyncContainer instance can only receive a value once (to conform to the Promise API),
 *
 * @author Bjørn Tore Hagen, Cerum AS
 */
export class AsyncContainer {
    constructor() {
        this._waitQueue = [];
    }
    getValue() {
        if (this.valuePromise) {
            return this.valuePromise;
        }
        return new Promise((resolve, reject) => {
            if (this._value !== undefined) {
                resolve(this._value);
                return;
            }
            if (this._rejectReason !== undefined) {
                reject(this._rejectReason);
                return;
            }
            this._waitQueue.push([resolve, reject]);
        });
    }
    getValueIfReady() {
        return this._value;
    }
    hasValue() {
        return this._value !== undefined;
    }
    reject(reason) {
        if (this._rejectReason !== undefined) {
            throw new Error('Already rejected');
        }
        this._rejectReason = reason;
        while (this._waitQueue.length) {
            const entry = this._waitQueue.shift();
            if (entry) {
                entry[1](reason);
            }
        }
    }
    setValue(value) {
        if (this._rejectReason !== undefined) {
            throw new Error("Value can't be assigned, if already rejected");
        }
        if (this._value !== undefined) {
            throw new Error("Value can't be set multiple times");
        }
        if (this.valuePromise) {
            throw new Error("Value can't be set multiple times");
        }
        this._value = value;
        while (this._waitQueue.length) {
            const entry = this._waitQueue.shift();
            if (entry) {
                entry[0](value);
            }
        }
    }
    /**
     * Helper-function to allow the value to be set by a Promise. Any calls
     * to setValue while this promise is pending will fail as if the value
     * already was set, while any calls to hasValue will still return false
     */
    asyncSetValue(valuePromise) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this._rejectReason !== undefined) {
                throw new Error("Value can't be assigned, if already rejected");
            }
            if (this._value !== undefined) {
                throw new Error("Value can't be set multiple times");
            }
            if (this.valuePromise) {
                throw new Error("Value can't be set multiple times");
            }
            this.valuePromise = valuePromise;
            const value = yield valuePromise;
            this.valuePromise = undefined;
            this.setValue(value);
            return value;
        });
    }
}
