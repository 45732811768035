export class UserAgent {
    constructor() {
        this.isAIR = 0.0;
        this.isBrowser = 0.0;
        this.isChrome = 0.0;
        this.isFF = 0.0;
        this.isIE = 0.0;
        this.isKhtml = 0.0;
        this.isMozilla = 0.0;
        this.isOpera = 0.0;
        this.isQuirks = 0.0;
        this.isSafari = 0.0;
        this.isWebKit = 0.0;
        const b = (this.browser = new Browser());
        this.isIE = b.getIE();
        this.isOpera = b.getOpera();
        this.isMozilla = b.getMozilla();
    }
}
/**
 * Gammel browser-greie som ikker no stas.
 * @obsolete
 */
export class Browser {
    constructor() {
        this.ie = 0.0;
        this.opera = 0.0;
        this.mozilla = 0.0;
        this.khtml = 0.0;
        this.parsed = false;
    }
    getIE() {
        this.parse();
        return this.ie;
    }
    getKHTML() {
        this.parse();
        return this.khtml;
    }
    getMozilla() {
        this.parse();
        return this.mozilla;
    }
    getOpera() {
        this.parse();
        return this.opera;
    }
    parse() {
        if (this.parsed) {
            return;
        }
        let iev;
        let ov;
        let mv;
        let kv;
        const ua = navigator.userAgent;
        if ((iev = ua.match(/Trident\/(\d+\.\d+)/))) {
            this.ie = parseFloat(iev[1]);
        }
        else if ((iev = ua.match(/MSIE (\d+\.\d+)/)) && !ua.match(/Opera/)) {
            this.ie = parseFloat(iev[1]);
        }
        else if ((ov = ua.match(/Opera (\d+\.\d+)/))) {
            this.opera = parseFloat(ov[1]);
        }
        else if ((ov = ua.match(/Opera\/(\d+\.\d+)/))) {
            this.opera = parseFloat(ov[1]);
        }
        else if (ua.match(/KHTML/) && (kv = ua.match(/KHTML\/(\d+\.\d+)/))) {
            this.khtml = parseFloat(kv[1]);
        }
        else if (ua.match(/Gecko/i) && (mv = ua.match(/Mozilla\/(\d+\.\d+)/))) {
            this.mozilla = parseFloat(mv[1]);
        }
        this.parsed = true;
    }
}
