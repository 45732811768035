/* eslint "@typescript-eslint/no-unsafe-argument": "warn" */
import 'reflect-metadata';
import { ArrayOfClass, HashMapOfClass } from './GeneratorHelpers';
export function property(_target, _propertyKey) {
    /*var PROPERTIES_KEY = "design.type";
      let columns: string[] = Reflect.getMetadata(PROPERTIES_KEY, target.constructor) || [];
      columns.push(propertyKey);
      Reflect.defineMetadata(PROPERTIES_KEY, columns, target.constructor);
      */
    /*return function(){
     }*/
}
export function array_property(type) {
    return (target, propertyKey) => {
        Reflect.defineMetadata('design:array_type', type, target, propertyKey);
    };
    //    return property;
}
export function tuple_property(...types) {
    return (target, propertyKey) => {
        Reflect.defineMetadata('design:tuple_length', types.length, target, propertyKey);
        for (let i = 0; i < types.length; i++) {
            Reflect.defineMetadata('design:tuple_type_' + i, types[i], target, propertyKey);
        }
    };
}
export function nullable(target, propertyKey) {
    //    return function(target: object, propertyKey: string) {
    Reflect.defineMetadata('design:type_is_nullable', true, target, propertyKey);
    //
}
export function nullable_array_property(type) {
    return (target, propertyKey) => {
        Reflect.defineMetadata('design:array_type', type, target, propertyKey);
        nullable(target, propertyKey);
    };
    //    return property;
}
export class JSONTypeError extends Error {
}
export class JSONStruct {
    // Then, function implementation
    static _castValueFromJSON(cls, instance, prop_name, in_value, type, strict = false) {
        const me = `(${cls.name} extends JSONStruct)`;
        if (in_value === null) {
            return null;
        }
        if (in_value === undefined) {
            return undefined;
        }
        if (type === String) {
            return String(in_value);
        }
        else if (type === Number) {
            return Number(in_value);
        }
        else if (type.prototype instanceof JSONStruct || type === JSONStruct) {
            const sub_instance = this.fromJSONObjWithClass(type, in_value, strict);
            return sub_instance;
        }
        else if (type === Object) {
            return in_value;
        }
        else if (type === Boolean) {
            return Boolean(in_value);
        }
        else if (type === Array) {
            if (in_value instanceof Array) {
                const inner_type = Reflect.getMetadata('design:array_type', instance, prop_name);
                if (inner_type) {
                    const ret = [];
                    for (const i in in_value) {
                        const entry = in_value[i];
                        const casted = this._castValueFromJSON(cls, instance, prop_name, entry, inner_type, strict);
                        if (casted !== undefined) {
                            ret[i] = casted;
                        }
                        else {
                            console.error(me +
                                '._castValueFromJSON(..): ' +
                                prop_name +
                                ': Failed casting array instance ' +
                                i +
                                ' to ' +
                                inner_type, [entry, inner_type]);
                        }
                    }
                    return ret;
                }
                const tuple_length = Reflect.getMetadata('design:tuple_length', instance, prop_name);
                if (tuple_length && typeof tuple_length === 'number') {
                    if (in_value.length !== tuple_length) {
                        console.error(me +
                            '._castValueFromJSON(..): ' +
                            prop_name +
                            ': Fikk inn et array som har annen lengde enn forventet tuple.');
                    }
                    const tuple = [];
                    for (let i = 0; i < Math.min(tuple_length, in_value.length); i++) {
                        const tuple_type = Reflect.getMetadata('design:tuple_type_' + i, instance, prop_name);
                        const entry = in_value[i];
                        const casted = this._castValueFromJSON(cls, instance, prop_name, entry, tuple_type, strict);
                        if (casted !== undefined) {
                            tuple[i] = casted;
                        }
                        else {
                            console.error(me +
                                '._castValueFromJSON(..): ' +
                                prop_name +
                                ': Failed casting tuple entry ' +
                                i +
                                ' to ' +
                                tuple_type, [entry, tuple_type]);
                        }
                    }
                    return tuple;
                }
                console.error(me +
                    '._castValueFromJSON(..): ' +
                    prop_name +
                    ': Fikk ikke typecasted arrayet, men lar det passere');
                return in_value;
            }
            else {
                const errorMsg = me +
                    '._castValueFromJSON(..): ' +
                    prop_name +
                    ': Fikk ikke array som forventet for ' +
                    instance.constructor.name +
                    '.' +
                    prop_name +
                    ': ' +
                    type;
                if (strict) {
                    throw new JSONTypeError(errorMsg);
                }
                console.error(errorMsg, in_value);
            }
        }
        else {
            const errorMsg = 'JSONStruct vet ikke hva vi skal for ' +
                instance.constructor.name +
                '.' +
                prop_name +
                ': ' +
                type;
            if (strict) {
                throw new JSONTypeError(errorMsg);
            }
            console.error(errorMsg);
        }
    }
    static classOf(clst, inst) {
        return new ClassWrapperClass(clst, inst);
    }
    static constructor_wrapper(in_t) {
        return in_t.constructor;
    }
    static fromJSONObj(obj, strict = false) {
        return this.fromJSONObjWithClass(this, obj, strict);
    }
    static fromJSONObjWithClass(cls, obj, strict = false) {
        const instance = new cls(); // Object.create(cls);
        return this.fromJSONObjWithClassAndInstance(cls, instance, obj, strict);
    }
    static fromJSONObjWithClassAndInstance(_cls, instance, obj, strict = false) {
        /*let cls: typeof JSONStruct;
            cls = (_cls as typeof JSONStruct);*/
        const cls = _cls;
        for (const prop_name in obj) {
            //if (obj instanceof JSONStruct && prop_name == 'init' &&
            if (typeof obj[prop_name] === 'function') {
                // Hopp over funksjona
                continue;
            }
            const type = Reflect.getMetadata('design:type', instance, prop_name);
            if (!instance.hasOwnProperty(prop_name) && !type) {
                if (!strict) {
                    instance[prop_name] = obj[prop_name];
                }
                continue;
            }
            try {
                const value = this._castValueFromJSON(cls, instance, prop_name, obj[prop_name], type, strict);
                if (value === null || value === undefined) {
                    const is_nullable = Reflect.getMetadata('design:type_is_nullable', instance, prop_name);
                    if (!is_nullable) {
                        if (!strict) {
                            /* void */
                        }
                        console.error('ERROR: Type is not nullable');
                        console.error('instance', instance);
                        console.error('type', type);
                        console.error('obj[' + prop_name + ']', obj[prop_name]);
                        console.error('value', value);
                        throw new JSONTypeError('Type is not nullable ' +
                            instance.constructor.name +
                            '.' +
                            prop_name +
                            ': [type=' +
                            type +
                            '] in:' +
                            obj[prop_name] +
                            ' => out:' +
                            value);
                    }
                }
                instance[prop_name] = value;
            }
            catch (e) {
                if (strict) {
                    throw e;
                }
                else {
                    console.error('Exception. Kanskje mangler det @property på ' + prop_name, e);
                }
            }
            /*                instance[prop_name] = obj[prop_name];
                  } else {*/
        }
        return instance;
    }
    static fromJSONString(raw, strict = false) {
        const obj = JSON.parse(raw);
        return this.fromJSONObjWithClass(this, obj, strict);
    }
    static vectorFromJSONObjVec(obj_vec, strict = false) {
        const ret = [];
        for (const i in obj_vec) {
            const obj = obj_vec[i];
            ret.push(this.fromJSONObj(obj, strict));
        }
        return ret;
    }
    static vectorFromJSONObjVecWithClass(cls, obj_vec, strict = false) {
        const ret = [];
        for (const i in obj_vec) {
            const obj = obj_vec[i];
            ret.push(cls.fromJSONObjWithClass(cls, obj, strict));
        }
        return ret;
    }
    static vectorFromJSONObjVecWithClassAndInstance(_cls, instance, obj_vec, strict = false) {
        const ret = [];
        for (const i in obj_vec) {
            const obj = obj_vec[i];
            const inst = instance.newEmptyInstance();
            ret.push(this.fromJSONObjWithClassAndInstance(_cls, inst, obj, strict));
        }
        return ret;
    }
    static vectorFromJSONString(raw, strict = false) {
        const obj = JSON.parse(raw);
        if (obj instanceof Array) {
            const ret = [];
            for (const e of obj) {
                // const e = obj[i];
                ret.push(this.fromJSONObj(e, strict));
            }
            return ret;
        }
        throw new JSONTypeError('Not an array');
    }
    constructor() {
        /** void */
    }
    boundInit(strict = false) {
        return (props) => {
            return this.init(props, strict);
        };
    }
    init(props, strict = false) {
        //        var cls = this;
        const instance = this; // new cls();
        const cls = instance.constructor;
        const me = this.constructor.name;
        //        var x = JSONStruct._constructor_wrapper(this);
        if (props instanceof Promise) {
            throw new JSONTypeError('(' + me + ' extends JSONStruct).init(..) fikk en Promise. Du mangler nok en await.');
        }
        else if (props instanceof Object) {
            JSONStruct.fromJSONObjWithClassAndInstance(cls, instance, props, strict);
        }
        else if (typeof props === 'string') {
            const obj = JSON.parse(props);
            if (obj) {
                JSONStruct.fromJSONObjWithClassAndInstance(cls, instance, obj, strict);
            }
            else {
                console.error('(' + me + ' extends JSONStruct).init: Fikk en string som ikke parser som json: ', props);
                if (strict) {
                    throw new JSONTypeError('(' + me + ' extends JSONStruct).init: Fikk en string som ikke parser som json ');
                }
            }
        }
        else if (props !== undefined && props !== null) {
            console.error('(' + me + ' extends JSONStruct).init Fant noe jeg ikke vet hva jeg skal gjøre ned', props);
            if (strict) {
                throw new JSONTypeError('(' + me + ' extends JSONStruct).init: Fikk en string som ikke parser som json ');
            }
        }
        return instance;
    }
    newEmptyInstance() {
        return new this.constructor();
    }
}
export class ClassWrapperClass {
    constructor(clst, inst) {
        this.clst = clst;
        this.inst = inst;
        // void
    }
    /** @override */ arrayOf() {
        const instance = this.inst; // new cls();
        const cls = this.clst;
        return new (class extends ArrayOfClass {
            /** @override */ generate(data) {
                return JSONStruct.vectorFromJSONObjVecWithClassAndInstance(cls, instance, data);
            }
        })();
    }
    /** @override */ generate(data) {
        const inst = new this.clst();
        inst.init(data);
        return inst;
    }
    /** @override */ hashMapOf() {
        const self = this;
        return new (class extends HashMapOfClass {
            /** @override */ generate(data) {
                const map = {};
                for (const i in data) {
                    if (data.hasOwnProperty(i)) {
                        map[i] = self.generate(data[i]);
                    }
                }
                return map;
            }
        })();
    }
}
