var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { BoostImageProfileIcon } from '../../website-images/BoostImageProfileIcon';
import { BoostWebsiteMailIcon } from '../../website-images/BoostWebsiteMailIcon';
import { BoostWebsitePhoneIcon } from '../../website-images/BoostWebsitePhoneIcon';
import { BoostImageAboutUs } from '../../website-images/BoostImageAboutUs';
import { BoostWebsiteFooter } from '../components/BoostWebsiteFooter';
import { BoostWebsiteHeader } from '../components/BoostWebsiteHeader';
let BoostWebsitePartners = class BoostWebsitePartners extends LitElement {
    constructor() {
        super(...arguments);
        this.smallScreen = false;
    }
    static get is() {
        return 'boost-website-partners';
    }
    static get styles() {
        return css `
            .background-img-container {
                height: 39rem;
                object-fit: cover;
                background: linear-gradient(
                        90deg,
                        rgba(32, 67, 115, 0.329551889115021) 0%,
                        rgba(32, 67, 115, 0.4556023092830882) 4%,
                        rgba(32, 67, 115, 0) 100%
                    ),
                    url('images/new-website/handshake.png');
                background-position: center;
                background-size: cover;
                margin-top: 8rem;
            }

            .background-img {
                width: 100%;
                height: 50rem;
            }

            .header-h1 {
                color: #ffffff;
                transform: translate(0px, 10rem);
                width: 50%;
                padding-left: 6rem;
                line-height: 1.4;
                font-size: 50px;
                font-weight: normal;
            }

            .header-text {
                font-size: 36px;
                color: #ffffff;
                width: 88%;
                font-weight: lighter;
            }
            .main-container {
                display: flex;
                flex-direction: row;
                width: 100%;
                padding-bottom: 4rem;
                justify-content: center;
                gap: 4rem;
                max-width: 86rem;
                margin: 0 auto;
            }

            .h1-img-container {
                display: flex;
                flex-direction: row;
            }

            .partner-logo {
                width: 6rem;
                height: 2rem;
                align-self: center;
            }

            .partner-text {
                padding-left: 2rem;
                margin: 0;
                width: 30rem;
            }

            .logo-container {
                width: 10rem;
                min-width: 10rem;
                height: 6rem;
                background-color: white;
                border-radius: 29px;
                display: flex;
                justify-content: center;
                cursor: pointer;
            }

            .sub-container {
                max-width: 45%;
                width: 50rem;
                display: flex;
                flex-direction: column;
                margin-top: 4rem;
            }

            .iizy {
                height: 5rem;
                width: 5rem;
            }

            .ng-logo {
                width: 5rem;
                height: 5rem;
                align-self: center;
            }

            .ag-logo {
                width: 10rem;
                height: 4rem;
            }

            @media screen and (max-width: 1500px) {
                .header-h1 {
                    width: 70%;
                }
            }

            @media screen and (max-width: 1000px) {
                .background-img-container {
                    background-repeat: no-repeat;
                    margin-top: 7rem;
                    background-size: cover;
                    height: 20rem;
                    width: 100%;
                    background-position: revert;
                }
                .header-h1 {
                    width: 98%;

                    transform: translate(-4rem, 2rem);
                    font-size: 36px;
                }

                .header-text {
                    font-size: 20px;
                }

                .main-container {
                    flex-direction: column;
                    padding-bottom: 0rem;
                    gap: 0rem;
                }

                .sub-container {
                    max-width: 100%;
                    width: 100%;
                    flex-direction: column;
                    margin-top: 0rem;
                }

                .h1-img-container {
                    flex-direction: column;
                }

                .h1-text {
                    font-size: 26px;
                    display: none;
                }

                .logo-container {
                    width: 9rem;
                    height: 3rem;
                    border-radius: 10px;
                    margin-bottom: 2rem;
                    margin-left: 1rem;
                }

                .partner-logo {
                    width: 5rem;
                    height: 2rem;
                }

                .partner-text {
                    width: 96%;
                    padding-left: 10px;
                    padding-top: 0rem;
                }

                .iizy {
                    height: 2rem;
                    width: 2rem;
                    align-self: center;
                }

                .ng-logo {
                    height: 2rem;
                    width: 2rem;
                    align-self: center;
                }

                .ag-logo {
                    width: 8rem;
                    height: 3rem;
                }

                .dark-blue {
                    background-color: #132c4f;
                    padding-bottom: 2rem;
                    padding-top: 2rem;
                }

                .light-blue {
                    background-color: #204373;
                    padding-bottom: 2rem;
                    padding-top: 2rem;
                }
            }
        `;
    }
    /** @override */ render() {
        return html `
            <boost-website-header
                @screen-size-changed="${(event) => (this.smallScreen = event.detail.value)}"
            ></boost-website-header>
            <div class="background-img-container">
                <h1 class="header-h1">
                    Våre partnere og integrasjoner
                    <p class="header-text">
                        Vi har knyttet til oss noen gode partnere slik at de kan gjøre det de kan best, og vi
                        kan fokusere 100% på det vi kan best!
                    </p>
                </h1>
            </div>
            <div class="main-container">
                <div class="sub-container dark-blue">
                    <h1 class="h1-text">Collectio</h1>

                    <div class="h1-img-container">
                        <div
                            class="logo-container"
                            @click="${() => window.open('https://www.collectio.no/treningssenter')}"
                        >
                            <img class="partner-logo" src="/images/collectio.png" />
                        </div>
                        <p class="partner-text">
                            Collectio er et voksende selskap med lang erfaring fra betalingsoppfølging i
                            treningssenterbransjen. De vet hvor viktig det er at dine medlemmer får god
                            oppfølging slik at de blir værende lenge. Les mer om Collectio her:
                            <span
                                @click="${() => window.open('https://www.collectio.no/treningssenter')}"
                                style="color:white; cursor:pointer; text-decoration:underline;"
                            >
                                collectio.no</span
                            >
                        </p>
                    </div>
                </div>
                <div class="sub-container light-blue">
                    <h1 class="h1-text">CrediCare</h1>

                    <div class="h1-img-container">
                        <div
                            class="logo-container"
                            @click="${() => window.open('https://www.credicare.no/bedrift')}"
                        >
                            <img
                                style="width:8rem; height:4rem; align-self:center; object-fit: contain;"
                                src="/images/new-website/credicare-logo.jpg"
                            />
                        </div>
                        <p class="partner-text" style=" align-self:start; ">
                            CrediCare har vært i bransjen siden 1987 og
                            <span style="font-weight:bold;"> kan </span> treningsbransjen! De har et stort
                            spekter av tjenester du kan lese mer om her:

                            <span
                                @click="${() => window.open('https://www.credicare.no/bedrift')}"
                                style="color:white; cursor:pointer; text-decoration:underline;"
                            >
                                credicare.no</span
                            >
                        </p>
                    </div>
                </div>
            </div>
            <div style="background-color:#204373;">
                <div class="main-container">
                    <div class="sub-container dark-blue">
                        <h1 class="h1-text">Iizy</h1>

                        <div class="h1-img-container">
                            <div class="logo-container" @click="${() => window.open('https://iizy.no/')}">
                                <img class="partner-logo iizy" src="/images/new-website/iizylogo.jpg" />
                            </div>
                            <p class="partner-text" style="align-self:start;">
                                Regnskapssystemer finnes det mange av og de fleste har uilke formater de
                                ønsker å motta informasjonen på. Derfor har vi knyttet til oss Iizy. De mottar
                                informasjonen fra våre kunder og sender dette videre til regnskapssystemene
                                med det korrekte formatet. Helt automatisk, selvsagt! Les mer om Iizy her:

                                <span
                                    @click="${() => window.open('https://iizy.no/')}"
                                    style="color:white; cursor:pointer; text-decoration:underline;"
                                    >iizy.no</span
                                >
                            </p>
                        </div>
                    </div>
                    <div class="sub-container light-blue">
                        <h1 class="h1-text">Vipps</h1>

                        <div class="h1-img-container">
                            <div
                                class="logo-container"
                                @click="${() => window.open('https://vippsmobilepay.com/no/betalt-paa-nett')}"
                            >
                                <img
                                    style="width:8rem; height:4rem; align-self:center; width:10rem; "
                                    src="/images/vippsboost.png"
                                />
                            </div>
                            <p class="partner-text">
                                Vipps kjenner vi alle godt! Selv om avtalegiro er foretrukket betalingsmiddel
                                hos våre kunder så trenger de fortsatt betalingsløsninger når det skal selges
                                kurs, klippekort og ikke minst når første faktura skal betales ved innmelding.
                                Vipps er fremme i skoene når det gjelder utvikling, så nå vil også de som ikke
                                har eller kan ha Vipps velge å betale med kort! Les mer om Vippsintegrasjon
                                her:

                                <span
                                    @click="${() => window.open('https://vippsmobilepay.com/no/betalt-paa-nett')}"
                                    style="color:white; cursor:pointer; text-decoration:underline;"
                                    >vipps.no</span
                                >
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="main-container">
                <div class="sub-container dark-blue">
                    <h1 class="h1-text">Avtalegiro</h1>

                    <div class="h1-img-container">
                        <div
                            class="logo-container"
                            @click="${() => window.open('https://www.avtalegiro.no/bedrift')}"
                        >
                            <img
                                class="partner-logo ag-logo"
                                src="/images/new-website/avtalegiro-logo.png"
                                style="width:11rem;"
                            />
                        </div>
                        <p class="partner-text">
                            Avtalegiro er fortsatt den ledende løsningen for repeterende betalinger. I Boost
                            System får du automatisk inn oppdateringer av avtalegirostatus for dine medlemmer.
                            Denne løsningen krever heller ikke at dine medlemmer oppdaterer sin informasjon
                            når de får nye kort osv. Og.... avtalegiroen kan de ta med seg når de bytter bank!
                            Les mer om avtalegiro her:
                            <span
                                @click="${() => window.open('https://www.avtalegiro.no/bedrift')}"
                                style="color:white; cursor:pointer; text-decoration:underline;"
                                >avtalegiro.no</span
                            >
                        </p>
                    </div>
                </div>
                <div class="sub-container light-blue">
                    <h1 class="h1-text">EHF</h1>

                    <div class="h1-img-container">
                        <div
                            class="logo-container"
                            @click="${() => window.open('https://www.digdir.no/standarder/ehf-elektronisk-handelsformat/1678')}"
                        >
                            <img
                                style="width:8rem; height:4rem; align-self:center; width:5rem; height:2rem; "
                                src="/images/new-website/EHF.png"
                            />
                        </div>
                        <p class="partner-text" style="align-self:start;">
                            EHF er det standariserte formatet for å sende faktura direkte til mottakers
                            økonomisystemer. Mange mottakere av faktura krever nå EHF og vi har alt dette
                            klart for deg fra første dag! Les mer om EHF her:

                            <span
                                @click="${() => window.open('https://www.digdir.no/standarder/ehf-elektronisk-handelsformat/1678')}"
                                style="color:white; cursor:pointer; text-decoration:underline;"
                                >digdir.no</span
                            >
                        </p>
                    </div>
                </div>
            </div>
            <div style="background-color:#204373;">
                <div class="main-container">
                    <div class="sub-container dark-blue">
                        <h1 class="h1-text">Collectia</h1>

                        <div class="h1-img-container">
                            <div
                                class="logo-container"
                                @click="${() => window.open('https://collectiagroup.no/om-collectia/')}"
                            >
                                <img class="partner-logo" src="/images/collectia.png" />
                            </div>
                            <p class="partner-text" style="align-self:start;">
                                Collectia leverer purreservice, inkassotjenester og masse andre relaterte
                                tjenester til små og store bedrifter over hele landet. Les mer her:

                                <span
                                    @click="${() => window.open('https://collectiagroup.no/om-collectia/')}"
                                    style="color:white; cursor:pointer; text-decoration:underline;"
                                    >collectia.no</span
                                >
                            </p>
                        </div>
                    </div>
                    <div class="sub-container light-blue">
                        <h1 class="h1-text">NG Dialogue</h1>

                        <div class="h1-img-container">
                            <div
                                class="logo-container"
                                @click="${() => window.open('https://www.ngdialogue.com/')}"
                            >
                                <img class="ng-logo " src="/images/new-website/ng.png" />
                            </div>
                            <p class="partner-text">
                                NG Dialogue har spesialisert seg på å planlegge, utvikle og implementere
                                digitale kampanjer der målet er merkevarebygging, kundelojalitet og generering
                                av potensielle kunder. De henter data fra Boost System og gjør jobben på vegne
                                av dere. Les mer her:
                                <span
                                    @click="${() => window.open('https://www.ngdialogue.com/')}"
                                    style="color:white; cursor:pointer; text-decoration:underline;"
                                    >ngdialogue.com</span
                                >
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <boost-website-footer></boost-website-footer>
        `;
    }
};
BoostWebsitePartners._deps = [
    BoostWebsiteHeader,
    BoostImageProfileIcon,
    BoostWebsiteMailIcon,
    BoostWebsitePhoneIcon,
    BoostImageAboutUs,
    BoostWebsiteFooter,
];
__decorate([
    property({ type: Boolean }),
    __metadata("design:type", Boolean)
], BoostWebsitePartners.prototype, "smallScreen", void 0);
BoostWebsitePartners = __decorate([
    customElement(BoostWebsitePartners.is)
], BoostWebsitePartners);
export { BoostWebsitePartners };
