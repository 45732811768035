import { ExceptionHandler } from '../core/ExceptionHandler';
import { Utils } from '../Utils';
import { UserAgent } from './UserAgent';
/**
 * Helper utility for dom-manipulation
 */
/*	let Dom = function(doc) {
        this.document = doc;
        this._dblclick_link_disabled = false;
    };
    Dom.prototype = {
*/
export class Dom {
    constructor(document) {
        this.document = document;
        this._dblclick_link_disabled = false;
    }
    byId(id) {
        const documentPtr = this.getDocument();
        let query;
        if (id.match(/^[a-zA-Z_][a-zA-Z0-9_]+$/)) {
            query = '#' + id;
        }
        else {
            query = "[id='" + id + "']";
        }
        const nodeHit = this._queryNode(query, documentPtr.body);
        if (nodeHit) {
            return nodeHit;
        }
        try {
            // Midlertidig sak for � f� bl.a. epostklient til � funk ok med legacy-page-header
            const legacyPageHeader = documentPtr.getElementsByTagName('cerum-legacy-pageheader').item(0);
            if (legacyPageHeader && legacyPageHeader.shadowRoot) {
                const cerumBreadcrumb = legacyPageHeader.shadowRoot.querySelector('cerum-breadcrumb');
                if (cerumBreadcrumb && cerumBreadcrumb.shadowRoot) {
                    const rootNode = cerumBreadcrumb.shadowRoot;
                    const headingNodeHit = this._queryNode(query, rootNode);
                    if (headingNodeHit) {
                        return headingNodeHit;
                    }
                }
            }
        }
        catch (_error) {
            // void
        }
        // resorting to old method just in case
        return documentPtr.getElementById(id);
    }
    contains(posParent, posChildren) {
        let posChild = posChildren;
        while (posChild && posChild.parentNode) {
            posChild = posChild.parentNode;
            if (posChild === posParent) {
                return true;
            }
        }
        return false;
    }
    createElement(tagName) {
        return this.getDocument().createElement(tagName);
    }
    findLegendInFieldset(fieldset) {
        const childNodes = fieldset.childNodes;
        for (let i = 0; i < childNodes.length; i++) {
            const childNode = childNodes[i];
            //				if ((n.nodeType == 1) && (n.tagName == 'LEGEND')) {
            if (childNode instanceof HTMLLegendElement) {
                return childNode;
            }
            //				}
        }
        return null;
    }
    getDocument() {
        return this.document ? this.document : document;
    }
    getFormValues() {
        const documentPtr = this.getDocument();
        const data = {};
        try {
            for (let formIndex = 0; formIndex < documentPtr.forms.length; formIndex++) {
                const elements = documentPtr.forms[formIndex].elements;
                for (let elementIndex = 0; elementIndex < elements.length; elementIndex++) {
                    const element = elements[elementIndex];
                    if (element instanceof HTMLInputElement) {
                        const type = element.getAttribute('type'); // (typeof(e.type) != 'undefined') ? e.type : '';
                        if (type && type.match(/radio|checkbox/i) && !element.checked) {
                            // getAttribute('checked'))
                            continue;
                        }
                    }
                    if (element instanceof HTMLInputElement ||
                        element instanceof HTMLSelectElement ||
                        element instanceof HTMLTextAreaElement) {
                        data[element.name] = element.value;
                    }
                }
            }
        }
        catch (error) {
            ExceptionHandler.handle(error);
        }
        return data;
    }
    getWindow() {
        const documentPtr = this.getDocument();
        let windowPtr = null;
        if (typeof documentPtr.defaultView !== 'undefined') {
            windowPtr = documentPtr.defaultView;
        }
        else if (typeof documentPtr.window !== 'undefined') {
            windowPtr = documentPtr.window;
        }
        else if (typeof documentPtr.parentWindow !== 'undefined') {
            windowPtr = documentPtr.parentWindow;
        }
        if (!windowPtr) {
            windowPtr = window;
        }
        if (typeof windowPtr === 'undefined' || !windowPtr) {
            console.error('finner ikke window. document: ', documentPtr && documentPtr.location ? documentPtr.location.href : 'false');
        }
        return windowPtr;
    }
    htmlEntities(inputStr) {
        const node = this.getDocument().createElement('span');
        const txtNode = this.getDocument().createTextNode(inputStr);
        node.appendChild(txtNode);
        return node.innerHTML;
    }
    /**
     * Install/execute a javascript
     */
    instScript(jsString) {
        if (!jsString) {
            return;
        }
        const windowPtr = this.getWindow();
        if (typeof windowPtr === 'undefined' || !windowPtr) {
            console.error('[instScript]: Mangler win', this);
        }
        //  Internet Explorer has a funky execScript method that makes this easy
        try {
            const windowPtrAsAny = windowPtr;
            if (windowPtrAsAny.execScript) {
                windowPtrAsAny.in_install_script = true;
                windowPtrAsAny.execScript(jsString);
                windowPtrAsAny.in_install_script = false;
            }
            else {
                windowPtr.setTimeout(function () {
                    windowPtrAsAny.in_install_script = true;
                    windowPtrAsAny.eval(jsString);
                    windowPtrAsAny.in_install_script = false;
                }, 0);
            }
        }
        catch (error) {
            console.error('[instScript]: feil med javascript: ' + jsString.substring(0, 100), error);
        }
    }
    intoElement(result, id, delegate) {
        let targetDomNode = null;
        let domId = '';
        try {
            const documentPtr = this.getDocument();
            const windowPtr = this.getWindow();
            if (windowPtr.HTMLElement && id instanceof windowPtr.HTMLElement) {
                targetDomNode = id;
                domId = targetDomNode.id;
            }
            else if (windowPtr.Polymer && id instanceof windowPtr.Polymer.DomApi) {
                targetDomNode = id;
                domId = targetDomNode.id;
            }
            else if (typeof id === 'string') {
                targetDomNode = this.byId(id);
                domId = id;
            }
            else {
                domId = id.id;
            }
            if (!id && !targetDomNode) {
                console.error('intoElement failed, missing dom-id: [' + domId + ']. Document:', documentPtr);
                return false;
            }
            if (!targetDomNode) {
                console.error('intoElement failed for dom-id: [' + domId + ']. Document:', documentPtr);
                return false;
            }
            const userAgent = new UserAgent();
            /**
             * Look for jscript and do a bugfix for IE
             *
             * Dersom innholdet som skal inn i elementet begynner med en script-tag
             * s� f�r IE fullstendig superforvirring.
             * Fiksen er � slenge inn en span med litt "usynlig" innhold f�rst,
             * s� fjerne den etter at alt er p� plass.
             */
            let fjernId = '';
            if (userAgent.isIE && result.match(/^[ \t\r\n]*<script/i)) {
                fjernId = 'fjern_meg_' + new Date().getTime();
                const nyResult = '<span id="' +
                    fjernId +
                    '" style="position: absolute; visibility: hidden;">&nbsp;</span>' +
                    result;
                result = nyResult;
            }
            try {
                // IE takkel ikkje innerHTML inserts p� table/tbody, s� vi gj�r det om tell en tabell
                // insert den helt uskyldig inn i en div, �ss� fl�tt vi rade/tbody fra juksetabellen og
                // over i den tabellen som ska ha det..
                if (userAgent.isIE &&
                    (targetDomNode.tagName === 'TBODY' || targetDomNode.tagName === 'TABLE')) {
                    let parent = targetDomNode.parentNode;
                    if (parent instanceof HTMLElement && parent.tagName === 'TABLE') {
                        // Hvis vi let p� tbody, s� m� vi opp et hakk til.
                        parent = parent.parentNode;
                    }
                    if (parent) {
                        const tbid = '_x_dynamic_content_' + new Date().getTime();
                        const divid = '_x_dynamic_div_' + new Date().getTime();
                        const nyresult = '<table id="' + tbid + '">' + result + '</table>';
                        const tmpdiv = documentPtr.createElement('div');
                        tmpdiv.id = divid;
                        //						let tmpdiv = doc.createElement('div id="'+divid+'"');
                        // Spore opp kontaineren til tabellen
                        tmpdiv.innerHTML = nyresult;
                        tmpdiv.style.display = 'none';
                        tmpdiv.style.position = 'absolute';
                        parent.appendChild(tmpdiv);
                        const nytable = documentPtr.getElementById(tbid);
                        // overf�r fra tmp til dest
                        const chlds = targetDomNode.children;
                        // Fjern gamle
                        while (chlds.length) {
                            targetDomNode.removeChild(chlds[0]);
                        }
                        let srcTable = nytable;
                        if (targetDomNode.tagName === 'TBODY') {
                            // Hvis opprinnelig target let en tbody, s� henter vi rader fra
                            // den f�rste tbodyen i den nye tabellen, hvis vi f�r det til...
                            let node = null;
                            try {
                                if (srcTable && srcTable.children.length) {
                                    const childElement = srcTable.children[0];
                                    if (childElement instanceof HTMLElement) {
                                        node = childElement;
                                    }
                                }
                            }
                            catch (error) {
                                console.error(error);
                            }
                            if (node) {
                                srcTable = node;
                            }
                            // src = src.children[0];
                        }
                        if (srcTable) {
                            const srcTableChildren = srcTable.children;
                            while (srcTableChildren.length) {
                                targetDomNode.appendChild(srcTableChildren[0]);
                            }
                        }
                        // fjern opprinnelsen
                        parent.removeChild(tmpdiv);
                    }
                }
                else if (targetDomNode.tagName === 'FIELDSET') {
                    const fieldsetNode = targetDomNode;
                    const keepLegend = this.findLegendInFieldset(fieldsetNode);
                    fieldsetNode.innerHTML = result;
                    if (!this.findLegendInFieldset(fieldsetNode) && keepLegend) {
                        let first = null;
                        const childNodes = fieldsetNode.childNodes;
                        /**
                         * Firefox gl�m bort � tegn legend hvis man b�re putt den inn med ein gong, s� d�
                         * m� vi b�re vent litt... :-)
                         */
                        windowPtr.setTimeout(() => {
                            if (!keepLegend) {
                                return;
                            }
                            if (childNodes.length) {
                                first = fieldsetNode.childNodes[0];
                                fieldsetNode.insertBefore(keepLegend, first);
                            }
                            else {
                                fieldsetNode.appendChild(keepLegend);
                            }
                        }, 10);
                    }
                }
                else {
                    targetDomNode.innerHTML = result;
                }
            }
            catch (error) {
                console.error('doc: ', documentPtr.location ? documentPtr.location.href : null);
                console.error('fikk ikke til[1] å putte inn i innerHTML(' + domId + '). Exception: ', error);
                return;
            }
            // "Bugfix" for aa kjore script i innerhtml...
            try {
                const scripts = targetDomNode.getElementsByTagName('script');
                for (let i = 0; i < scripts.length; i++) {
                    if (scripts[i].getAttribute('runwithajax') ||
                        scripts[i].getAttribute('data-runwithajax')) {
                        const scriptElement = scripts[i].innerHTML;
                        this.instScript(scriptElement);
                    }
                }
            }
            catch (error) {
                console.error('Feil under herjing med script (intoElement)', error);
            }
            try {
                if (fjernId) {
                    const tilFjerning = documentPtr.getElementById(fjernId);
                    if (tilFjerning) {
                        targetDomNode.removeChild(tilFjerning);
                    }
                }
            }
            catch (_error) {
                /* void */
            }
            try {
                let _win;
                let _doc;
                // F�rst pr�v � finn document
                if (typeof targetDomNode.ownerDocument !== 'undefined') {
                    _doc = targetDomNode.ownerDocument;
                }
                else {
                    let parent = targetDomNode;
                    let last = null;
                    let max = 1000;
                    do {
                        last = parent;
                        if (!max--) {
                            break;
                        }
                    } while (parent.parentNode instanceof HTMLElement && (parent = parent.parentNode));
                    if (last instanceof Document) {
                        _doc = last;
                    }
                    else if (parent.parentNode instanceof Document) {
                        _doc = parent.parentNode;
                    }
                }
                if ('window' in targetDomNode && typeof targetDomNode.window !== 'undefined') {
                    _win = targetDomNode.window;
                }
                else if (_doc) {
                    const documentPtrAsAny = _doc;
                    if ('contentWindow' in documentPtrAsAny && documentPtrAsAny.contentWindow) {
                        _win = documentPtrAsAny.contentWindow;
                    }
                    else if ('parentWindow' in documentPtrAsAny && documentPtrAsAny.parentWindow) {
                        _win = documentPtrAsAny.parentWindow;
                    }
                    else if ('parentView' in documentPtrAsAny && documentPtrAsAny.parentView) {
                        _win = documentPtrAsAny.parentView;
                    }
                }
                if (delegate) {
                    delegate.dom_node = targetDomNode;
                }
                if (delegate && delegate.dominserted) {
                    try {
                        delegate.dominserted(targetDomNode);
                    }
                    catch (_error) {
                        /* void */
                    }
                }
                this.onNewContentInElement(targetDomNode, _doc, _win);
            }
            catch (_error) {
                /* void */
            }
        }
        catch (error) {
            console.error('fant ikke element eller.no: ' + domId, error);
        }
        return targetDomNode;
    }
    /**
     * Funksjon som unng�r dobbeltklikk p� knapper
     *
     * Lokal variabel er deklarert lenger opp i denne
     * anonyme funksjonen. De 2 funksjonen vi deklarer
     * her f�r en peker til den. N�r vi g�r ut av denne
     * anonyme funksjonen vi har deklarert det i, s� har
     * ingen andre pekere til den.
     * De deler derfor en "hemmelig" variabel
     */
    isNotDoubleClick() {
        const windowPtr = this.getWindow();
        if (windowPtr.in_install_script) {
            return true;
        }
        if (this._dblclick_link_disabled) {
            return false;
        }
        const that = this; // Lage levere-til-closure-peker
        this._dblclick_link_disabled = true;
        // Aktiver klikk igjen om 1s
        setTimeout(() => {
            // Blokker klikk for andre for en stund
            that._dblclick_link_disabled = false;
        }, 1000);
        // console.debug("click disabled", this._dblclick_link_disabled);
        return true;
    } // end function
    onNewContentInElement(_targetDomNode, _documentPtr, _windowPtr) {
        /* void */
    }
    reloadDocument(documentPtr) {
        if (!documentPtr) {
            documentPtr = this.getDocument();
        }
        // @TODO bear:2012.08.23
        // sjekk om dokumentet er gyldig?
        // I tilfelle et oppbrukt document har
        // en eventhandler e.l. som kan trigges un�dvendig?
        if (!documentPtr.location) {
            console.error('document.location er tom');
            return;
        }
        let locationHref = documentPtr.location.href;
        const uxTimeMs = new Date().getTime();
        if (locationHref.match(/_x_t_=/)) {
            locationHref = locationHref.replace(/_x_t_=\d+/, '_x_t_=' + uxTimeMs);
        }
        else {
            if (locationHref.match(/\?/)) {
                locationHref += '&';
            }
            else {
                locationHref += '?';
            }
            locationHref += '_x_t_=' + new Date().getTime();
        }
        documentPtr.location.href = locationHref;
    }
    runjavascriptonlyonce(id, javascript) {
        const window = this.getWindow();
        if (typeof window === 'undefined' || !window) {
            console.error('Mangler win', this);
        }
        const anyWindow = window;
        if (typeof anyWindow._voidids === 'undefined') {
            anyWindow._voidids = [];
        }
        const voidIds = (anyWindow._voidids || []);
        if (Utils.in_array(id, voidIds)) {
            return;
        }
        anyWindow._voidids[anyWindow._voidids.length] = id;
        try {
            const someEval = anyWindow.eval ? anyWindow.eval : eval;
            someEval(javascript);
        }
        catch (error) {
            ExceptionHandler.handle(error);
        }
    }
    validDocument(vDocument) {
        if (!vDocument) {
            return false;
        }
        try {
            const documentDomHandler = new Dom(vDocument);
            const domWindow = documentDomHandler.getWindow();
            // documentet peker p� et window, hvis windowed ikke peker tilbake
            // er document-variabelen ubrukelig
            return domWindow.document === vDocument;
        }
        catch (_error) {
            // vi vet ikke, og da er det d�rlig gjort � si at den er defekt
            return true;
        }
    }
    validNode(node) {
        try {
            if (!node) {
                return false;
            }
            if (node.nodeType === node.DOCUMENT_NODE) {
                return this.validDocument(node);
            }
            const ownerDocument = node.ownerDocument;
            if (!ownerDocument) {
                return false;
            }
            if (!this.validDocument(ownerDocument)) {
                return false;
            }
            return this.contains(ownerDocument, node);
        }
        catch (exception) {
            console.warn('Dom.validNode gav exception', { Dom, node, exception });
            return false;
        }
    }
    _queryNode(query, rootNode, _id) {
        const nodes = rootNode.querySelectorAll(query);
        if (nodes.length > 0) {
            const node = nodes[0];
            if (nodes.length > 1) {
                console.warn('Found multiple elements for query [' +
                    query +
                    ']. Count: ' +
                    nodes.length +
                    '. Returning first.');
            }
            if (node instanceof HTMLElement) {
                return node;
            }
            else {
                console.warn('Found element for query [' + query + "] but it's not an HTMLElement", node);
                return null;
            }
        }
        return null;
    }
}
