var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { css, html, LitElement } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { BoostWebsiteFooter } from '../components/BoostWebsiteFooter';
import { BoostWebsiteHeader } from '../components/BoostWebsiteHeader';
import { Router } from '@vaadin/router';
let BoostWebsiteFunctions = class BoostWebsiteFunctions extends LitElement {
    constructor() {
        super(...arguments);
        this.smallScreen = false;
    }
    static get is() {
        return 'boost-website-functions';
    }
    static get styles() {
        return css `
            .background-img-container {
                height: 39rem;
                object-fit: cover;
                background: linear-gradient(rgba(18, 80, 146, 0.5), rgba(8, 32, 57, 0.5)),
                    url('images/new-website/funksjonerbakgrunn.jpg');
                background-size: cover;
                margin-top: 8rem;
            }

            .background-img {
                width: 100%;
                height: 50rem;
            }

            .header-h1 {
                color: #ffffff;
                transform: translate(0px, 10rem);
                width: 50%;
                padding-left: 6rem;
                line-height: 1.4;
                font-size: 50px;
                font-weight: normal;
            }

            .header-text {
                font-size: 36px;
                color: #ffffff;
                width: 88%;
                font-weight: lighter;
            }
            .main-container {
                display: flex;
                flex-direction: row;
                width: 100%;
                padding-bottom: 4rem;
                justify-content: center;
                gap: 4rem;
                max-width: 86rem;
                margin: 0 auto;
            }

            .h1-img-container {
                display: flex;
                flex-direction: row;
            }

            .partner-logo {
                width: 10rem;
                height: 4rem;
                align-self: center;
            }

            .partner-text {
                align-self: end;
                padding-left: 1rem;
                margin: 0;
                width: 30rem;
            }

            .logo-container {
                width: 9rem;
                height: 6rem;
                background-color: white;
                border-radius: 29px;
                display: flex;
                justify-content: center;
            }

            .sub-container {
                max-width: 45%;
                width: 50rem;
                display: flex;
                flex-direction: column;

                margin-top: 4rem;
            }

            .h1-app-text {
                width: 46rem;
            }

            .portal-img {
                width: 3rem;
                height: 6rem;
                align-self: center;
            }

            .price-text {
                align-self: start;
            }

            .logo-background {
                width: 10rem;
                height: 6rem;
                background-color: white;
                border-radius: 29px;
                align-items: center;
                display: flex;
                justify-content: center;
            }

            @media screen and (max-width: 1500px) {
                .header-h1 {
                    width: 70%;
                }
                .h1-app-text {
                    width: 23rem;
                }
            }

            @media screen and (max-width: 1000px) {
                .background-img-container {
                    background-repeat: no-repeat;
                    margin-top: 7rem;
                    background-size: cover;
                    height: 20rem;
                    width: 100%;
                }
                .header-h1 {
                    width: 98%;
                    padding-left: 10px;
                    transform: translate(0px, 36px);
                    font-size: 36px;
                }

                .header-text {
                    font-size: 20px;
                }

                .main-container {
                    flex-direction: column;
                    padding-bottom: 0rem;
                    gap: 0rem;
                }

                .sub-container {
                    max-width: 100%;
                    width: 100%;
                    flex-direction: column;
                    margin-top: 0rem;
                }

                .h1-img-container {
                    flex-direction: column;
                }

                .partner-text {
                    padding-left: 10px;
                    align-self: start;
                    width: 98%;
                }

                .logo-container {
                    height: 4rem;
                    width: 4rem;
                    position: absolute;
                    transform: translate(18rem, -5rem);
                }

                .h1-text {
                    font-size: 20px;
                    margin-left: 10px;
                }

                .vipps {
                    display: none;
                }

                .h1-app-text {
                    width: 15rem;
                }

                .portal-img {
                    width: 3rem;
                    height: 6rem;
                    align-self: start;
                    transform: translate(15rem, -4.5rem);
                }

                .sky {
                    width: 15rem;
                }

                .price-text {
                    align-self: end;
                }

                .dark-blue {
                    background-color: #132c4f;
                    padding-bottom: 2rem;
                    padding-top: 2rem;
                }

                .light-blue {
                    background-color: #204373;
                    padding-bottom: 2rem;
                    padding-top: 2rem;
                }

                .logo-background {
                    background-color: inherit;
                    height: 4rem;
                }

                .screenshot {
                    position: absolute;
                    transform: translate(15rem, -4rem);
                }
            }
        `;
    }
    /** @override */ render() {
        return html `
            <boost-website-header
                @screen-size-changed="${(event) => (this.smallScreen = event.detail.value)}"
            ></boost-website-header>
            <div class="background-img-container">
                <h1 class="header-h1">
                    Våre løsninger
                    <p class="header-text">
                        Vi har mange funksjoner som bidrar til å gi ditt treningssenter den komplette
                        løsningen.
                    </p>
                </h1>
            </div>
            <div class="main-container">
                <div class="sub-container dark-blue">
                    <h1 class="h1-text vipps">Vipps</h1>

                    <div class="h1-img-container">
                        <div class="logo-background">
                            <img class="partner-logo" src="/images/vippsboost.png" />
                        </div>
                        <p class="partner-text">
                            Fortsatt er Avtalegiro den foretrukne betalingsmåten på treningssentre. Vi har
                            også integrert oss mot VIPPS for enklere betaling av første faktura. Vipps brukes
                            også når medlemmer skal betale for korttidsmedlemskap og klippekort. PS: Vipps er
                            ikke lenger bare Vipps. Medlemmer kan også betale med kort.
                        </p>
                    </div>
                </div>
                <div class="sub-container light-blue">
                    <h1 class="h1-text">Full drift</h1>

                    <div class="h1-img-container">
                        <div
                            class="logo-container"
                            style="background:url('images/new-website/fulldrift.jpg'); background-size:cover;"
                        ></div>
                        <p class="partner-text full-drift">
                            Kjedelig med rutiner? <br /><br />

                            Det skjønner vi godt. Dette er vi gode på og ønsker å gjøre dette for deg. Vi tar
                            oss av rutinene med månedstrekk, innbetalinger og betalingsoppfølging slik at du
                            kan bruke din tid på det som gjør senteret attraktivt for medlemmer.
                        </p>
                    </div>
                </div>
            </div>
            <div style="background-color:#204373;">
                <div class="main-container">
                    <div class="sub-container dark-blue" @click="${() => Router.go('/app')}">
                        <h1 class="h1-text h1-app-text">Medlemsapp med mange funksjoner</h1>

                        <div class="h1-img-container">
                            <div class="logo-background">
                                <img
                                    class="partner-logo screenshot"
                                    src="/images/new-website/medlemsapp.png"
                                    style="height:6rem; width:3rem;"
                                />
                            </div>
                            <p class="partner-text ">
                                Vi er stolte av appen vår. Her finner medlemmene all info de trenger samt at
                                de kan booke seg på timer, åpne dør til senteret og lese nyheter du har lagt
                                ut. I tillegg har vi selvsagt nyttig funksjonalitet for dine instruktører. Les
                                mer om appen
                                <span
                                    @click="${() => Router.go('/app')}"
                                    style="text-decoration: underline; cursor:pointer;"
                                    >her.</span
                                >
                            </p>
                        </div>
                    </div>
                    <div class="sub-container light-blue">
                        <h1 class="h1-text">Kommunikasjon</h1>

                        <div class="h1-img-container">
                            <div
                                class="logo-container"
                                style="background:url('images/new-website/kommunikasjon.jpg'); background-size:cover;"
                            ></div>
                            <p class="partner-text">
                                Effektiv kommunikasjon er nøkkelen for lojale medlemmer.
                                <br /><br />
                                I Boost System kan du kommunisere på e-post eller SMS. I tillegg kan du sende
                                ut nyhetsbrev til medlemmene i appen. Medlemmer vil selvsagt får push-varsel
                                når du sender ut et nyhetsbrev.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="main-container">
                <div class="sub-container dark-blue">
                    <h1 class="h1-text">Integrasjoner</h1>

                    <div class="h1-img-container">
                        <div
                            class="logo-container"
                            style="background:url('images/new-website/integrasjoner.jpg'); background-size:cover;"
                        ></div>
                        <p class="partner-text ">
                            Man kan ikke alltid finne opp kruttet selv. Derfor har vi integrert oss mot
                            aktører i flere segmenter. Vi er integrert mot purre/inkasso-selskaper, Vipps,
                            Iizy for regnskapsfiler og systemer for medlemsoppfølging. Les mer om våre
                            partnere
                            <span
                                style="font-weight:bold; text-decoration:underline; cursor:pointer;"
                                @click="${() => Router.go('/partners')}"
                            >
                                her</span
                            >.
                        </p>
                    </div>
                </div>
                <div class="sub-container light-blue">
                    <h1 class="h1-text">Innmeldingsportal</h1>

                    <div class="h1-img-container">
                        <div class="logo-background">
                            <img class="portal-img" src="/images/new-website/innmeldingsportal.png" />
                        </div>
                        <p class="partner-text ">
                            Ditt senter får en egen portal der nye og gamle medlemmer kan melde seg inn.
                            <br />
                            <br />
                            I portalen har du dine vanlige medlemskap, kampanjer, klippekort og tidsavgrensede
                            medlemskap. Elektronisk signering av avtalegiro er selvsagt integrert. Når noen
                            har meldt seg inn kan de logge seg inn i appen og gå inn på senteret umiddelbart
                            etter signering.
                        </p>
                    </div>
                </div>
            </div>
            <div style="background-color:#204373;">
                <div class="main-container" style="background-color:#204373;">
                    <div class="sub-container dark-blue">
                        <h1 class="h1-text">Betalingsoppfølging</h1>

                        <div class="h1-img-container">
                            <div
                                class="logo-container"
                                style="background:url('images/new-website/payment.jpg'); background-size:cover;"
                            ></div>
                            <p class="partner-text">
                                Vi har helautomatiserte systemer for betalingsoppfølging.
                                <br /><br />
                                Vi sender først ut betalingspåminnelse med mulighet for å betale med Vipps før
                                vi eventuelt sender saker videre til purrepartner. Denne løsningen har nesten
                                halvert antall purringer for våre kunder og samtidig gitt en mer fornøyd
                                medlemsmasse.
                            </p>
                        </div>
                    </div>
                    <div class="sub-container light-blue">
                        <h1 class="h1-text sky">Skybasert desktopversjon</h1>

                        <div class="h1-img-container">
                            <div
                                class="logo-container"
                                style="background:url('images/new-website/skybasert.jpg'); background-size:cover; background-position:center;"
                            ></div>
                            <p class="partner-text ">
                                Verktøyet for administrasjon ligger i skyen. Uansett hvor i verden du er har
                                du tilgang til adminverktøyet. Logg deg på og du ser det samme du gjør hjemme,
                                alltid oppdatert.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="main-container">
                <div class="sub-container dark-blue">
                    <h1 class="h1-text">Nytt senter?</h1>

                    <div class="h1-img-container">
                        <div
                            class="logo-container"
                            style="background:url('images/new-website/kettlebell.jpg'); background-size:cover;"
                        ></div>
                        <p class="partner-text">
                            Det er en jungel av systemer der ute.
                            <br /><br />
                            Ta kontakt med oss så tar vi en prat om ulike løsninger som kan være aktuelle for
                            deg. Vi er opptatt av at ditt nye senter skal få en god løsning både systemmessig
                            og økonomsk.
                        </p>
                    </div>
                </div>
                <div class="sub-container light-blue">
                    <h1 class="h1-text">Priser</h1>

                    <div class="h1-img-container">
                        <div
                            class="logo-container"
                            style="background:url('images/new-website/sparegris.jpg'); background-size:cover; background-position: center;"
                        ></div>
                        <p class="partner-text price-text">
                            Prisen på Boost System varierer litt etter hvilke løsninger du ønsker. Det du kan
                            være sikker på at er at du får et konkurransedyktig tilbud med de beste løsningene
                            - uten noen skjulte kostnader.
                            <br />
                            <br />
                            Ta kontakt med oss for et uforpliktende tilbud.
                        </p>
                    </div>
                </div>
            </div>
            <boost-website-footer></boost-website-footer>
        `;
    }
};
BoostWebsiteFunctions._deps = [BoostWebsiteHeader, BoostWebsiteFooter];
__decorate([
    state(),
    __metadata("design:type", Boolean)
], BoostWebsiteFunctions.prototype, "smallScreen", void 0);
BoostWebsiteFunctions = __decorate([
    customElement(BoostWebsiteFunctions.is)
], BoostWebsiteFunctions);
export { BoostWebsiteFunctions };
