var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export class PolyfillHeaders {
    constructor() {
        this.map = {};
    }
    /** @override */ append(name, value) {
        this.map[name] = value;
    }
    /** @override */ delete(name) {
        delete this.map[name];
    }
    /** @override */ forEach(callbackfn, _thisArg) {
        for (const i in this.map) {
            callbackfn(this.map[i], i, this);
        }
    }
    /** @override */ get(name) {
        return name in this.map ? this.map[name] : null;
    }
    /** @override */ has(name) {
        return name in this.map;
    }
    /** @override */ set(name, value) {
        this.map[name] = value;
    }
    /** @override */ getSetCookie() {
        // Missing implementation. Not used by us, mostly indented for node server services
        // https://developer.mozilla.org/en-US/docs/Web/API/Headers/getSetCookie
        return [];
    }
}
export class PolyfillResponse {
    /** @override */ get body() {
        throw new Error('PolyfillResponse.() Getter not implemented');
    }
    /** @override */ get bodyUsed() {
        throw new Error('PolyfillResponse.() Getter not implemented');
    }
    /** @override */ get headers() {
        return this.responseInit.headers;
    }
    /** @override */ get ok() {
        throw new Error('PolyfillResponse.ok() Getter not implemented');
    }
    /** @override */ get redirected() {
        throw new Error('PolyfillResponse.redirected() Getter not implemented');
    }
    /** @override */ get status() {
        return this.responseInit.status || 0;
    }
    /** @override */ get statusText() {
        return this.responseInit.statusText || '';
    }
    /** @override */ get trailer() {
        throw new Error('PolyfillResponse.trailer() Getter not implemented');
    }
    /** @override */ get type() {
        throw new Error('PolyfillResponse.type() Getter not implemented');
    }
    /** @override */ get url() {
        throw new Error('PolyfillResponse.url() Getter not implemented');
    }
    constructor(xhr, response, responseInit) {
        this.xhr = xhr;
        this.response = response;
        this.responseInit = responseInit;
    }
    /** @override */ arrayBuffer() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.response instanceof ArrayBuffer) {
                return this.response;
            }
            throw new Error('PolyfillResponse.arrayBuffer() Method not implemented.');
        });
    }
    /** @override */ blob() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.response instanceof Blob) {
                return this.response;
            }
            throw new Error('PolyfillResponse.blob() Method not implemented.');
        });
    }
    /** @override */ clone() {
        return new PolyfillResponse(this.xhr, this.response, this.responseInit);
    }
    /** @override */ formData() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.response instanceof FormData) {
                return this.response;
            }
            throw new Error('PolyfillResponse.formData() Method not implemented.');
        });
    }
    /** @override */ json() {
        const response = this.response;
        if (typeof response == 'string') {
            return new Promise((resolve, reject) => {
                try {
                    const json = JSON.parse(response);
                    if (json) {
                        resolve(json);
                    }
                    else {
                        reject(json);
                    }
                }
                catch (error) {
                    reject(error);
                }
            });
        }
        throw new Error('Bad response type ' + typeof response);
        //#            throw new Error("PolyfillResponse.json() Method not implemented.");
    }
    /** @override */ text() {
        const response = this.response;
        if (typeof response == 'string') {
            return new Promise((resolve, reject) => {
                try {
                    resolve(response);
                }
                catch (error) {
                    reject(error);
                }
            });
        }
        throw new Error('Bad response type ' + typeof response);
    }
}
