import { Router } from '@vaadin/router';
import { BoostWebsiteAboutUs } from '../pages/BoostWebsiteAboutUs';
import { BoostWebsiteContactRequestSuccess } from '../pages/BoostWebsiteContactRequestSuccess';
import { BoostWebsiteFrontPage } from '../pages/BoostWebsiteFrontPage';
import { BoostWebsiteContact } from '../pages/BoostWebsiteContact';
import { BoostWebsitePrivacyPolicy } from '../pages/BoostWebsitePrivacyPolicy';
import { BoostWebsiteSupport } from '../pages/BoostWebsiteSupport';
import { BoostWebsitePartners } from '../pages/BoostWebsitePartners';
import { BoostWebsiteFunctions } from '../pages/BoostWebsiteFunctions';
import { BoostWebsiteApp } from '../pages/BoostWebsiteApp';
export const router = new Router();
router.setRoutes([
    {
        path: '/',
        component: BoostWebsiteFrontPage.is,
    },
    {
        path: '/contact',
        component: BoostWebsiteContact.is,
    },
    {
        path: '/about',
        component: BoostWebsiteAboutUs.is,
    },
    {
        path: '/support',
        component: BoostWebsiteSupport.is,
    },
    {
        path: '/success',
        component: BoostWebsiteContactRequestSuccess.is,
    },
    {
        path: '/functions',
        component: BoostWebsiteFunctions.is,
    },
    {
        path: '/partners',
        component: BoostWebsitePartners.is,
    },
    {
        path: '/app',
        component: BoostWebsiteApp.is,
    },
    {
        path: '/privacypolicy',
        component: BoostWebsitePrivacyPolicy.is,
    },
]);
