var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { css, html, LitElement } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { TextField } from '@vaadin/text-field';
import { TextArea } from '@vaadin/text-area';
import { EmailField } from '@vaadin/email-field';
import { BoostWebsiteHeader } from '../components/BoostWebsiteHeader';
import { isErrorHTTP, isSuccessHTTP } from 'base-api/APIBase';
import { SnackBar } from 'common-uicomponents/components/SnackBar';
import { Router } from '@vaadin/router';
import { WebAPI } from 'webpage-api/cerum/webpage/web/WebAPI';
import { BoostImageContact } from '../../website-images/BoostImageContact';
import { BoostWebsiteFooter } from '../components/BoostWebsiteFooter';
let BoostWebsiteContact = class BoostWebsiteContact extends LitElement {
    constructor() {
        super(...arguments);
        this.input = { name: '', message: '', phone: '', mail: '' };
        this.smallScreen = false;
    }
    static get is() {
        return 'boost-website-contact';
    }
    static get styles() {
        return css `
            .contact-form {
                background-color: #204373;
                width: 44%;
                border-radius: 6px;
                padding: 5rem;
                display: flex;
                flex-direction: column;
                margin-bottom: 25px;
            }

            .information-container {
                width: 40%;
                padding-top: 20px;
            }

            .background-img-container {
                height: 39rem;
                object-fit: cover;
                background: linear-gradient(rgba(18, 80, 146, 0.5), rgba(8, 32, 57, 0.5)),
                    url('images/new-website/keyboard-background.jpg');
                background-size: cover;
                margin-top: 8rem;
                background-position: center;
            }

            .background-img {
                width: 100%;
                height: 39rem;
            }

            .header-h1 {
                color: #ffffff;
                transform: translate(0px, 10rem);
                width: 50%;
                padding-left: 6rem;
                line-height: 1.4;
                font-size: 50px;
                font-weight: normal;
            }

            .header-text {
                font-size: 36px;
                color: #ffffff;
                width: 88%;
                font-weight: lighter;
            }

            .icon-text-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 20px;
                justify-content: center;
                margin: 40px;
            }

            .contact-h1 {
                font-weight: lighter;
                font-size: 36px;
                margin: 0;
                color: #fff;
            }

            .logo-thumb {
                height: 60px;
                width: 60px;
            }

            .text {
                color: #ffff;
            }

            .contact-form-container {
                display: flex;
                justify-content: center;
                max-width: 86rem;
                margin: 0 auto;
            }

            .contact-footer {
                display: flex;
                flex-direction: column;
                margin-top: 4rem;
                width: 100%;
                justify-content: center;

                background-color: #204373;
                padding-top: 4rem;
            }

            .org-container {
                justify-content: space-evenly;
                display: flex;
                margin-top: 3rem;
            }

            .flex-container {
                display: flex;
                flex-direction: row-reverse;
                justify-content: space-evenly;
            }
            .header-logo {
                height: 6rem;
                width: 15rem;
                align-self: center;
            }

            .map-text {
                font-size: 16px;
            }

            .map {
                width: 70%;
                border-radius: 10px;
                height: 16rem;
            }

            vaadin-text-field {
                --vaadin-input-field-background: white;
                --vaadin-input-field-label-color: white;
                --vaadin-input-field-invalid-background: #ffe1e1;
                --vaadin-input-field-placeholder-color: #cccccc;
            }

            vaadin-text-field:hover {
                --vaadin-input-field-hovered-label-color: white;
            }

            vaadin-text-area {
                --vaadin-input-field-label-color: white;
                --vaadin-input-field-background: white;
                --vaadin-input-field-invalid-background: #ffe1e1;
                --vaadin-input-field-placeholder-color: #cccccc;
                --vaadin-input-field-focused-label-color: white;
            }

            vaadin-text-area:hover::part(input-field) {
                background-color: white;
            }

            vaadin-text-area:hover::part(label) {
                color: white;
            }

            vaadin-email-field {
                --vaadin-input-field-label-color: white;
                --vaadin-input-field-background: white;
                --vaadin-input-field-invalid-background: #ffe1e1;
                --vaadin-input-field-placeholder-color: #cccccc;
            }

            vaadin-email-field:hover {
                --vaadin-input-field-hovered-label-color: white;
                --vaadin-input-field-focused-label-color: white;
            }

            .submit-btn {
                height: 3em;
                width: 14rem;
                background-color: #2dfe53;
                border-radius: 50px;
                border: none;
                color: #132c4f;
            }

            .text-wrapper {
                display: flex;
                flex-direction: column;
                line-height: 1;
                max-width: 86rem;
                margin: 0 auto;
            }

            .icon-text {
                display: flex;
                flex-direction: row;
                gap: 20px;
                align-items: center;
                width: 100%;
                justify-content: center;
            }

            .text-support {
                margin: 0px auto;
                width: 56%;
            }

            .footer-text {
                width: 43%;
                display: flex;
                flex-direction: row-reverse;
                gap: 2rem;
            }

            .social-media-container {
                display: flex;
                flex-direction: column;
                align-self: center;
            }

            .social-media-logo {
                width: 40px;
                height: 40px;
            }

            .social-media-icon-text {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 2rem;
                cursor: pointer;
            }

            @media screen and (max-width: 1000px) {
                .background-img-container {
                    background-repeat: no-repeat;
                    margin-top: 7rem;
                    background-size: cover;
                    height: 20rem;
                    width: 100%;
                }
                .header-h1 {
                    width: 98%;
                    padding-left: 10px;
                    transform: translate(0px, 36px);
                    font-size: 36px;
                }

                .header-text {
                    font-size: 20px;
                }

                .contact-h1 {
                    font-size: 26px;
                }

                .icon-text-container {
                    justify-content: start;
                    align-items: start;

                    padding-left: 10px;
                    margin: 0rem;
                    margin-top: 4rem;
                }

                .contact-form {
                    width: 82%;
                    padding: 2rem;
                }

                .logo-thumb {
                    width: 40px;
                    height: 40px;
                }

                .icon-text {
                    gap: 10px;
                }

                .submit-btn {
                    font-size: 16px;
                }

                .text-support {
                    margin: 0;
                    width: 100%;
                    padding-left: 10px;
                }

                .footer-text {
                    width: 100%;
                    flex-direction: column;
                }

                .header-logo {
                    height: 6rem;
                    padding-right: 1rem;
                    align-self: start;
                    padding-left: 1rem;
                }
                .text-container {
                    display: none;
                }

                .contact-footer {
                    flex-direction: column;
                }

                .map {
                    width: 90%;
                    align-self: center;
                }

                .social-media-container {
                    align-self: start;
                    padding-left: 2rem;
                }

                .flex-container {
                    flex-direction: column;
                    gap: 2rem;
                }

                .org-container {
                    margin-top: 0rem;
                    flex-direction: column;
                    align-items: center;
                }
            }
        `;
    }
    get validationFields() {
        var _a;
        return (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelectorAll('.validate');
    }
    /** @override */ render() {
        return html `
            <boost-website-header
                @screen-size-changed="${(event) => (this.smallScreen = event.detail.value)}"
            ></boost-website-header>
            <div class="background-img-container">
                <h1 class="header-h1">
                    Kontakt oss
                    <p class="header-text">
                        Vi er tilgjengelig mandag til fredag 08.00 - 16.00. Skulle det være noe i mellomtiden
                        er vi tilgjengelige på e-post. Ikke nøl med å ta kontakt.
                    </p>
                </h1>
            </div>
            <div class="icon-text-container">
                <div class="icon-text">
                    <img src="images/new-website/boostlogothumb.png" class="logo-thumb" />
                    <h1 class="contact-h1">Send oss en henvendelse</h1>
                </div>
                <p class="text">
                    Er du medlem på et treningssenter og trenger hjelp? Ta kontakt direkte med
                    treningssenteret du tilhører.
                </p>
            </div>

            <div class="contact-form-container">${this._renderForm()}</div>

            <div class="text-wrapper">
                <div class="text-support">
                    <p>Du kan også nå oss på e-post eller telefon:</p>
                    <p>
                        Trenger du hjelp?
                        <a href="mailto:support@boostsystem.no" style="color:white; "
                            >support@boostsystem.no</a
                        >
                    </p>
                    <p>
                        Har du andre spørsmål?
                        <a href="mailto:post@boostsystem.no" style="color:white; ">post@boostsystem.no</a>
                    </p>
                    <p>Telefon: <span>75 19 81 15</span></p>
                </div>
            </div>
            <div class="contact-footer">
                <div class="flex-container">
                    <div class="social-media-container">
                        <div
                            class="social-media-icon-text"
                            @click="${() => window.open('https://www.facebook.com/boostsystem')}"
                        >
                            <img
                                class="social-media-logo"
                                src="images/new-website/facebook.png"
                                alt="instagram-logo"
                            />
                            <p>Følg oss på Facebook</p>
                        </div>
                        <div
                            class="social-media-icon-text"
                            @click="${() => window.open('https://www.instagram.com/boost_system?igsh=MWdrMDZjMWx5Mjg1dg==')}"
                        >
                            <img
                                class="social-media-logo"
                                src="images/new-website/instagram.png"
                                alt="instagram-logo"
                            />
                            <p>Følg oss på Instagram</p>
                        </div>
                    </div>
                    <div class="footer-text">
                        <div class="text-container">
                            <span class="map-span">Besøksadresse</span>
                            <p class="map-text">
                                Skysoft AS <br />
                                Midtre gate 5 <br />
                                8624 Mo i Rana
                            </p>

                            <span class="map-span">Fakturaadresse</span>
                            <p class="map-text">
                                Skysoft AS <br />
                                Postboks 413 <br />
                                8624 Mo i Rana
                            </p>
                        </div>
                        <div class="map">
                            <google-map coordinates="66.3131328,14.127023"></google-map>
                        </div>
                    </div>
                </div>
                <div class="org-container">
                    <p style="color: #d4d4d4; font-size: medium;">
                        &copy; Skysoft AS &mdash; Org. nummer 999 290 396
                    </p>
                    <a
                        style="color: #d4d4d4; text-decoration:none; font-size: medium;"
                        href="/privacypolicy"
                        target="_blank"
                    >
                        Personvernerklæring & bruksvilkår
                    </a>
                </div>
            </div>
        `;
    }
    _renderForm() {
        return html `<div class="contact-form">
            <vaadin-text-field
                class="validate"
                label="Ditt navn"
                error-message="Navn må fylles ut"
                value="${this.input.name}"
                placeholder="Skriv ditt navn..."
                required
                @value-changed="${(event) => (this.input.name = event.detail.value)}"
            ></vaadin-text-field>
            <vaadin-email-field
                class="validate"
                label="Din e-postadresse"
                value="${this.input.mail}"
                placeholder="Skriv din e-postadresse..."
                required
                error-message="E-postadresse må fylles ut"
                @value-changed="${(event) => (this.input.mail = event.detail.value)}"
            ></vaadin-email-field>
            <vaadin-text-field
                class="validate"
                value="${this.input.subject || ''}"
                label="Emne"
                required
                error-message="Emne må fylles ut"
                placeholder="Emne..."
                @value-changed="${(event) => (this.input.subject = event.detail.value)}"
            ></vaadin-text-field>
            <vaadin-text-area
                style="padding-bottom:25px"
                class="validate"
                label="Din henvendelse"
                value="${this.input.message}"
                placeholder="Hva kan vi hjelpe deg med?"
                required
                error-message="Må fylles ut"
                @value-changed="${(event) => (this.input.message = event.detail.value)}"
            ></vaadin-text-area>
            <div style="display:flex; justify-content:center">
                <button class="submit-btn" @click="${() => this._sendRequest()}">Send din henvendelse</button>
            </div>
        </div> `;
    }
    _validateFields() {
        let hasInvalidFields = false;
        this.validationFields.forEach((validationField) => {
            if (!validationField.validate()) {
                hasInvalidFields = true;
            }
        });
        return !hasInvalidFields;
    }
    _sendRequest() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this._validateFields()) {
                return;
            }
            const webContact = yield WebAPI.postV1WebContact({ receiver: 'boost' }, this.input);
            if (isSuccessHTTP(webContact)) {
                SnackBar.push({ title: 'Din forespørsel er sendt', type: 'success' });
                Router.go('/success');
            }
            else if (isErrorHTTP(webContact)) {
                SnackBar.pushError(webContact.error);
            }
        });
    }
};
BoostWebsiteContact._deps = [
    BoostWebsiteHeader,
    BoostWebsiteFooter,
    TextField,
    TextArea,
    EmailField,
    BoostImageContact,
];
__decorate([
    state(),
    __metadata("design:type", Object)
], BoostWebsiteContact.prototype, "input", void 0);
__decorate([
    state(),
    __metadata("design:type", Boolean)
], BoostWebsiteContact.prototype, "smallScreen", void 0);
BoostWebsiteContact = __decorate([
    customElement(BoostWebsiteContact.is)
], BoostWebsiteContact);
export { BoostWebsiteContact };
