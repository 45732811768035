import { SingletonInstanceOf } from 'badphraim/core/Singleton';
import { ServicesBase } from 'base-services/ServicesBase';
import { StandaloneAjax } from './StandaloneAjax';
export class StandaloneServices extends ServicesBase {
    static instance() {
        return SingletonInstanceOf(StandaloneServices);
    }
    static setManifest(m) {
        this.instance()._setManifest(m);
    }
    getAjaxHandler() {
        return StandaloneAjax.instanceForAnyScope();
    }
    /** @override */ getBaseUrl() {
        return this._getBaseUrl();
    }
}
