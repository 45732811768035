var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
let BoostImageAboutUs = class BoostImageAboutUs extends LitElement {
    constructor() {
        super(...arguments);
        this.smallScreen = false;
    }
    static get is() {
        return 'boost-image-about-us';
    }
    static get styles() {
        return css ``;
    }
    /** @override */ render() {
        return html `
            <svg
                xmlns="http://www.w3.org/2000/svg"
                data-name="Layer 1"
                width="400"
                height="400"
                viewBox="0 0 800 600"
                xmlns:xlink="http://www.w3.org/1999/xlink"
            >
                <path
                    d="M788.70874,445.35256a6.6176,6.6176,0,0,0,10.13608.47785l21.56551,9.37924-2.1006-12.03868-20.15693-7.061a6.65349,6.65349,0,0,0-9.44406,9.24262Z"
                    transform="translate(-223.31656 -125.21687)"
                    fill="#a0616a"
                />
                <path
                    d="M808.33018,438.55131l41.19723,5.33928,42.485-25.59256a18.78361,18.78361,0,0,1,26.80668,8.35028h0a18.80382,18.80382,0,0,1-8.4331,24.39255l-57.08925,20.34376-.10746.03844-50.08943-22.31169Z"
                    transform="translate(-223.31656 -125.21687)"
                    fill="#1f477d"
                />
                <polygon
                    points="690.298 579.764 681.54 579.764 677.374 545.984 690.3 545.985 690.298 579.764"
                    fill="#a0616a"
                />
                <path
                    d="M915.848,713.4702l-28.2383-.001V713.112a10.99172,10.99172,0,0,1,10.99113-10.991h.0007l5.1581-3.9132,9.62386,3.9138,2.465.0001Z"
                    transform="translate(-223.31656 -125.21687)"
                    fill="#2f2e41"
                />
                <polygon
                    points="656.36 574.245 647.818 576.174 636.313 544.142 648.921 541.295 656.36 574.245"
                    fill="#a0616a"
                />
                <path
                    d="M883.72548,707.25094l-27.54492,6.21926-.07868-.3484a10.99172,10.99172,0,0,1,8.3001-13.14209l.00068-.00016,4.1694-4.9533,10.2496,1.69774,2.40451-.54289Z"
                    transform="translate(-223.31656 -125.21687)"
                    fill="#2f2e41"
                />
                <path
                    d="M924.70647,498.47061c4.106,12.3837,3.67191,25.82586-.98693,40.253L921.728,619.95178s2.96729,53.26781-6.6076,72.01238H901.23414l-6.87529-76.877-.86335-51.60031-29.77062,43.53325,18.9618,83.68716L865.596,692.23513l-30.76995-90.82,37.964-88.11122Z"
                    transform="translate(-223.31656 -125.21687)"
                    fill="#3d325e"
                />
                <path
                    d="M868.49283,510.44241l3.42446-8.561-2.26477-7.36036-.51563-71.32157-.00028-.05432,8.21752-13.10363.06685-8.288,22.94605-.55964,2.48846,6.31934,4.924,2.9442A35.64637,35.64637,0,0,1,925.08672,441.638l-.88947,48.12063,2.28984,4.58024a9.63783,9.63783,0,0,1-5.67975,13.49028l-50.4935,8.07657Z"
                    transform="translate(-223.31656 -125.21687)"
                    fill="#1f477d"
                />
                <circle cx="663.47615" cy="247.27029" r="20.8488" fill="#a0616a" />
                <path
                    d="M908.5027,377.03213c.62184-2.6197.33635-6.30613-.01506-8.94389a25.03486,25.03486,0,0,0-10.5963-17.38372,7.63326,7.63326,0,0,0-3.80218-1.511,3.28226,3.28226,0,0,0-3.3002,1.99619,6.84189,6.84189,0,0,0-3.8598-3.82756,12.05793,12.05793,0,0,0-5.49444-.74021,20.08039,20.08039,0,0,0-16.75192,11.9747c-.55687,1.32291-2.11229-3.56352-2.81785-2.31357a5.55717,5.55717,0,0,1-3.15143,2.76892c-1.38251.38578-1.944,6.08184-2.4881,4.75361a6.33176,6.33176,0,0,0,6.05342,8.8288,34.87245,34.87245,0,0,0,4.143-.793,10.32927,10.32927,0,0,1,11.3093,7.73074,13.68475,13.68475,0,0,1,1.80033-4.00553,4.63181,4.63181,0,0,1,3.71585-2.04166,5.2038,5.2038,0,0,1,3.57592,2.05552c.91785,1.07772,1.6072,2.3351,2.53182,3.407,2.71306,3.14524,6.38255,10.4801,10.24894,9.94514C902.65525,388.51049,907.65366,380.609,908.5027,377.03213Z"
                    transform="translate(-223.31656 -125.21687)"
                    fill="#2f2e41"
                />
                <path
                    d="M809.07054,500.68736a6.61764,6.61764,0,0,0,9.07527-4.53962l23.39771-2.36389-7.71912-9.474L812.789,488.00707a6.65349,6.65349,0,0,0-3.71842,12.68029Z"
                    transform="translate(-223.31656 -125.21687)"
                    fill="#a0616a"
                />
                <path
                    d="M822.86038,485.16l38.54676-15.48762,24.54475-43.09887a18.78361,18.78361,0,0,1,27.46639-5.82433h0a18.80381,18.80381,0,0,1,4.5716,25.40107L878.13941,491.812l-.07493.08608-54.60267,5.031Z"
                    transform="translate(-223.31656 -125.21687)"
                    fill="#1f477d"
                />
                <path
                    d="M1030.27526,714.66088h-347.988a1.19069,1.19069,0,1,1,0-2.38137h347.988a1.19069,1.19069,0,1,1,0,2.38137Z"
                    transform="translate(-223.31656 -125.21687)"
                    fill="#ccc"
                />
                <path
                    d="M805.38289,521.01774H647.24317a4.16145,4.16145,0,0,1-4.15662-4.15662V452.69488a4.16146,4.16146,0,0,1,4.15662-4.15663H805.38289a4.16139,4.16139,0,0,1,4.15662,4.15663v64.16624A4.16138,4.16138,0,0,1,805.38289,521.01774Z"
                    transform="translate(-223.31656 -125.21687)"
                    fill="#62ee7b"
                />
                <path
                    d="M790.32508,551.79867h-128.024a4.98795,4.98795,0,1,1,0-9.97589h128.024C796.75761,541.733,796.822,551.88927,790.32508,551.79867Z"
                    transform="translate(-223.31656 -125.21687)"
                    fill="#e4e4e4"
                />
                <path
                    d="M790.32508,574.6601h-128.024a4.98795,4.98795,0,1,1,0-9.9759h128.024C796.75761,564.59441,796.822,574.7507,790.32508,574.6601Z"
                    transform="translate(-223.31656 -125.21687)"
                    fill="#e4e4e4"
                />
                <path
                    d="M790.32508,597.52152h-128.024a4.98795,4.98795,0,1,1,0-9.97589h128.024C796.75761,587.45584,796.822,597.61212,790.32508,597.52152Z"
                    transform="translate(-223.31656 -125.21687)"
                    fill="#e4e4e4"
                />

                <circle cx="176.73495" cy="141.33694" r="22.44541" fill="#2f2e41" />
                <path
                    d="M522.86615,482.5485H364.72643a4.16146,4.16146,0,0,1-4.15662-4.15663V414.22563a4.16145,4.16145,0,0,1,4.15662-4.15662H522.86615a4.16139,4.16139,0,0,1,4.15662,4.15662v64.16624A4.16139,4.16139,0,0,1,522.86615,482.5485Z"
                    transform="translate(-223.31656 -125.21687)"
                    fill="#d1d3d4"
                />
                <path
                    d="M507.80833,513.32943h-128.024a4.988,4.988,0,0,1,0-9.9759h128.024C514.24087,503.26374,514.30521,513.42,507.80833,513.32943Z"
                    transform="translate(-223.31656 -125.21687)"
                    fill="#e4e4e4"
                />
                <path
                    d="M507.80833,536.19085h-128.024a4.98795,4.98795,0,1,1,0-9.97589h128.024C514.24087,526.12517,514.30521,536.28145,507.80833,536.19085Z"
                    transform="translate(-223.31656 -125.21687)"
                    fill="#e4e4e4"
                />
                <path
                    d="M507.80833,559.05228h-128.024a4.98795,4.98795,0,0,1,0-9.9759h128.024C514.24087,548.9866,514.30521,559.14288,507.80833,559.05228Z"
                    transform="translate(-223.31656 -125.21687)"
                    fill="#e4e4e4"
                />
                <path
                    d="M518.3203,310.88021a7.5527,7.5527,0,0,1-11.506,1.31694l-56.03547,23.91971,1.47421-13.86917,54.6082-21.17247a7.59361,7.59361,0,0,1,11.45907,9.805Z"
                    transform="translate(-223.31656 -125.21687)"
                    fill="#ffb6b6"
                />
                <path
                    d="M404.26434,309.59186s-6.9281,6.729-.05264,15.38831,40.87031,14.28989,46.32241,15.36146,35.77912-19.55715,35.77912-19.55715l2.93726-14.94511-33.85165,11.614Z"
                    transform="translate(-223.31656 -125.21687)"
                    fill="#6c63ff"
                />
                <path
                    d="M385.65859,360.6475l.54554,10.36527s-1.6102,9.1817-.28592,11.1631c1.31431,1.97149,37.17883,2.22041,37.17883,2.22041s2.36973-12.77463,4.41087-23.95613Z"
                    transform="translate(-223.31656 -125.21687)"
                    fill="#ffb6b6"
                />
                <polygon
                    points="265.457 372.482 272.955 370.968 270.809 340.682 259.743 342.918 265.457 372.482"
                    fill="#ffb6b6"
                />
                <polygon
                    points="276.197 333.07 283.167 329.936 274.125 300.959 263.837 305.586 276.197 333.07"
                    fill="#ffb6b6"
                />
                <path
                    d="M505.58509,441.9601l-13.12456,7.43147-32.368-52.01294c-22.72256,15.30844-44.94235,22.63954-67.34188,26.55157-7.57317,1.34845-15.2945.66509-16.74841-7.04294q-.102-.54051-.16257-1.08705c-.89431-8.41941,9.57876-19.21125,10.91-29.5123l28.663-1.72061,3.53455,4.53182,33.14777-20.37939a14.22944,14.22944,0,0,1,19.58307,4.95118q.32862.54118.61018,1.10917Z"
                    transform="translate(-223.31656 -125.21687)"
                    fill="#2f2e41"
                />
                <path
                    d="M497.55209,476.94969l-15.01776,1.39566L455.30469,422.656c-27.0018,4.64388-52.47556,6.13153-74.50924.51382a14.24211,14.24211,0,0,1-10.18528-17.20508q.12864-.53472.29751-1.0581c2.637-8.04565,9.53976-13.73838,14.97824-22.5875l39.72453.60342-4.437,15.3091,38.58961-4.99362a14.22943,14.22943,0,0,1,15.8303,12.54641q.07779.62835.10166,1.26184Z"
                    transform="translate(-223.31656 -125.21687)"
                    fill="#2f2e41"
                />
                <circle cx="177.85296" cy="149.54605" r="16.13042" fill="#ffb6b6" />
                <path
                    d="M391.71309,296.71644l14.7901,1.74a3.018,3.018,0,0,1,2.59579,2.353l2.35209,10.763,14.66229,17.26667s5.26507,9.87206,3.29069,21.0604S426.562,369.4013,426.562,369.4013s-39.33133,14.747-40.6476,12.77256-1.74344-10.94267-1.74344-10.94267L378.90588,312.657s5.92323-9.87205,9.21392-9.87205C390.24358,302.71331,389.97113,297.93343,391.71309,296.71644Z"
                    transform="translate(-223.31656 -125.21687)"
                    fill="#6c63ff"
                />
                <ellipse cx="203.64529" cy="243.2793" rx="3.75539" ry="4.50647" fill="#6c63ff" />
                <path
                    d="M485.05261,489.90106l11.431-5.72874,2.15445,5.02613,14.45491,2.922a3.41579,3.41579,0,0,1,.6692,6.48775l-13.96637,5.98667-4.5384-3.93972,1.19114,5.37452-5.26588,2.25722Z"
                    transform="translate(-223.31656 -125.21687)"
                    fill="#2f2e41"
                />
                <path
                    d="M496.242,455.87627l11.32849-5.9289,2.24251,4.98747,14.50407,2.6673a3.41578,3.41578,0,0,1,.7832,6.475l-13.85893,6.23139-4.607-3.8593,1.28548,5.35274-5.22537,2.34948Z"
                    transform="translate(-223.31656 -125.21687)"
                    fill="#2f2e41"
                />
                <path
                    d="M391.95364,269.70328a20.64237,20.64237,0,0,1-11.90679-3.75646l-.11641-.08151v-.142a15.56559,15.56559,0,0,1,15.5479-15.5479h6.54649a15.56559,15.56559,0,0,1,15.54791,15.5479,3.62006,3.62006,0,0,1-3.54069,3.60249l-21.71054.37426C392.199,269.70221,392.07591,269.70328,391.95364,269.70328Z"
                    transform="translate(-223.31656 -125.21687)"
                    fill="#2f2e41"
                />
                <path
                    d="M456.88021,395.17611a7.55265,7.55265,0,0,1-8.22151-8.15655l-53.70233-28.77824,11.74595-7.52062,50.66588,29.38193a7.59361,7.59361,0,0,1-.488,15.07348Z"
                    transform="translate(-223.31656 -125.21687)"
                    fill="#ffb6b6"
                />
                <path
                    d="M386.5765,305.35513s-9.58323-1.2-12.04279,9.57987,14.4,40.83164,16.97242,45.75671,37.633,15.69661,37.633,15.69661l13.5004-7.05147L412.41086,350.1784Z"
                    transform="translate(-223.31656 -125.21687)"
                    fill="#6c63ff"
                />
                <path
                    d="M403.66608,252.57923c1.50926-1.85063.32945-4.59164-.90821-6.63391q-4.25372-7.01907-8.92988-13.77023c-3.89552-5.62391-8.61563-11.5035-15.32276-12.85178-6.11687-1.22961-12.27907,1.67716-17.54144,5.02911-5.17385,3.29557-10.26964,7.44685-12.23887,13.25646-1.88453,5.55973-.561,11.7338,1.78383,17.11563,2.00823,4.60934,4.71,10.12111,1.84595,14.25346a11.05671,11.05671,0,0,1-5.29648,3.60469,37.8325,37.8325,0,0,1-16.62932,2.45369c7.76709-.0835,14.56583,7.89179,22.09541,5.98389,4.26985-1.08192,7.11355-5.06266,9.15993-8.96324a25.11031,25.11031,0,0,0,2.98931-8.466c.49749-4.04034-.57852-8.292.7799-12.12949,1.41377-3.99388,5.17214-6.66612,8.96571-8.55255,2.51875-1.25249,5.26467-2.29852,8.06961-2.0862,7.95119.60184,12.06933,10.38851,19.61418,12.969"
                    transform="translate(-223.31656 -125.21687)"
                    fill="#2f2e41"
                />
                <path
                    d="M673.90537,307.19618H515.76565a4.16145,4.16145,0,0,1-4.15662-4.15663V238.87331a4.16145,4.16145,0,0,1,4.15662-4.15662H673.90537a4.16139,4.16139,0,0,1,4.15662,4.15662v64.16624A4.16139,4.16139,0,0,1,673.90537,307.19618Z"
                    transform="translate(-223.31656 -125.21687)"
                    fill="#62ee7b"
                />
                <path
                    d="M658.84755,337.97711h-128.024a4.98795,4.98795,0,0,1,0-9.9759h128.024C665.28009,327.91142,665.34443,338.06771,658.84755,337.97711Z"
                    transform="translate(-223.31656 -125.21687)"
                    fill="#e4e4e4"
                />
                <path
                    d="M658.84755,360.83853h-128.024a4.98795,4.98795,0,1,1,0-9.97589h128.024C665.28009,350.77285,665.34443,360.92913,658.84755,360.83853Z"
                    transform="translate(-223.31656 -125.21687)"
                    fill="#e4e4e4"
                />
                <path
                    d="M658.84755,383.7h-128.024a4.98795,4.98795,0,0,1,0-9.9759h128.024C665.28009,373.63428,665.34443,383.79056,658.84755,383.7Z"
                    transform="translate(-223.31656 -125.21687)"
                    fill="#e4e4e4"
                />
            </svg>
        `;
    }
};
BoostImageAboutUs._deps = [];
__decorate([
    property({ type: Boolean }),
    __metadata("design:type", Boolean)
], BoostImageAboutUs.prototype, "smallScreen", void 0);
BoostImageAboutUs = __decorate([
    customElement(BoostImageAboutUs.is)
], BoostImageAboutUs);
export { BoostImageAboutUs };
