var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { BoostImageProfileIcon } from '../../website-images/BoostImageProfileIcon';
import { BoostWebsiteMailIcon } from '../../website-images/BoostWebsiteMailIcon';
import { BoostWebsitePhoneIcon } from '../../website-images/BoostWebsitePhoneIcon';
import { BoostImageAboutUs } from '../../website-images/BoostImageAboutUs';
import { BoostWebsiteFooter } from '../components/BoostWebsiteFooter';
import { BoostWebsiteHeader } from '../components/BoostWebsiteHeader';
let BoostWebsiteAboutUs = class BoostWebsiteAboutUs extends LitElement {
    constructor() {
        super(...arguments);
        this.smallScreen = false;
    }
    static get is() {
        return 'boost-website-about-us';
    }
    static get styles() {
        return css `
            .background-img-container {
                height: 39rem;
                background: url('images/new-website/havmannen2.jpg');
                background-size: cover;
                background-position: center;
                margin-top: 8rem;
            }

            .header-h1 {
                color: #ffffff;
                transform: translate(0px, 10rem);
                width: 50%;
                padding-left: 6rem;
                line-height: 1.4;
                font-size: 50px;
                font-weight: normal;
            }

            .header-text {
                font-size: 36px;
                color: #ffffff;
                width: 88%;
                font-weight: lighter;
            }

            .text-img-container {
                display: flex;
                flex-direction: row;
                width: 100%;
                justify-content: space-evenly;
                max-width: 86rem;
                margin: 8rem auto;
                align-items: center;
            }

            .text-container {
                display: flex;
                flex-direction: column;
                width: 30%;
            }

            .about-us-img {
                width: 31%;
                height: 48rem;
                object-fit: cover;
            }

            .logo-thumb {
                width: 60px;
                height: 60px;
            }

            .text-h1 {
                font-weight: lighter;
                font-size: 36px;
                margin: 0;
                color: #fff;
            }

            .icon-text-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 20px;
            }

            @media screen and (max-width: 1000px) {
                .background-img-container {
                    background-repeat: no-repeat;
                    margin-top: 7rem;
                    background-size: cover;
                    height: 20rem;
                    width: 100%;
                }
                .header-h1 {
                    width: 98%;
                    padding-left: 10px;
                    transform: translate(0px, 8rem);
                    font-size: 36px;
                }

                .header-text {
                    font-size: 20px;
                }

                .text-img-container {
                    flex-direction: column;
                    margin-top: 2rem;
                }

                .text-container {
                    width: 97%;
                    padding-left: 10px;
                }

                .about-us-img {
                    margin: 0 auto;
                    border-radius: 8px;
                    width: 94%;
                    height: 37rem;
                }
            }
        `;
    }
    /** @override */ render() {
        return html `
            <boost-website-header
                @screen-size-changed="${(event) => (this.smallScreen = event.detail.value)}"
            ></boost-website-header>
            <div class="background-img-container">
                <h1 class="header-h1">
                    Om oss
                    <p class="header-text">
                        Vi holder til i Mo i Rana og sammen med Havmannen har vi orkesterplass til Ranfjorden
                        i all slags vær
                    </p>
                </h1>
            </div>
            <div class="text-img-container">
                <div class="text-container">
                    <div class="icon-text-container">
                        <img class="logo-thumb" src="images/new-website/boostlogothumb.png" />
                        <h1 class="text-h1">Vår historie</h1>
                    </div>
                    <p style="margin-top:4rem;">
                        Cerum AS ble grunnlagt i år 2000 og var tidlig i gang i Norge med å tilby skybaserte
                        forretningssystemer.<br /><br />
                        Vi har levert både generelle systemer som passer for de fleste bedrifter samt
                        bransjetilpassede systemer. <br /><br />
                        Opp gjennom årene har vi hatt flere ulike bransjer vi har levert til, men de siste 15
                        årene har vi jobbet mest sammen med entreprenørbransjen, sveiseindustrien, telecom og
                        sist, men ikke minst mot treningssenterbransjen. <br /><br />
                        Vi startet vår satsning mot treningssenterne så tidlig som i 2006. Vi så at mange av
                        våre funksjoner passet godt i et forretningssystem tilpasset denne bransjen. I 2011
                        skilte vi dette ut i et eget selskap, Skysoft AS, for å kunne fokusere helt og holdent
                        på dette.<br /><br />
                        Sammen med vår “søster”, Cerum AS, jobber vi nå sammen for å utvikle våre konsepter
                        videre. Vår søsterbedrift Cerum AS har nå lansert sin satsning på renholdsbransjen. En
                        bransje i stor utvikling!<br /><br />
                        Cerum AS kan du lese mer om her:
                        <a href="https://cerum.no/" style="color:white">https://cerum.no/</a>
                    </p>
                </div>
                <img class="about-us-img" src="images/new-website/boat-image.jpg" />
            </div>
            <boost-website-footer></boost-website-footer>
        `;
    }
};
BoostWebsiteAboutUs._deps = [
    BoostWebsiteHeader,
    BoostImageProfileIcon,
    BoostWebsiteMailIcon,
    BoostWebsitePhoneIcon,
    BoostImageAboutUs,
    BoostWebsiteFooter,
];
__decorate([
    property({ type: Boolean }),
    __metadata("design:type", Boolean)
], BoostWebsiteAboutUs.prototype, "smallScreen", void 0);
BoostWebsiteAboutUs = __decorate([
    customElement(BoostWebsiteAboutUs.is)
], BoostWebsiteAboutUs);
export { BoostWebsiteAboutUs };
