var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Router } from '@vaadin/router';
import { css, html, LitElement } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { BoostWebsiteLogInIcon } from '../../website-images/BoostWebsiteLogInIcon';
import { BoostWebsiteMailIcon } from '../../website-images/BoostWebsiteMailIcon';
import { BoostWebsiteProfilePictureIcon } from '../../website-images/BoostWebsiteProfilePictureIcon';
let BoostWebsiteHeader = class BoostWebsiteHeader extends LitElement {
    constructor() {
        super(...arguments);
        this.smallScreen = false;
        this.dropdownIsVisible = false;
        this.isActiveLink = false;
        this._showDropdownMenu = () => {
            return html ` <div class="navbar-smallscreen-container">
            <div class="header">
                <img
                    class="header-logo"
                    @click="${() => Router.go('#')}"
                    src="/images/new-website/boostlogo.png"
                    alt="LOGO"
                />
            </div>
            <div style="display:flex; flex-direction:column; padding-top:7rem; min-height:100vh; ">
                <div class="navbar-smallscreen" @click="${() => this._mobileViewAboutUsLink()}">
                    <img class="boost-logo-thumb" src="images/new-website/boostlogothumb.png" />
                    <p style="padding-left:15px;margin-top: 20px;">Om oss</p>
                </div>
                <div class="navbar-smallscreen" @click="${() => this._mobileViewContactLink()}">
                    <img class="boost-logo-thumb" src="images/new-website/boostlogothumb.png" />
                    <p style="padding-left:15px;margin-top: 20px;">Kontakt oss</p>
                </div>
                <div class="navbar-smallscreen" @click="${() => this._mobileViewSupportLink()}">
                    <img class="boost-logo-thumb" src="images/new-website/boostlogothumb.png" />
                    <p style="padding-left:15px;margin-top: 20px;">Ofte stilte spørsmål</p>
                </div>
                <div class="navbar-smallscreen" @click="${() => this._mobileViewPartnersLink()}">
                    <img class="boost-logo-thumb" src="images/new-website/boostlogothumb.png" />
                    <p style="padding-left:15px;margin-top: 20px;">Våre partnere</p>
                </div>
                <div class="navbar-smallscreen" @click="${() => this._mobileViewAppLink()}">
                    <img class="boost-logo-thumb" src="images/new-website/boostlogothumb.png" />
                    <p style="padding-left:15px;margin-top: 20px;">Medlemsapp</p>
                </div>
                <div class="navbar-smallscreen" @click="${() => this._mobileViewHomeLink()}">
                    <img class="boost-logo-thumb" src="images/new-website/boostlogothumb.png" />
                    <p style="padding-left:15px;margin-top: 20px;">Hjem</p>
                </div>
                <div
                    class="navbar-smallscreen"
                    @click="${() => window.location.assign('https://cerum.no/login/boost')}"
                >
                    <boost-website-log-in-icon></boost-website-log-in-icon>
                    <p style="padding-left:15px;margin-top: 20px;">Logg inn</p>
                </div>
            </div>
        </div>`;
        };
        this._dropdownMenuChanged = () => {
            this.dropdownIsVisible = !this.dropdownIsVisible;
            if (this.smallScreen && document.body.classList.contains('overflow-hidden')) {
                document.body.classList.remove('overflow-hidden');
            }
            else {
                document.body.classList.add('overflow-hidden');
            }
        };
    }
    static get is() {
        return 'boost-website-header';
    }
    static get styles() {
        return css `
            .header {
                height: 7rem;
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                align-items: center;
                position: fixed;
                top: 0;
                background-color: rgba(19, 44, 79, 0.8);
                z-index: 999;
                transition: transform 0.3s ease-in-out;
            }

            .header-link-container {
                height: 3rem;
                width: 50%;
                border-radius: 50px;
                background-color: #ffffff;
                display: flex;
                justify-content: space-around;
                align-items: center;
            }
            .header-link {
                text-decoration: none;
                color: #132c4f;
                cursor: pointer;
                width: 20%;
                text-align: center;
                font-size: 16px;
            }

            .active-link {
                background-color: #2dfe53;
                height: 3rem;
                border-radius: 50px;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .header-logo {
                width: auto;
                height: 4rem;
                cursor: pointer;
            }

            .log-in-btn {
                height: 3rem;
                width: 10rem;
                background-color: #2dfe53;
                border-radius: 50px;
                border: none;
                color: #132c4f;
                font-size: 16px;
                cursor: pointer;
            }

            .bar {
                display: flex;
                width: 30px;
                height: 4px;
                margin-top: 3px;
                background-color: white;
                border-radius: 6px;
            }

            .hamburger {
                z-index: 4;
                align-self: center;
                cursor: pointer;
                transition:
                    width 2s,
                    height 2s,
                    transform 2s;
            }

            img:hover {
                cursor: pointer;
                opacity: 0.8;
            }

            .close {
                position: absolute;
                right: 32px;
                top: 40px;
                width: 40px;
                height: 32px;
                border-radius: 6px;
                opacity: 0.8;
                z-index: 5;
            }

            .close:hover {
                opacity: 1;
            }

            .close:before,
            .close:after {
                position: absolute;
                left: 15px;
                content: ' ';
                height: 33px;
                width: 4px;
                background-color: white;
                border-radius: 6px;
                border-radius: 2px;
            }

            .close:before {
                transform: rotate(45deg);
            }

            .close:after {
                transform: rotate(-45deg);
            }

            .hideHeader {
                transform: translateY(-100%);
            }

            .showHeader {
                transform: translateY(0);
            }

            @media screen and (max-width: 1500px) {
                .hamburger {
                    padding: 10%;
                }

                .navbar-smallscreen {
                    color: #fefefe;
                    font-size: 25px;
                    padding: 10px 0px 0px 35px;
                    background-color: #204373;
                    margin: 10px;
                    border-radius: 6px;
                    align-items: center;
                    display: flex;

                    font-weight: 300;
                }

                .navbar-smallscreen:hover {
                    cursor: pointer;
                }

                .navbar-smallscreen-container {
                    max-height: 100vh;

                    overflow: auto;
                    background-color: #132c4f;
                    display: flex;
                    flex-direction: column;
                    z-index: 4;
                    width: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                }

                .header-logo {
                    padding-left: 2rem;
                }

                .header {
                    justify-content: space-between;
                }

                .boost-logo-thumb {
                    width: 24px;
                    height: 24px;
                }
            }

            @media screen and (max-width: 2000px) {
                .show-log-in-btn {
                    display: flex;
                    flex-direction: row;
                    gap: 5rem;
                }
            }

            @media screen and (max-width: 550px) {
                .log-in-btn {
                    display: none;
                }
            }

            @media screen and (max-width: 1200px) {
                .hamburger {
                    padding: 10%;
                }

                .navbar-smallscreen {
                    color: #fefefe;
                    font-size: 25px;
                    padding: 10px 0px 0px 35px;
                    background-color: #204373;
                    margin: 10px;
                    border-radius: 6px;
                    align-items: center;
                    display: flex;

                    font-weight: 300;
                }

                .navbar-smallscreen:hover {
                    cursor: pointer;
                }

                .navbar-smallscreen-container {
                    max-height: 100vh;

                    overflow: auto;
                    background-color: #132c4f;
                    display: flex;
                    flex-direction: column;
                    z-index: 4;
                    width: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                }

                .header-logo {
                    padding-left: 2rem;
                }

                .header {
                    justify-content: space-between;
                }

                .boost-logo-thumb {
                    width: 24px;
                    height: 24px;
                }
            }
        `;
    }
    connectedCallback() {
        super.connectedCallback();
        this._hideHeaderOnScroll();
        window.addEventListener('resize', () => this._sizeHandler());
        this._sizeHandler();
        this._locationChangedHandler();
    }
    /** @override */ render() {
        return html `
            <div class="header">
                <img
                    class="header-logo"
                    src="images/new-website/boostlogo.png"
                    alt="Boost-logo"
                    @click="${() => Router.go('#')}"
                />
                ${!this.smallScreen
            ? html ` <div class="header-link-container">
                              <div
                                  class="header-link"
                                  data-path="about"
                                  @click="${() => Router.go('/about')}"
                              >
                                  Om oss
                              </div>
                              <div
                                  class="header-link"
                                  data-path="contact"
                                  @click="${() => Router.go('/contact')}"
                              >
                                  Kontakt oss
                              </div>
                              <div
                                  class="header-link"
                                  data-path="support"
                                  @click="${() => Router.go('/support')}"
                              >
                                  Ofte stilte spørsmål
                              </div>
                              <div
                                  class="header-link"
                                  data-path="partners"
                                  @click="${() => Router.go('/partners')}"
                              >
                                  Våre partnere
                              </div>
                              <div class="header-link" data-path="app" @click="${() => Router.go('/app')}">
                                  Medlemsapp
                              </div>
                          </div>
                          <button
                              class="log-in-btn"
                              @click="${() => window.location.assign('https://cerum.no/login/boost')}"
                          >
                              Logg inn
                          </button>`
            : html ` <div class="show-log-in-btn">
                          <button
                              class="log-in-btn"
                              @click="${() => window.location.assign('https://cerum.no/login/boost')}"
                          >
                              Logg inn
                          </button>
                          <div
                              style="display: flex;flex-direction: column;align-self: center;padding-right: 35px;"
                          >
                              ${this.dropdownIsVisible
                ? html `<div class="close " @click="${this._dropdownMenuChanged}">
                                        <span class="bar1" id="bar1"></span>
                                        <span class="bar2" id="bar2"></span>
                                    </div>`
                : html `<div @click="${this._dropdownMenuChanged}" class="hamburger">
                                        <span class="bar"></span>
                                        <span class="bar"></span>
                                        <span class="bar"></span>
                                    </div>`}
                              ${this.dropdownIsVisible ? this._showDropdownMenu() : ''}
                          </div>
                      </div>`}
            </div>
        `;
    }
    _setActiveLink() {
        var _a;
        const path = window.location.pathname.slice(1);
        const headerLinks = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelectorAll('.header-link');
        headerLinks === null || headerLinks === void 0 ? void 0 : headerLinks.forEach((link) => {
            link.classList.remove('active-link');
            const linkPath = link.getAttribute('data-path');
            if (linkPath === path) {
                link.classList.add('active-link');
            }
        });
    }
    _hideHeaderOnScroll() {
        let prevScrollPos = window.scrollY;
        window.addEventListener('scroll', () => {
            var _a;
            const currentScrollPos = window.scrollY;
            const header = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('.header');
            if (!header)
                return;
            if (currentScrollPos > 100) {
                if (prevScrollPos > currentScrollPos) {
                    header.classList.remove('hideHeader');
                    header.classList.add('showHeader');
                }
                else {
                    header.classList.remove('showHeader');
                    header.classList.add('hideHeader');
                }
            }
            else {
                header.classList.remove('hideHeader');
                header.classList.add('showHeader');
            }
            prevScrollPos = currentScrollPos;
        });
    }
    _sizeHandler() {
        const width = window.innerWidth;
        this.updateComplete.then(() => {
            if (width < 1400 || /Mobi|Android/i.test(navigator.userAgent)) {
                this.smallScreen = true;
            }
            else {
                this.smallScreen = false;
            }
        });
    }
    _locationChangedHandler() {
        window.addEventListener('vaadin-router-location-changed', () => {
            window.scrollTo(0, 0);
        });
    }
    _mobileViewAboutUsLink() {
        document.body.classList.remove('overflow-hidden');
        Router.go('/about');
    }
    _mobileViewHomeLink() {
        document.body.classList.remove('overflow-hidden');
        Router.go('/');
    }
    _mobileViewContactLink() {
        document.body.classList.remove('overflow-hidden');
        Router.go('/contact');
    }
    _mobileViewSupportLink() {
        document.body.classList.remove('overflow-hidden');
        Router.go('/support');
    }
    _mobileViewPartnersLink() {
        document.body.classList.remove('overflow-hidden');
        Router.go('/partners');
    }
    _mobileViewAppLink() {
        document.body.classList.remove('overflow-hidden');
        Router.go('/app');
    }
    /** @override */ updated(changedProperties) {
        changedProperties.forEach((_oldValue, propertyName) => {
            switch (propertyName) {
                case 'smallScreen':
                    this._smallScreenObserver();
                    break;
                case 'isActiveLink':
                    this._setActiveLink();
                    break;
            }
        });
        super.updated(changedProperties);
    }
    _smallScreenObserver() {
        this.dispatchEvent(new CustomEvent('screen-size-changed', {
            detail: { value: this.smallScreen },
            bubbles: true,
            composed: true,
        }));
    }
};
BoostWebsiteHeader._deps = [BoostWebsiteMailIcon, BoostWebsiteProfilePictureIcon, BoostWebsiteLogInIcon];
__decorate([
    state(),
    __metadata("design:type", Boolean)
], BoostWebsiteHeader.prototype, "smallScreen", void 0);
__decorate([
    state(),
    __metadata("design:type", Boolean)
], BoostWebsiteHeader.prototype, "dropdownIsVisible", void 0);
__decorate([
    state(),
    __metadata("design:type", Boolean)
], BoostWebsiteHeader.prototype, "isActiveLink", void 0);
BoostWebsiteHeader = __decorate([
    customElement(BoostWebsiteHeader.is)
], BoostWebsiteHeader);
export { BoostWebsiteHeader };
