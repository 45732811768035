export class AjaxDelegate {
    constructor() {
        /** @override */ this.nospinner = false;
    }
    /** @override */ dominserted(_node) {
        /* void */
    }
    /** @override */ error(_x) {
        /* void */
    }
    /** @override */ load(_str) {
        /* void */
    }
    /** @override */ loaded(_str) {
        /* void */
    }
    /** @override */ readyStateChange(_ev) {
        /* void */
    }
}
