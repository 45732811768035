var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Router } from '@vaadin/router';
import { css, html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
let BoostWebsiteFooter = class BoostWebsiteFooter extends LitElement {
    static get is() {
        return 'boost-website-footer';
    }
    static get styles() {
        return css `
            h1 {
                color: #ffffff;
            }

            p {
                color: #ffffff;
            }

            .thumb {
                height: 40px;
                width: 40px;
            }

            .icon-text-container {
                display: flex;
                flex-direction: row-reverse;
                align-items: center;
                gap: 20px;
                justify-content: start;
            }

            .text-container {
                display: flex;
                flex-direction: column;
            }

            .text-wrapper {
                display: flex;
                flex-direction: column;
                line-height: 1;
            }

            .icon-text {
                display: flex;
                flex-direction: row;
                gap: 20px;
                align-items: center;
                width: 100%;
            }

            .text-support {
                width: 53%;
                margin: 0 auto;
            }

            .footer-text {
                padding-bottom: 1rem;
                display: flex;
                flex-direction: column;
                background-color: #204373;
                margin-top: 6rem;
                padding-top: 2rem;
            }

            .function-footer {
                padding-bottom: 1rem;
                display: flex;
                flex-direction: column;
                background-color: #204373;
                margin-top: 6rem;
                padding-top: 2rem;
            }

            .support-footer {
                padding-bottom: 1rem;
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 6rem;
            }

            .text {
                color: #ffff;
                margin-top: 0;
                width: 18rem;
            }

            .contact-form-container {
                display: flex;
                justify-content: center;
            }

            .contact-footer {
                display: flex;
                flex-direction: row;
                margin-top: 4rem;
                width: 100%;
            }

            .header-logo {
                width: auto;
                height: 9rem;
                align-self: center;
                position: relative;
                transform: translate(1rem, 6rem);
            }

            .map-text {
                font-size: 16px;
            }

            .contact-btn {
                height: 3rem;
                width: 14rem;
                background-color: #2dfe53;
                border-radius: 50px;
                border: none;
                color: #132c4f;
                cursor: pointer;
                font-size: 16px;
            }

            .flex-container {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
            }

            .social-media-logo {
                width: 40px;
                height: 40px;
            }

            .social-media-icon-text {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 2rem;
                cursor: pointer;
            }

            .partner-footer {
                background-color: #132c4f;
                justify-content: space-evenly;
                padding-bottom: 4rem;
                display: flex;
                flex-direction: column;
                margin-top: 6rem;
                padding-top: 2rem;
                align-items: center;
            }

            .contact-sm-container {
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                align-items: center;
                width: 100%;
            }

            .org-container {
                justify-content: space-evenly;
                display: flex;
                margin-top: 3rem;
                width: 100%;
            }

            @media screen and (max-width: 1200px) {
                .map-container {
                    flex-direction: column;
                    margin-top: 4rem;
                    margin-bottom: 0rem;
                }

                .contact-container {
                    width: 90%;
                    padding-left: 2rem;
                }

                .contact-h1 {
                    font-size: 36px;
                }

                .contact-btn {
                    width: 13rem;
                    height: 2.5rem;
                }

                .map-text-container {
                    flex-direction: column-reverse;
                    width: 90%;
                    gap: 10px;
                    align-items: start;
                    padding-left: 23px;
                    margin-top: 2rem;
                }

                .map {
                    width: 100%;
                }

                .footer-text {
                    flex-direction: column;
                    width: 100%;
                    gap: 2rem;
                    padding-bottom: 2rem;
                }

                .header-logo {
                    height: 3rem;
                    transform: translate(0rem, 8.5rem);
                }

                .icon-text-container {
                    justify-content: space-between;
                }

                .text {
                    font-size: 16px;
                }

                .thumb {
                    display: none;
                }

                .social-media-container {
                    display: flex;
                    flex-direction: column;
                    align-self: start;
                    padding-left: 2rem;
                }

                .support-footer {
                    gap: 4rem;
                    display: flex;
                    flex-direction: column;
                    margin-top: 2rem;
                    background-color: #132c4f;
                }

                .contact-sm-container {
                    flex-direction: column;
                    gap: 3rem;
                }

                .org-container {
                    flex-direction: column;
                    align-items: center;
                    margin-top: 0rem;
                }

                .flex-container {
                    gap: 2rem;
                }

                .partner-footer {
                    margin-top: 0rem;
                    flex-direction: column;
                    gap: 2rem;
                }

                .function-footer {
                    background-color: #132c4f;
                    margin-top: 0rem;
                    gap: 2rem;
                }
            }
        `;
    }
    /** @override */ render() {
        return html `
            <footer class="footer-text">
                <div class="contact-sm-container">
                    <div class="contact-container">
                        <div class="flex-container">
                            <div class="icon-text-container">
                                <h1 class="contact-h1">Ta kontakt</h1>
                            </div>
                        </div>
                        <p class="text">Ta gjerne kontakt med oss for en hyggelig, uforpliktende prat.</p>
                        <button class="contact-btn" @click="${() => Router.go('/contact')}">
                            Kontakt oss
                        </button>
                    </div>
                    <div class="social-media-container">
                        <div
                            class="social-media-icon-text"
                            @click="${() => window.open('https://www.facebook.com/boostsystem')}"
                        >
                            <img
                                class="social-media-logo"
                                src="images/new-website/facebook.png"
                                alt="instagram-logo"
                            />
                            <p>Følg oss på Facebook</p>
                        </div>
                        <div
                            class="social-media-icon-text"
                            @click="${() => window.open('https://www.instagram.com/boost_system?igsh=MWdrMDZjMWx5Mjg1dg==')}"
                        >
                            <img
                                class="social-media-logo"
                                src="images/new-website/instagram.png"
                                alt="instagram-logo"
                            />
                            <p>Følg oss på Instagram</p>
                        </div>
                    </div>
                </div>
                <div class="org-container">
                    <p style="font-size: medium; color: #d4d4d4">
                        &copy; Skysoft AS &mdash; Org. nummer 999 290 396
                    </p>
                    <a
                        style="color: #d4d4d4; text-decoration:none; font-size: medium;"
                        href="/privacypolicy"
                        target="_blank"
                    >
                        Personvernerklæring & bruksvilkår
                    </a>
                </div>
            </footer>
        `;
    }
    _showDifferentStyle() {
        const path = window.location.pathname;
        window.addEventListener('scroll', () => {
            var _a;
            const footer = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('.footer-text');
            if (path === '/support') {
                footer === null || footer === void 0 ? void 0 : footer.classList.remove('footer-text');
                footer === null || footer === void 0 ? void 0 : footer.classList.add('support-footer');
            }
            if (path === '/partners') {
                footer === null || footer === void 0 ? void 0 : footer.classList.remove('footer-text');
                footer === null || footer === void 0 ? void 0 : footer.classList.add('partner-footer');
            }
            if (path === '/functions') {
                footer === null || footer === void 0 ? void 0 : footer.classList.remove('footer-text');
                footer === null || footer === void 0 ? void 0 : footer.classList.add('function-footer');
            }
            return;
        });
    }
    connectedCallback() {
        super.connectedCallback();
        this._showDifferentStyle();
    }
};
BoostWebsiteFooter._deps = [];
BoostWebsiteFooter = __decorate([
    customElement(BoostWebsiteFooter.is)
], BoostWebsiteFooter);
export { BoostWebsiteFooter };
