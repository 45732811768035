var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { css, html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
import { FrontPage } from './pages/FrontPage';
let BoostWebsite = class BoostWebsite extends LitElement {
    static get is() {
        return 'boost-website';
    }
    static get styles() {
        return css ``;
    }
    constructor() {
        super();
    }
    /** @override */ render() {
        return html `<front-page></front-page>`;
    }
    createRenderRoot() {
        return this;
    }
};
BoostWebsite._deps = [FrontPage];
BoostWebsite = __decorate([
    customElement(BoostWebsite.is),
    __metadata("design:paramtypes", [])
], BoostWebsite);
export { BoostWebsite };
