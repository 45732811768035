import { GeneratorHelpers } from 'badphraim/data/GeneratorHelpers';
class GenerateableBaseImpl {
    /** @override */ arrayOf() {
        return GeneratorHelpers.instance().wrapAsArray(this);
    }
    /** @override */ hashMapOf() {
        return GeneratorHelpers.instance().wrapAsHashMap(this);
    }
}
export class NativeType {
    Blob() {
        return new (class extends GenerateableBaseImpl {
            /** @override */ generate(data) {
                if (data instanceof Blob) {
                    return data;
                }
                throw new Error('Fikk ikke inn en blob som forventet');
            }
        })();
    }
    boolean() {
        return new (class extends GenerateableBaseImpl {
            /** @override */ generate(data) {
                return Boolean(data);
            }
        })();
    }
    never() {
        throw new Error("Can't instanciate.");
    }
    number() {
        return new (class extends GenerateableBaseImpl {
            /** @override */ generate(data) {
                return parseInt(String(data), 10);
            }
        })();
    }
    string() {
        return new (class extends GenerateableBaseImpl {
            /** @override */ generate(data) {
                return String(data);
            }
        })();
    }
}
