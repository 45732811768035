var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
let BoostWebsiteImageLocation = class BoostWebsiteImageLocation extends LitElement {
    constructor() {
        super(...arguments);
        this.smallScreen = false;
    }
    static get is() {
        return 'boost-website-image-location';
    }
    static get styles() {
        return css ``;
    }
    /** @override */ render() {
        return html `
            ${!this.smallScreen
            ? html `<svg
                      xmlns="http://www.w3.org/2000/svg"
                      data-name="Layer 1"
                      width="500"
                      height="350"
                      viewBox="0 0 1000 1000"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      transform="translate(-50,30)"
                  >
                      <polygon
                          points="534.25 438.618 418.25 300.618 185.404 302.746 44.156 440.325 47.01 442.159 45.991 442.159 45.991 728.324 533.937 728.324 533.937 442.159 534.25 438.618"
                          fill="#3f3d56"
                      />
                      <polygon
                          points="418.371 300.912 273.455 470.811 273.455 728.324 533.937 728.324 533.937 438.491 418.371 300.912"
                          fill="#28e04d"
                      />
                      <rect x="378.01459" y="585.91537" width="53.19723" height="47.02019" fill="#fff" />
                      <rect x="378.01459" y="504.52856" width="53.19723" height="46.27453" fill="#fff" />
                      <rect x="141.60242" y="573.43886" width="53.19723" height="47.02019" fill="#fff" />
                      <rect x="141.60242" y="492.05205" width="53.19723" height="46.27453" fill="#fff" />
                      <rect y="727.43053" width="840.5" height="2" fill="#3f3d56" />
                      <polygon
                          points="713.92 695.831 717.92 715.831 697.92 719.831 700.92 694.831 713.92 695.831"
                          fill="#a0616a"
                      />
                      <polygon
                          points="776.92 691.831 771.92 716.831 747.92 716.831 757.92 686.831 776.92 691.831"
                          fill="#a0616a"
                      />
                      <path
                          d="M978.66929,588.91557l4,27-5,59-4,22-17,72s-19,0-22-8l5-41s4-33,3-37-16-68-16-68-22,101-25,110-7,48-7,48-17,3-18,0-2-74-2-85,2-17,2-19-7.8786-97.47192-7.8786-97.47192Z"
                          transform="translate(-179.75 -71.08443)"
                          fill="#2f2e41"
                      />
                      <path
                          d="M937.66929,782.91557s5,1,8,0,0-7,0-7h11s12,27,17,29,21,24,2,24a62.08559,62.08559,0,0,1-33-10s-2-9-11-10-15-12-15-12l13-24S932.66929,783.91557,937.66929,782.91557Z"
                          transform="translate(-179.75 -71.08443)"
                          fill="#2f2e41"
                      />
                      <path
                          d="M890.66929,781.91557s-2,4-4,2-4-7-4-7-6-3-7,0-5,19-7,21-23,23-2,27,25-5,25-5-3-5,3-7,7-9,7-9l-4-30S893.66929,783.91557,890.66929,781.91557Z"
                          transform="translate(-179.75 -71.08443)"
                          fill="#2f2e41"
                      />
                      <path
                          d="M1001.66929,518.91557l3,52s14,33-5,32-7-33-7-33l-18.60235-46.40942Z"
                          transform="translate(-179.75 -71.08443)"
                          fill="#a0616a"
                      />
                      <path
                          d="M847.66929,503.91557l-3,52s-14,33,5,32,7-33,7-33l18.60236-46.40942Z"
                          transform="translate(-179.75 -71.08443)"
                          fill="#a0616a"
                      />
                      <circle cx="747.91927" cy="293.83115" r="26" fill="#a0616a" />
                      <path
                          d="M948.16929,375.41557s-7,28,7,35-44,17-48,0l2-8s8-10,6-19Z"
                          transform="translate(-179.75 -71.08443)"
                          fill="#a0616a"
                      />
                      <path
                          d="M932.66929,410.91557s-17.25519,1.61145-22.62756-9.69427c0,0-40.37244,2.69427-49.37244,39.69427l15,96s-16,28-14,31c0,0-10,28,82,29l40-2a55.64622,55.64622,0,0,0-1-17c-2-9-13-72-13-72s30-47,27-54-7.80438-34.60076-46.40222-45.80038C950.26707,406.11519,949.66929,411.91557,932.66929,410.91557Z"
                          transform="translate(-179.75 -71.08443)"
                          fill="#e6e6e6"
                      />
                      <path
                          d="M983.66929,434.91557l13,17s13,71,9,72-28,9-32,6-22-62-22-62Z"
                          transform="translate(-179.75 -71.08443)"
                          fill="#e6e6e6"
                      />
                      <path
                          d="M877.66929,429.91557l-17,11s-25,70-18,73,46,1,46,1Z"
                          transform="translate(-179.75 -71.08443)"
                          fill="#e6e6e6"
                      />
                      <path
                          d="M956.089,341.64757s-9.00568-19.51227-26.26654-15.00943-27.017,11.25708-27.76746,18.01132.37525,16.88562.37525,16.88562,1.87616-13.88373,13.88373-10.88183c6.38656,1.59664,14.68386,1.70714,21.05261,1.46071a9.19307,9.19307,0,0,1,9.57007,9.30845l-.31464,21.49316S971.62205,366.91557,956.089,341.64757Z"
                          transform="translate(-179.75 -71.08443)"
                          fill="#2f2e41"
                      />
                      <path
                          d="M833.45954,349.21456H480.27425a6.54816,6.54816,0,0,1-6.54045-6.54046V180.50271a6.54815,6.54815,0,0,1,6.54045-6.54045H833.45954A6.54815,6.54815,0,0,1,840,180.50271V342.6741A6.54816,6.54816,0,0,1,833.45954,349.21456ZM480.27425,176.57844a3.92856,3.92856,0,0,0-3.92427,3.92427V342.6741a3.92856,3.92856,0,0,0,3.92427,3.92427H833.45954a3.92857,3.92857,0,0,0,3.92428-3.92427V180.50271a3.92857,3.92857,0,0,0-3.92428-3.92427Z"
                          transform="translate(-179.75 -71.08443)"
                          fill="#e6e6e6"
                      />
                      <circle cx="352.78588" cy="160.32649" r="26.64236" fill="#28e04d" />
                      <path
                          d="M594.06705,213.64934a4.4404,4.4404,0,1,0,0,8.88079H803.39989a4.44039,4.44039,0,0,0,0-8.88079Z"
                          transform="translate(-179.75 -71.08443)"
                          fill="#e6e6e6"
                      />
                      <path
                          d="M594.06705,240.2917a4.4404,4.4404,0,1,0,0,8.88079H684.1436a4.4404,4.4404,0,1,0,0-8.88079Z"
                          transform="translate(-179.75 -71.08443)"
                          fill="#e6e6e6"
                      />
                      <path
                          d="M512.10048,282.88511c-3.37772,0-6.12638,1.99223-6.12638,4.4404s2.74866,4.44039,6.12638,4.44039H800.9154c3.37771,0,6.12638-1.99223,6.12638-4.44039s-2.74867-4.4404-6.12638-4.4404Z"
                          transform="translate(-179.75 -71.08443)"
                          fill="#e6e6e6"
                      />
                      <path
                          d="M512.10048,309.52747c-3.37772,0-6.12638,1.99223-6.12638,4.4404s2.74866,4.44039,6.12638,4.44039H800.9154c3.37771,0,6.12638-1.99223,6.12638-4.44039s-2.74867-4.4404-6.12638-4.4404Z"
                          transform="translate(-179.75 -71.08443)"
                          fill="#e6e6e6"
                      />
                      <circle cx="590.09957" cy="47.38212" r="47.38212" fill="#28e04d" />
                      <polygon
                          points="590.1 124.408 575.284 98.747 560.469 73.086 590.1 73.086 619.73 73.086 604.915 98.747 590.1 124.408"
                          fill="#28e04d"
                      />
                      <circle cx="590.48169" cy="47.08636" r="17" fill="#fff" />
                      <ellipse cx="768.87205" cy="293.83115" rx="4" ry="5" fill="#a0616a" />
                  </svg>`
            : html ``}
        `;
    }
};
BoostWebsiteImageLocation._deps = [];
__decorate([
    property({ type: Boolean }),
    __metadata("design:type", Boolean)
], BoostWebsiteImageLocation.prototype, "smallScreen", void 0);
BoostWebsiteImageLocation = __decorate([
    customElement(BoostWebsiteImageLocation.is)
], BoostWebsiteImageLocation);
export { BoostWebsiteImageLocation };
