var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
let GoogleMap = class GoogleMap extends LitElement {
    constructor() {
        super(...arguments);
        this._deps = [];
        this.coordinates = '';
    }
    static get is() {
        return 'google-map';
    }
    static get styles() {
        return css `
            .google-maps {
                position: relative;
                padding-bottom: 31.3%;
                height: var(--GoogleMapsHeight, 100px);
                overflow: hidden;
                border-radius: 8px;
            }
            .google-maps iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        `;
    }
    /** @override */ render() {
        const mapSource = `https://www.google.com/maps/embed/v1/place?key=AIzaSyDKDL6fn1IFNXqK6n_7hhwQqOAsB4L9Iqs&q=${encodeURIComponent(this.coordinates)}&zoom=15`;
        return html `
            <div class="google-maps">
                <iframe src="${mapSource}" width="600" height="200" frameborder="0" style="border:0"></iframe>
            </div>
        `;
    }
};
__decorate([
    property({ type: String }),
    __metadata("design:type", String)
], GoogleMap.prototype, "coordinates", void 0);
GoogleMap = __decorate([
    customElement(GoogleMap.is)
], GoogleMap);
export { GoogleMap };
