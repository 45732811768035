import { GlobalWindowPropertyHelper } from './GlobalWindowPropertyHelper';
/**
 * Return a singleton-instance of the supplied class
 */
export function SingletonInstanceOf(cls) {
    return SingletonInstanceLike(cls, () => {
        return new cls();
    });
    /*    const inst = (cls as any).___instance_of;
    if (inst) {
        return inst;
    }
    return ((cls as any).___instance_of = new cls());*/
}
/**
 * Return a singleton-instance attached to the supplied class, instanciated using the supplied closure
 */
export function SingletonInstanceLike(cls, closure) {
    const key_base = '___instance_';
    let cnt = 0;
    const any_cls = cls;
    for (;;) {
        const key = key_base + cnt;
        if (key in any_cls && !any_cls.hasOwnProperty(key)) {
            // Hvis den har en property med det navnet, men den er arvet, så prøver vi på nytt med annen key
            cnt++;
            continue;
        }
        if (key in any_cls && any_cls[key]) {
            return any_cls[key];
        }
        return (any_cls[key] = closure());
    }
}
export function GlobalSingletonInstanceOf(cls_name, cls) {
    return SingletonInstanceLike(cls, () => {
        const inst = GlobalWindowPropertyHelper.get(cls_name, new cls());
        if (inst) {
            return inst;
        }
        throw new Error('Sorry');
    });
}
