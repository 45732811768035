var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
let BoostWebsiteInformation = class BoostWebsiteInformation extends LitElement {
    constructor() {
        super(...arguments);
        this.smallScreen = false;
        this.title = '';
    }
    static get is() {
        return 'boost-website-information';
    }
    static get styles() {
        return css `
            .first-container {
                width: 100%;
                background-color: #f7f9fd;
                display: flex;
                padding-bottom: 20px;
            }

            .second-container {
                width: 100%;
                background-color: #f6f6f6;
                display: flex;
                padding-bottom: 20px;
            }

            .img {
                opacity: 0.5;
            }

            .img:hover {
                opacity: 1;
                transition: 0.7s;
            }

            .text-container {
                display: flex;
                line-height: 1;
                padding-bottom: 25px;
                width: 100%;
                align-items: center;
                padding-left: 50px;
            }

            .hexagon {
                width: 25%;
                display: flex;
                align-items: center;
            }

            .slot-container {
                color: #677fa0;
                font-size: 18px;
                line-height: 1.2;
                padding-top: 6px;
            }

            span {
                font-size: 18px;
                color: #132c4f;
            }

            @media screen and (max-width: 1200px) {
                .first-container {
                    display: flex;
                    justify-content: center;
                }
                .second-container {
                    display: flex;
                    justify-content: center;
                }
                .hexagon {
                    width: 100%;
                    justify-content: center;
                    flex-direction: column;
                    align-items: baseline;
                }
                .hexagon span {
                    font-size: 18px;
                    padding-left: 5px;
                }
                .hexagon img {
                    width: 30px;
                    height: 30px;
                }
                .text-container {
                    padding-left: 20px;
                }
                .hexagon-text-container {
                    display: flex;
                    justify-content: space-evenly;
                }
                .smallscreen-text {
                    color: #293655;
                    padding-left: 8px;
                }
            }
        `;
    }
    /** @override */ render() {
        return html `
            <div class="text-container">
                <img
                    src="images/bluehexagon.jpg.gif"
                    alt="#"
                    width="${!this.smallScreen ? 30 : 25}"
                    height="${!this.smallScreen ? 30 : 25}"
                />

                ${!this.smallScreen
            ? html ` <div style="padding-left:20px;">
                          <span style="font-weight: 600; padding-bottom:6px">${this.title}</span>
                          <div class="slot-container"><slot></slot></div>
                      </div>`
            : html `<div>
                          <span class="smallscreen-text">${this.title}</span>
                      </div>`}
            </div>
        `;
    }
};
BoostWebsiteInformation._deps = [];
__decorate([
    property({ type: Boolean }),
    __metadata("design:type", Boolean)
], BoostWebsiteInformation.prototype, "smallScreen", void 0);
__decorate([
    property({ type: String }),
    __metadata("design:type", String)
], BoostWebsiteInformation.prototype, "title", void 0);
BoostWebsiteInformation = __decorate([
    customElement(BoostWebsiteInformation.is)
], BoostWebsiteInformation);
export { BoostWebsiteInformation };
