var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { css, html, LitElement } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { BoostImageProfileIcon } from '../../website-images/BoostImageProfileIcon';
import { BoostWebsiteMailIcon } from '../../website-images/BoostWebsiteMailIcon';
import { BoostWebsitePhoneIcon } from '../../website-images/BoostWebsitePhoneIcon';
import { BoostImageAboutUs } from '../../website-images/BoostImageAboutUs';
import { BoostWebsiteFooter } from '../components/BoostWebsiteFooter';
import { BoostWebsiteHeader } from '../components/BoostWebsiteHeader';
let BoostWebsiteSupport = class BoostWebsiteSupport extends LitElement {
    constructor() {
        super(...arguments);
        this.question = [
            {
                title: 'Hvordan er prosessen hvis jeg vil bytte til Boost System?',
                text: 'Vi har mange års erfaring med å hjelpe kunder til å komme i gang med et systembytte. Vi importerer medlemsmassen og timer slik at hverken du som drifter eller medlemmene merker så mye til det. Alt av eksisterende avtalegiroavtaler blir med videre. Sammen legger vi en plan som er tilpasset din timeplan og ditt senter.',
                id: 1,
            },
            {
                title: 'Jeg skal starte et nytt senter. Hva må jeg tenke på? ',
                text: 'Når man skal starte opp et treningssenter er det en del administrative ting man må få på plass. Alle bankpapirer hjelper vi deg med. Vi hjelper deg også med et oppsett av systemet slik at alt er på plass fra dag 1. I tillegg kan du bruke innmeldingsportalen til å ta i mot nye medlemmer frem til åpningsdato!',
                id: 2,
            },
            {
                title: 'Hvilke regnskapssystemer er dere integrert mot? ',
                text: 'Mye skjer i regnskapsverden! Derfor har vi knyttet oss mot Iizy. De tar i mot data fra oss og sender videre til regnskapssystemet ditt. Helt automatisk!',
                id: 3,
            },
            {
                title: 'Kan jeg selge PT-timer og kurs via Boost System? ',
                text: 'Det kan du! Vi har løsninger i appen der man kan kjøpe tilgang til PT-timer, kurs, squash, cageball, padel osv osv. Alt betales med Vipps og er enkelt for medlemmene.',
                id: 4,
            },
            {
                title: 'Hvilke bedriftsavtaler kan jeg ha i Boost System? ',
                text: 'Hvis man får en avtale med en bedriftskunde skal det ikke være våre løsninger som setter begrensninger. Om bedriften ønsker å betale hele eller deler av treningsavgiften for sine ansatte eller kanskje de vil betale pr trening? Vi har løsningen for at dette skjer automatisk hver måned! ',
                id: 5,
            },
            {
                title: 'Hvordan fungerer adgangssystemet? ',
                text: 'I systemet setter du opp åpningstider. Dette kan du gjøre for hele senteret eller kanskje du vil ha egne åpningstider for et formiddagsmedlemskap? Ikke noe problem! Når medlemmet ankommer senteret så vil appen sjekke om medlemmet har adgang til senteret. Hvis adgangen er i orden så kan medlemmet bare sveipe i appen for å åpne døren. Har du medlemmer uten smarttelefon så kan de få et adgangskort.',
                id: 6,
            },
            {
                title: 'Har dere integrasjoner mot noen som kan følge opp betalinger for oss?',
                text: 'Det har vi! Våre partnere har lang fartstid i bransjen og vil kunne bidra til bedre likviditet og kontroll på utestående fordringer. Les mer om våre partnere ',
                link: '/partners',
                id: 7,
            },
            {
                title: 'Hvordan kan jeg holde kontakten med medlemmene mine?  ',
                text: 'I Boost System kan du velge om du vil kommunisere med medlemmene via e-post, SMS eller via den nye nyhetsmodulen vi har i appen vår. I appen kan du lage nyhetsbrev og sende medlemmene et pushvarsel slik at de ikke går glipp av det!',
                id: 8,
            },
            {
                title: 'Hva kan medlemmene bruke medlemsappen til? ',
                text: 'Som medlem kan du bruke appen til å åpne døren på senteret og du kan booke timer og kurs. I tillegg har du full oversikt over ditt medlemskap, dine faktura og logg over besøk og bookinger. Appen har også flere bruksområder. De kan du lese om',
                id: 9,
                link: '/app',
            },
        ];
        this.smallScreen = false;
        this.questionId = -1;
    }
    static get is() {
        return 'boost-website-support';
    }
    static get styles() {
        return css `
            .background-img-container {
                height: 32em;
                object-fit: cover;
                background: linear-gradient(
                        90deg,
                        rgba(32, 67, 115, 0.329551889115021) 0%,
                        rgba(32, 67, 115, 0.4556023092830882) 4%,
                        rgba(32, 67, 115, 0) 67%
                    ),
                    url('images/new-website/kundeservice.jpg');
                background-size: cover;
                margin-top: 8rem;
                background-position: top;
            }

            .background-img {
                width: 100%;
                height: 50rem;
            }

            .header-h1 {
                color: #ffffff;
                transform: translate(0px, 10rem);
                width: 50%;
                padding-left: 6rem;
                line-height: 1.4;
                font-size: 50px;
                font-weight: normal;
            }

            .header-text {
                font-size: 36px;
                color: #ffffff;
                width: 88%;
                font-weight: lighter;
            }

            .faq-container {
                background-color: #204373;
                width: 74%;
                margin: 0 auto;
                display: flex;
                flex-direction: column;
                width: 50%;
                margin-top: 8rem;
                border-radius: 8px;
                max-width: 86rem;
            }

            .faq-header-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 20px;
                padding-left: 7rem;
                margin: 4rem 0rem 4rem 0rem;
            }

            .logo-thumb {
                width: 60px;
                height: 60px;
            }

            .contact-h1 {
                font-weight: lighter;
                font-size: 36px;
                margin: 0;
                color: #fff;
            }

            .text-icon-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 20px;
            }

            .faq-box {
                display: flex;
                flex-direction: column;
                width: 80%;
                margin: 0 auto 2rem;
            }

            .text-container {
                display: flex;
                flex-direction: column;
                width: 95%;
            }

            .question-box {
                display: flex;
                flex-direction: row;
                align-items: center;
                background-color: #132c4f;
                margin-bottom: 2rem;
                border-radius: 8px;
                cursor: pointer;
            }

            #text-field {
                width: 86%;
                padding-left: 4rem;
            }

            .question-text {
                font-size: 24px;
                padding-left: 20px;
            }

            .plus-sign {
                color: #25be53;
                font-size: 36px;
                padding-right: 20px;
                cursor: pointer;
            }

            @media screen and (max-width: 1000px) {
                .background-img-container {
                    background-repeat: no-repeat;
                    margin-top: 7rem;
                    background-size: cover;
                    height: 20rem;
                    width: 100%;
                }
                .header-h1 {
                    width: 98%;
                    padding-left: 10px;
                    transform: translate(0px, 36px);
                    font-size: 36px;
                }

                .header-text {
                    font-size: 20px;
                }

                .faq-container {
                    margin: 0rem;
                    width: 100%;
                }

                .faq-header-container {
                    margin: 3rem 0rem 3rem 2rem;
                    padding-left: 10px;
                }

                .contact-h1 {
                    font-size: 26px;
                }

                .faq-box {
                    width: 100%;
                    justify-content: center;
                    align-items: center;
                }

                .question-box {
                    width: 98%;
                }

                .question-text {
                    font-size: 20px;
                }

                .plus-sign {
                    font-size: 28px;
                    align-self: start;
                }

                #text-field {
                    font-size: 16px;
                    padding-left: 2rem;
                }

                .smallscreen-footer {
                    margin: 0;
                }
            }
        `;
    }
    /** @override */ render() {
        return html `
            <boost-website-header
                @screen-size-changed="${(event) => (this.smallScreen = event.detail.value)}"
            ></boost-website-header>
            <div class="background-img-container">
                <h1 class="header-h1">
                    Ofte stilte spørsmål
                    <p class="header-text">
                        Her har vi samlet det mange lurer på. Har du andre spørsmål så er det bare å kontakte
                        oss!
                    </p>
                </h1>
            </div>
            <div class="faq-container">
                <div class="faq-header-container">
                    <img class="logo-thumb" src="images/new-website/boostlogothumb.png" />
                    <h1 class="contact-h1">Ofte stilte spørsmål</h1>
                </div>
                <div class="faq-box">
                    ${this.question.map((question) => html `<div
                                class="question-box"
                                @click="${() => this._showQuestionText(question.id)}"
                            >
                                <div class="text-container">
                                    <p class="question-text">${question.title}</p>
                                    ${this.questionId === question.id
            ? html `
                                              <p id="text-field">
                                                  ${question.text}
                                                  ${question.link
                ? html ` <a href="${question.link}" style="color:white;"
                                                            >her.</a
                                                        >`
                : html ``}
                                              </p>
                                          `
            : html ``}
                                </div>
                                <p class="plus-sign">
                                    ${this.questionId === question.id ? html `&#x2212;` : html `&#x2b;`}
                                </p>
                            </div>`)}
                </div>
            </div>
            <boost-website-footer></boost-website-footer>
        `;
    }
    _showQuestionText(id) {
        this.questionId = this.questionId === id ? -1 : id;
    }
};
BoostWebsiteSupport._deps = [
    BoostWebsiteHeader,
    BoostImageProfileIcon,
    BoostWebsiteMailIcon,
    BoostWebsitePhoneIcon,
    BoostImageAboutUs,
    BoostWebsiteFooter,
];
__decorate([
    state(),
    __metadata("design:type", Array)
], BoostWebsiteSupport.prototype, "question", void 0);
__decorate([
    property({ type: Boolean }),
    __metadata("design:type", Boolean)
], BoostWebsiteSupport.prototype, "smallScreen", void 0);
__decorate([
    state(),
    __metadata("design:type", Number)
], BoostWebsiteSupport.prototype, "questionId", void 0);
BoostWebsiteSupport = __decorate([
    customElement(BoostWebsiteSupport.is)
], BoostWebsiteSupport);
export { BoostWebsiteSupport };
